import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateEventClassEntryInput, CreateEventClassEntryMutation, DeleteEventClassEntryInput, DeleteEventClassEntryMutation, GetEventClassEntryQuery, ListEventClassEntriesQuery, UpdateEventClassEntryInput, UpdateEventClassEntryMutation } from "../../API";
import { eventClassEntriesCountByEventClassIdByStatus, eventClassEntriesWithEventClassByEventEntryId } from "./EventClassEntryCustomQueries";
import { EventClassEntry } from "../../models";
import moment from "moment";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: eventClassEntry. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created eventClassEntry.
*/
export async function createEventClassEntry(input: CreateEventClassEntryInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventClassEntry", "No input", "Create EventClassEntry received no input.");
   try {
        const fullInput: CreateEventClassEntryInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createEventClassEntry, { input: fullInput }))) as GraphQLResult<CreateEventClassEntryMutation>;
       else result = (await API.graphql({
           query: mutations.createEventClassEntry,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateEventClassEntryMutation>;
       const eventClassEntry = result.data?.createEventClassEntry;
       return formatResult(true, "EventClassEntry", eventClassEntry, "Successfully created the eventClassEntry.");
   } catch (error: any) {
       return formatResult(false, "EventClassEntry", error, "Error creating record in the database for type: eventClassEntry");
   }
}

/**
* Handle updating a new record in the database for type: eventClassEntry. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated eventClassEntry.
*/
export async function updateEventClassEntry(input: UpdateEventClassEntryInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventClassEntry", "No input", "Update EventClassEntry received no input.");
   try {
        const fullInput: UpdateEventClassEntryInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateEventClassEntry, { input: fullInput }))) as GraphQLResult<UpdateEventClassEntryMutation>;
       else result = (await API.graphql({
           query: mutations.updateEventClassEntry,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateEventClassEntryMutation>;
       const eventClassEntry = result.data?.updateEventClassEntry;
       return formatResult(true, "EventClassEntry", eventClassEntry, "Successfully updated the eventClassEntry.");
   } catch (error: any) {
       return formatResult(false, "EventClassEntry", error, "Error updating record in the database for type: eventClassEntry");
   }
}

/**
* Handle deleting a new record in the database for type: eventClassEntry. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted eventClassEntry.
*/
export async function deleteEventClassEntry(input: DeleteEventClassEntryInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "EventClassEntry", "No input", "Delete EventClassEntry received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteEventClassEntry, { input: input }))) as GraphQLResult<DeleteEventClassEntryMutation>;
       else result = (await API.graphql({
           query: mutations.deleteEventClassEntry,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteEventClassEntryMutation>;
       const eventClassEntry = result.data?.deleteEventClassEntry;
       return formatResult(true, "EventClassEntry", eventClassEntry, "Successfully deleted the eventClassEntry.");
   } catch (error: any) {
       return formatResult(false, "EventClassEntry", error, "Error deleting record in the database for type: eventClassEntry");
   }
}

/**
* Get all records in the database for type: eventClassEntry. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClassEntry object
*/
export async function getAllEventClassEntries(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listEventClassEntries,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassEntriesQuery>;

        let items = result.data?.listEventClassEntries?.items as EventClassEntry[];
        let nextToken = result.data?.listEventClassEntries?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listEventClassEntries,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListEventClassEntriesQuery>;

            const nextItems = nextResult.data?.listEventClassEntries?.items as EventClassEntry[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listEventClassEntries?.nextToken;
        }

        return formatResult(true, "EventClassEntry", items, "Successfully got the EventClassEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntrys");
    }
}

/**
* Read a specific record in the database for type: eventClassEntry. 
* 
* @param {string}  id                  The eventClassEntry id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClassEntry object
*/
export async function getEventClassEntryById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getEventClassEntry,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetEventClassEntryQuery>;
       const eventClassEntry = result.data?.getEventClassEntry;
       return formatResult(true, "EventClassEntry", eventClassEntry, "Successfully got the eventClassEntry.");
   } catch (error: any) {
       return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntry");
   }
}

/**
* Get all records in the database that match the given criteria for type: eventClassEntry. 
* 
* @param {string}  eventClassId         The event class id 
* @param {string}  entryId              The entry id 
* @param {string}  authMode             API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event class entries
*/
export async function getEventClassEntryByEventClassIdEntryId(eventClassId: string, entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventClassId: {eq: eventClassId}, eventEntryId: {eq: entryId}};
        const result = (await API.graphql({
            query: queries.listEventClassEntries,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassEntriesQuery>;

        let items = result.data?.listEventClassEntries?.items as EventClassEntry[];
        let nextToken = result.data?.listEventClassEntries?.nextToken;

        if (((!items) || (items && items.length === 0)) && nextToken) {
            while (((!items) || (items && items.length === 0)) && nextToken) {
                const nextResult = await API.graphql({
                    query: queries.listEventClassEntries,
                    variables: {
                        filter,
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<ListEventClassEntriesQuery>;
        
                const nextItems = nextResult.data?.listEventClassEntries?.items as EventClassEntry[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.listEventClassEntries?.nextToken;
            }
        }

        const eventClassEntrys = items;
        if (eventClassEntrys && eventClassEntrys[0]) return formatResult(true, "EventClassEntry", eventClassEntrys[0], "Successfully got the eventClassEntry.");
        else return formatResult(false, "EventClassEntry", null, "Could not find the eventClassEntry.");
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntrys");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClassEntry. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClassEntry object
*/
export async function getEventClassEntriesByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listEventClassEntries,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassEntriesQuery>;

        let items = result.data?.listEventClassEntries?.items as EventClassEntry[];
        let nextToken = result.data?.listEventClassEntries?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.listEventClassEntries,
                    variables: {
                        filter,
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<ListEventClassEntriesQuery>;
        
                const nextItems = nextResult.data?.listEventClassEntries?.items as EventClassEntry[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.listEventClassEntries?.nextToken;
            }
        }

        return formatResult(true, "EventClassEntry", items, "Successfully got the EventClassEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntrys");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClassEntry. 
* 
* @param {string}  eventId             The event id 
* @param {string}  eventEntryId        The event entry id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClassEntry object
*/
export async function getEventClassEntriesByEventIdEntryId(eventId: string, eventEntryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, eventEntryId: {eq: eventEntryId}};
        const result = (await API.graphql({
            query: queries.listEventClassEntries,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListEventClassEntriesQuery>;

        let items = result.data?.listEventClassEntries?.items as EventClassEntry[];
        let nextToken = result.data?.listEventClassEntries?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.listEventClassEntries,
                    variables: {
                        filter,
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<ListEventClassEntriesQuery>;
        
                const nextItems = nextResult.data?.listEventClassEntries?.items as EventClassEntry[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.listEventClassEntries?.nextToken;
            }
        }

        return formatResult(true, "EventClassEntry", items, "Successfully got the EventClassEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntrys");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClassEntry. 
* 
* @param {string}  eventId             The event id 
* @param {string}  eventEntryId        The event entry id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the eventClassEntry object
*/
export async function getEventClassEntriesWithEventClassByEventEntryId(eventEntryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventClassEntriesWithEventClassByEventEntryId,
            variables: {
                eventEntryId: eventEntryId,
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassEntriesByEventEntryId?.items as EventClassEntry[];
        let nextToken = result.data?.eventClassEntriesByEventEntryId?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: eventClassEntriesWithEventClassByEventEntryId,
                    variables: {
                        eventEntryId: eventEntryId,
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<any>;
        
                const nextItems = nextResult.data?.eventClassEntriesByEventEntryId?.items as EventClassEntry[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.eventClassEntriesByEventEntryId?.nextToken;
            }
        }

        return formatResult(true, "EventClassEntry", items, "Successfully got the EventClassEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntrys");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClassEntry. 
* 
* @param {string}  eventClassId        The event class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event class entries
*/
export async function getEventClassEntriesByEventClassId(eventClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventClassEntriesByEventClassIdByStatus,
            variables: {
                limit: 1000,
                eventClassId: eventClassId,
                status: {eq: "accepted"}
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassEntriesByEventClassIdByStatus?.items as EventClassEntry[];
        let nextToken = result.data?.eventClassEntriesByEventClassIdByStatus?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.eventClassEntriesByEventClassIdByStatus,
                    variables: {
                        eventClassId: eventClassId,
                        status: {eq: "accepted"},
                        limit: 100,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<any>;
        
                const nextItems = nextResult.data?.eventClassEntriesByEventClassIdByStatus?.items as EventClassEntry[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.eventClassEntriesByEventClassIdByStatus?.nextToken;
            }
        }

        return formatResult(true, "EventClassEntry", items, "Successfully got the EventClassEntrys.");
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntrys");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClassEntry. 
* 
* @param {string}  eventClassId        The event class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the event class entries
*/
export async function getAcceptedEventClassEntriesByEventClassId(eventClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.eventClassEntriesByEventClassIdByStatus,
            variables: {
                eventClassId: eventClassId,
                status: {eq: "accepted"},
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.eventClassEntriesByEventClassIdByStatus?.items as EventClassEntry[];
        let nextToken = result.data?.eventClassEntriesByEventClassIdByStatus?.nextToken;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: queries.eventClassEntriesByEventClassIdByStatus,
                    variables: {
                        eventClassId: eventClassId,
                        status: {eq: "accepted"},
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<any>;
        
                const nextItems = nextResult.data?.eventClassEntriesByEventClassIdByStatus?.items as EventClassEntry[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.eventClassEntriesByEventClassIdByStatus?.nextToken;
            }
        }

        if (items && items.length > 0) {
            return formatResult(true, "EventClassEntry", items, "Successfully got the EventClassEntrys.");
        } else {
            return formatResult(false, "EventClassEntry", null, "Found no EventClassEntrys.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: eventClassEntrys");
    }
}

/**
* Get all records in the database that match the given criteria for type: eventClassEntry. 
* 
* @param {string}  eventClassId        The event class id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the count of entries in this event class
*/
export async function getEventClassEntriesCountByEventClassId(eventClassId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: eventClassEntriesCountByEventClassIdByStatus,
            variables: {
                eventClassId: eventClassId,
                status: {eq: "accepted"},
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;

        let items = result.data?.eventClassEntriesByEventClassIdByStatus?.items as EventClassEntry[];
        let nextToken = result.data?.eventClassEntriesByEventClassIdByStatus?.nextToken;

        let count = items.length;

        if (nextToken) {
            while (nextToken) {
                const nextResult = await API.graphql({
                    query: eventClassEntriesCountByEventClassIdByStatus,
                    variables: {
                        eventClassId: eventClassId,
                        status: {eq: "accepted"},
                        limit: 1000,
                        nextToken
                    },
                    authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
                }) as GraphQLResult<any>;
        
                const nextItems = nextResult.data?.eventClassEntriesByEventClassIdByStatus?.items as EventClassEntry[];
                if (nextItems && nextItems.length) {
                    items = items?.concat(nextItems);
                }
                nextToken = nextResult.data?.eventClassEntriesByEventClassIdByStatus?.nextToken;
            }
            count = items.length;
        }

        if (items && items.length > 0) {
            return formatResult(true, "EventClassEntry", count, "Successfully got the count of the EventClassEntrys.");
        } else {
            return formatResult(false, "EventClassEntry", null, "Found no EventClassEntrys.");
        }
    } catch (error: any) {
        return formatResult(false, "EventClassEntry", error, "Error reading record in the database for type: EventClassEntrys");
    }
}
