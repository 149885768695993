import {
    IonButton,
    IonButtons,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonModal,
    IonRow,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Organization, OrganizationClass, PointTable } from "../../models";
import { getOrganizationById } from "../../utilities/organization/Organization";
import { getOrganizationClassesByOrganizationId, updateOrganizationClass } from "../../utilities/organizationClass/OrganizationClass";
import { sortOrganizationClassesByNumber } from "../../utilities/organizationClass/SortOrganizationClasses";
import { Input, Table } from "reactstrap";
import OrganizationClassForm from "./OrganizationClassForm";
import { chevronDown, chevronUp, close, createOutline } from "ionicons/icons";
import Spinner from "../Spinners/Spinner";
import ErrorAlert from "../Errors/ErrorAlert";
import PriceField from "../Forms/PriceField";
import SelectCreatePointTable from "../PointTable/SelectCreatePointTable";
import { UpdateOrganizationClassInput } from "../../API";
import { formatJumpHeightString } from "../../utilities/jumpHeight/DisplayJumpHeights";

interface _Props {
    organizationId: string
}

enum sortOptions {
    number1 = "SortByNumberSmallToLarge",
    number2 = "SortByNumberLargeToSmall",
    name1 = "SortByNameAtoZ",
    name2 = "SortByNameZtoA"
}

const OrganizationClassEditTable: React.FC<_Props> = ({organizationId}) => {

    const [organizationClasss, setOrganizationClasss] = useState<OrganizationClass[] | null | undefined>();
    const [selectedOrganizationClass, setSelectedOrganizationClass] = useState<OrganizationClass | null | undefined>();
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const [currentSortOption, setCurrentSortOption] = useState(sortOptions.number1);
    const [isEditNumber, setIsEditNumber] = useState(false);
    const [isEditName, setIsEditName] = useState(false);
    const [isEditEntryFee, setIsEditEntryFee] = useState(false);
    const [isEditPointTable, setIsEditPointTable] = useState(false);

    function sortOrganizationClasss(currentSortOption: sortOptions) {
        if (organizationClasss) {
            if (currentSortOption === sortOptions.number1) {
                const sorted = organizationClasss.sort((a,b) => {
                    const numberResult = (a.number || 0) - (b.number || 0);
                    const nameResult = a.name.localeCompare(b.name);
                    return numberResult || nameResult;
                });
                setOrganizationClasss(sorted);
            } else if (currentSortOption === sortOptions.number2) {
                const sorted = organizationClasss.sort((a,b) => {
                    const numberResult = (b.number || 0) - (a.number || 0);
                    const nameResult = a.name.localeCompare(b.name);
                    return numberResult || nameResult;
                });
                setOrganizationClasss(sorted);
            } else if (currentSortOption === sortOptions.name1) {
                const sorted = organizationClasss.sort((a,b) => a.name.localeCompare(b.name));
                setOrganizationClasss(sorted);
            } else if (currentSortOption === sortOptions.name2) {
                const sorted = organizationClasss.sort((a,b) => b.name.localeCompare(a.name));
                setOrganizationClasss(sorted);
            } 
        }
    }

    useEffect(() => {
        async function getOrganization() {
            const queryResult = await getOrganizationById(organizationId);
            if (queryResult.isSuccess) {
                getOrganizationClasss(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }

        getOrganization();
    }, [organizationId]);

    async function getOrganizationClasss(organization: Organization) {
        setIsLoading(true);
        const queryResult = await getOrganizationClassesByOrganizationId(organization.id);
        if (queryResult.isSuccess) {
            const list: OrganizationClass[] = queryResult.result;
            const sorted = sortOrganizationClassesByNumber(list);
            if (sorted) {
                setOrganizationClasss(sorted);
            } else {
                setOrganizationClasss(list);
            }
        }
        setIsLoading(false);
    }

    const onSelectClass = (orgClass: OrganizationClass) => {
        setSelectedOrganizationClass(orgClass);
        setShowModal(true);
    }

    const selectAddNewClass = () => {
        setSelectedOrganizationClass(undefined);
        setShowModal(true);
    }

    const handleOrganizationClassChange = (type: ("create" | "update" | "delete"), orgClass: OrganizationClass) => {
        setIsLoading(true);
        setShowModal(false);
        let updatedOrganizationClasss: OrganizationClass[] = [];

        if (type === "create") {
            if (organizationClasss) {
                updatedOrganizationClasss = organizationClasss?.concat([orgClass]);
                updatedOrganizationClasss = sortOrganizationClassesByNumber(updatedOrganizationClasss) || updatedOrganizationClasss;
            } else {
                updatedOrganizationClasss = [orgClass];
            }
        } else if (type === "update") {
            if (organizationClasss) {
                const index = organizationClasss.findIndex(od => od.id === orgClass.id);
                updatedOrganizationClasss = [
                    ...organizationClasss.slice(0, index),
                    orgClass,
                    ...organizationClasss.slice(index + 1)
                ];
            }
        } else if (type === "delete") {
            if (organizationClasss) {
                updatedOrganizationClasss = organizationClasss.filter(od => od.id !== orgClass.id);
            }
        }

        setOrganizationClasss(updatedOrganizationClasss);
        setSelectedOrganizationClass(undefined);
        setIsLoading(false);
    }

    const handleNumberChange = (orgClass: OrganizationClass, index: number, number?: number) => {
        if (organizationClasss) {
            let updatedOrganizationClasss = [
                ...organizationClasss.slice(0, index),
                {
                    ...orgClass,
                    number: number
                },
                ...organizationClasss.slice(index + 1)
            ];
            setOrganizationClasss(updatedOrganizationClasss);
        }
    }

    const handleNameChange = (orgClass: OrganizationClass, index: number, name: string) => {
        if (organizationClasss) {
            let updatedOrganizationClasss = [
                ...organizationClasss.slice(0, index),
                {
                    ...orgClass,
                    name: name
                },
                ...organizationClasss.slice(index + 1)
            ];
            setOrganizationClasss(updatedOrganizationClasss);
        }
    }

    const handleEntryFeeChange = (orgClass: OrganizationClass, index: number, entryFee: number) => {
        if (organizationClasss) {
            const updatedOrgClass: OrganizationClass = {
                ...orgClass,
                entryFee: (entryFee === 0 ? null : entryFee.toString())
            };

            let updatedOrganizationClasss = [
                ...organizationClasss.slice(0, index),
                updatedOrgClass,
                ...organizationClasss.slice(index + 1)
            ];
            setOrganizationClasss(updatedOrganizationClasss);
        }
    }

    const handlePointTableChange = (orgClass: OrganizationClass, index: number, pointTable: PointTable) => {
        if (organizationClasss) {
            const updatedOrgClass: OrganizationClass = {
                ...orgClass,
                organizationClassPointTableId: pointTable.id || "",
                pointTable: pointTable
            };

            let updatedOrganizationClasss = [
                ...organizationClasss.slice(0, index),
                updatedOrgClass,
                ...organizationClasss.slice(index + 1)
            ];
            setOrganizationClasss(updatedOrganizationClasss);
        }
    }

    const handleSave = async () => {
        setIsLoading(true);
        if (organizationClasss) {
            for (let i = 0; i < organizationClasss.length; i++) {
                const orgClass = organizationClasss[i];
                const input: UpdateOrganizationClassInput = {
                    id: orgClass.id,
                    name: orgClass.name,
                    number: orgClass.number,
                    entryFee: orgClass.entryFee,
                    organizationClassPointTableId: orgClass.pointTable?.id || ""
                };
                const updateResult = await updateOrganizationClass(input);
            }
        }
        setIsEditName(false);
        setIsEditNumber(false);
        setIsEditEntryFee(false);
        setIsEditPointTable(false);
        setIsLoading(false);
    }

    return (
        <>
            <IonCard mode="md" className="ion-padding bg-white">
                <IonCardTitle>Organization Classes</IonCardTitle>
                <IonCardSubtitle>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="6">
                            <p>Click on a class to edit it.</p>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-right">
                            <IonButton onClick={selectAddNewClass}>
                                Add Class
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardSubtitle>
                {error && <ErrorAlert error={error} />}
                {(isEditEntryFee || isEditName || isEditNumber || isEditPointTable) && (
                    <IonRow className="ion-justify-content-center">
                        <IonCol sizeXs="12" sizeMd="6" className="ion-text-center">
                            <IonButton color="success" onClick={() => handleSave()}>
                                Save Edits
                            </IonButton>
                        </IonCol>
                    </IonRow>
                )}
                {isLoading ?
                    <Spinner />
                    :
                    <IonRow>
                        <IonCol>
                            {(organizationClasss && organizationClasss.length > 0) ? 
                                <Table hover responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <p>
                                                    <span onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1); sortOrganizationClasss(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1);} }>
                                                        Number
                                                    </span>
                                                    <IonIcon icon={currentSortOption === sortOptions.number1 ? chevronDown : chevronUp} onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1); sortOrganizationClasss(currentSortOption === sortOptions.number1 ? sortOptions.number2 : sortOptions.number1);} } /> 
                                                    <IonIcon icon={createOutline} ariaLabel="Edit Numbers" onClick={() => setIsEditNumber(true)} />
                                                </p>
                                            </th>
                                            <th>
                                                <p>
                                                    <span onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.name1 ? sortOptions.name2 : sortOptions.name1); sortOrganizationClasss(currentSortOption === sortOptions.name1 ? sortOptions.name2 : sortOptions.name1);} }>
                                                        Name
                                                    </span>
                                                    <IonIcon icon={currentSortOption === sortOptions.name1 ? chevronDown : chevronUp} onClick={() => {setCurrentSortOption(currentSortOption === sortOptions.name1 ? sortOptions.name2 : sortOptions.name1); sortOrganizationClasss(currentSortOption === sortOptions.name1 ? sortOptions.name2 : sortOptions.name1);} } /> 
                                                    <IonIcon icon={createOutline} ariaLabel="Edit Names" onClick={() => setIsEditName(true)} />
                                                </p>
                                            </th>
                                            <th><p>Jump Height(s)</p></th>
                                            <th>
                                                <p>
                                                    <span>
                                                        Entry Fee
                                                    </span>
                                                    <IonIcon icon={createOutline} ariaLabel="Edit Names" onClick={() => setIsEditEntryFee(true)} />
                                                </p>
                                            </th>
                                            <th>
                                                <p>
                                                    <span>
                                                        Point Table
                                                    </span>
                                                    <IonIcon icon={createOutline} ariaLabel="Edit Names" onClick={() => setIsEditPointTable(true)} />
                                                </p>
                                            </th>
                                            <th><p>Prize Money</p></th>
                                            <th><p>USEF</p></th>
                                            <th><p>USHJA</p></th>
                                            <th><p>EC</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {organizationClasss.map((od, index) => (
                                            <tr key={index} onClick={() => (isEditEntryFee || isEditName || isEditNumber || isEditPointTable) ? console.log("Is editing") : onSelectClass(od)}>
                                                <td>
                                                    {isEditNumber ? 
                                                        <>
                                                            <Input
                                                                className="input-width"
                                                                type="text"
                                                                value={od.number || 0}
                                                                onChange={e => {
                                                                    handleNumberChange(od, index, (e.target.value ? parseInt(e.target.value) : undefined));
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            {od?.number || ""}
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    {isEditName ? 
                                                        <>
                                                            <Input
                                                                className="extra-large-input-width"
                                                                type="text"
                                                                value={od.name || 0}
                                                                onChange={e => {
                                                                    handleNameChange(od, index, e.target.value);
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        <>
                                                            {od?.name || ""}
                                                        </>
                                                    }
                                                </td>
                                                <td>{od.jumpHeight ? formatJumpHeightString(od.jumpHeight) : ""}</td>
                                                <td>
                                                    {isEditEntryFee ? 
                                                        <>
                                                            <PriceField value={od.entryFee ? parseFloat(od.entryFee) : undefined} className="ion-margin-top" onChange={(value: number) => handleEntryFeeChange(od, index, value)}/>
                                                        </>
                                                        :
                                                        <>
                                                            {od.entryFee ? ("$" + od.entryFee) : ""}
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    {isEditPointTable ? 
                                                        <div className="extra-large-input-width">
                                                            <SelectCreatePointTable organizationId={organizationId} pointTable={od.pointTable} onSelect={(pt: PointTable) => handlePointTableChange(od, index, pt)}/>
                                                        </div>
                                                        :
                                                        <>
                                                            {od.pointTable?.name || ""}
                                                        </>
                                                    }
                                                </td>
                                                <td>{od.prizeMoney ? ("$" + od.prizeMoney) : ""}</td>
                                                <td>{od.class.usefNumber || ""}</td>
                                                <td>{od.class.ushjaName || ""}</td>
                                                <td>{od.class.ecNumber || ""}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <p>No organization classes found.</p>
                            }
                        </IonCol>
                    </IonRow>
                }
            </IonCard>
            <IonModal backdropDismiss={false} isOpen={showModal} id="organizationClassModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        {selectedOrganizationClass ? selectedOrganizationClass.name : "Organization Class"} 
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="organizationClassModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <OrganizationClassForm organizationId={organizationId} organizationClass={selectedOrganizationClass} onSubmit={handleOrganizationClassChange} />
                </IonContent>
            </IonModal>
        </>
    );
};

export default OrganizationClassEditTable;