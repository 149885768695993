import { Auth } from "aws-amplify";
import { IonCol, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react";
import React, { useContext, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import VerifyAccountModal from "../Modals/VerifyAccountModal";
import {alertCircle} from "ionicons/icons";

const VerifyEmailAlert: React.FC = () => {
    const user = useContext(PersonContext);

    const [showVerifyModal, setShowVerifyModal] = useState(false);
    const [error, setError] = useState("");

    const handleVerifyAccountClick = () => {
        Auth.verifyCurrentUserAttribute("email")
            .then(() => {
                setShowVerifyModal(true);
            }).catch((e) => {
                setError(e.message || "Sorry, we were unable to send the verification email at this time.");
            });

        setShowVerifyModal(true);
    }

    return (
        <>
            {!user.isVerified && (
                <>
                    <IonRow className="bg-warning ion-padding">
                        <IonCol className="description">
                            <IonIcon icon={alertCircle} color="light"/>
                            <IonLabel className="ion-text-wrap font-weight-bold">
                                <IonText className="text-white"> Please verify your email address in case you forget your password in the future. <span className="link" onClick={handleVerifyAccountClick}>Verify My Email Address</span></IonText>
                            </IonLabel>
                        </IonCol>
                    </IonRow>
                    {error && (
                        <IonRow>
                            <IonCol>
                                <ErrorAlert width="12" error={error} />
                            </IonCol>
                        </IonRow>
                    )}
                    <VerifyAccountModal openModal={showVerifyModal} />
                </>
            )}
        </>
    );
};

export default VerifyEmailAlert;
