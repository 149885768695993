import moment from "moment";
import { Event } from "../../models";
import { getAcceptedEventEntriesByEventId } from "../eventEntry/EventEntry";
import { getEventEntryInvoiceData } from "../eventEntry/EventEntryInvoiceData";
import { sortEventEntriesByNumber } from "../eventEntry/SortEventEntry";
import { defaultTableSettings } from "./ReportUtils";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateEntryTotalsPDF(event: Event, callbackFunction: Function) {
    // initialize jsPDF
    const doc = new jsPDF("l");

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Entry Bill Totals Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY h:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = [
        {header: "Entry #", dataKey: "number"}, 
        {header: "Horse", dataKey: "horse"}, 
        {header: "Rider(s)", dataKey: "rider"},
        {header: "Trainer", dataKey: "trainer"},
        {header: "Total", dataKey: "total"},
        {header: "Prize $", dataKey: "prizemoney"},
        {header: "Paid", dataKey: "received"},
        {header: "Payment Methods", dataKey: "methods"},
        {header: "Payment Note", dataKey: "notes"},
        {header: "Amount Owed", dataKey: "owed"},
    ];
    // define an empty array of rows
    const tableRows: any[] = [];

    const eventEntryResult = await getAcceptedEventEntriesByEventId(event.id);
    if (eventEntryResult.isSuccess) {
        const eventEntries = eventEntryResult.result;
        const sortedEventEntries = sortEventEntriesByNumber(eventEntries);
        if (sortedEventEntries && sortedEventEntries.length > 0) {
            for (let i = 0; i < sortedEventEntries.length; i++) {
                const currentEventEntry = sortedEventEntries[i];

                const percentage = (i+1)/sortedEventEntries.length*100;
                const progressMessage = percentage.toFixed(0) + "% Complete";
                callbackFunction(progressMessage);

                const entryNumber = currentEventEntry.number || "0";
                const horseName = currentEventEntry.horse?.name || currentEventEntry.horseName || "";
                const riderName = currentEventEntry.rider?.name || currentEventEntry.riderName || "";
                const secondRiderName = currentEventEntry.secondRider?.name || currentEventEntry.secondRiderName || "";
                const fullRiderName = (riderName ? riderName : "") + (secondRiderName ? (", " + secondRiderName) : "");
                const trainerName = currentEventEntry.trainer?.name || currentEventEntry.trainerName || "";

                const entryInvoiceData = await getEventEntryInvoiceData(currentEventEntry);

                const totalFeeCost = entryInvoiceData.feeTotal || 0;

                const prizeMoneyAmount = entryInvoiceData.prizeMoneyTotal || 0;
                
                const amountPaid = entryInvoiceData.paymentsTotal || 0;

                const paymentMethods = entryInvoiceData.paymentMethodsUsed;

                const paymentNote = entryInvoiceData.paymentNote;

                const amountOwed = entryInvoiceData.amountOwed || 0;
                
                const row = [
                    entryNumber,
                    horseName,
                    fullRiderName,
                    trainerName,
                    "$" + totalFeeCost.toFixed(2),
                    "$" + prizeMoneyAmount.toFixed(2),
                    "$" + amountPaid.toFixed(2),
                    paymentMethods,
                    paymentNote,
                    "$" + amountOwed.toFixed(2)
                ];

                tableRows.push(row);
            }
        }
    }
    
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        ...defaultTableSettings,
        startY: 30,
    });

    // page footer
    addFooters(doc);

    // we define the name of our PDF file.
    doc.save(`Entry_Bill_Totals.pdf`);
}