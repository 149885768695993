import {
    IonButton, 
    IonCard, 
    IonCardContent, 
    IonCardHeader, 
    IonCardTitle, 
    IonCheckbox, 
    IonCol, 
    IonContent, 
    IonInput,  
    IonItem,  
    IonLabel,  
    IonPage, 
    IonRouterLink, 
    IonRow,
    IonText, 
} from "@ionic/react";
import React, {useEffect, useRef, useState} from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { Organization, Person } from "../../models";
import { getOrganizationByBackHalf } from "../../utilities/organization/Organization";
import Footer from "../../components/Footers/Footer";
import OrganizationAuthNavbar from "../../components/Navbars/OrganizationAuthNavbar";
import Spinner from "../../components/Spinners/Spinner";
import ErrorAlert from "../../components/Errors/ErrorAlert";
import TermsAndConditionsModal from "../../components/Modals/TermsAndConditionsModal";
import { signUp } from "../../utilities/auth/RegisterUtil";
import { logIn } from "../../utilities/auth/LoginUtil";
import { getUser, updateUserPersonId, updateUserOrganizationId } from "../../utilities/user/User";
import { CreatePersonInput, LogoInput, UpdatePersonInput } from "../../API";
import { createPerson, updatePerson } from "../../utilities/person/Person";
import { createStripeCustomer } from "../../utilities/stripe/CreateCustomer";
import AuthNavbar from "../../components/Navbars/AuthNavbar";

interface ViewOrgProps extends RouteComponentProps<{
    id: string;
}> {
    setOrganizationHandler: Function
}

const OrganizationRegisterPage: React.FC<ViewOrgProps> = ({match, setOrganizationHandler}) => {
    const history = useHistory();

    const [currentOrganization, setCurrentOrganization] = useState<Organization>();
    const [logos, setLogos] = useState<(LogoInput | null)[] | null[]>();
    const [orgLogoURL, setOrgLogoURL] = useState<string>();
    const [id, setId] = useState<string>("");
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isTermsAndConditionsChecked, setIsTermsAndConditionsChecked] = useState(false);

    const emailInputRef = useRef<HTMLIonInputElement>(null);
    const passwordInputRef = useRef<HTMLIonInputElement>(null);

    async function getOrganization(id: string) {
        setIsLoading(true);
        setError("");
        const queryResult = await getOrganizationByBackHalf(id);
        if (queryResult.isSuccess && queryResult.result) {
            const organization = queryResult.result;
            setCurrentOrganization(organization);
            setOrganizationHandler(organization);
        } else {
            setError("Error: please check that you have the correct URL or try navigating to www.ringsidepro.com/register"); 
        }
        setIsLoading(false);
    }

    useEffect(() => {
        setLogos(currentOrganization?.logos ?? []);
    }, [currentOrganization]);

    useEffect(() => {
        setOrgLogoURL(logos?.[0]?.logoURL ?? "");
    }, [logos]);

    useEffect(() => {
        // First, check if the id is found in the URL parameters
        if (match.params && match.params.id) {
            setError("");
            setId(match.params.id);
            getOrganization(match.params.id);
        // Second, check if you can find the id by searching in the URL
        } else {
            // For ex - match.path = "/iea/register".
            // The pathArray will be ["", "iea", "register"]
            const path = match.path;
            const pathArray = path.split("/");
            const id = pathArray[pathArray.length - 2];
            if (id) {
                setError("");
                setId(id);
                getOrganization(id);
            } else {
                // Lastly, set an error message 
                setError("Error: please check that you have the correct URL or try navigating to www.ringsidepro.com/register"); 
            }
        }
    }, [match, match.params.id]);

    const validateRegisterForm = () => {
    // Using a type assertion to ensure that the inputs contain strings
        const email = (emailInputRef.current!.value as string);
        const password = (passwordInputRef.current!.value as string);

        if (!email) {
            setError("Please enter your email address.");
            return false;
        }
        if (!password) {
            setError("Please enter your password.");
            return false;
        }
        if (!isTermsAndConditionsChecked) {
            setError("Please agree to the Terms and Conditions.");
            return false;
        }
        setError("");
        return true;
    };

    const handleCreateAccount = async () => {
        try {
            // Show spinner
            setIsLoading(true);

            // Validate the form fields
            const isValid = validateRegisterForm();
            if (!isValid) {
                setIsLoading(false);
                return;
            }

            // Using a type assertion to ensure that the inputs contain strings
            const email = (emailInputRef.current!.value as string);
            const password = (passwordInputRef.current!.value as string);

            // Sign up the user with AWS Cognito
            const signUpResult = await signUp(email, password);
            if (signUpResult.isSuccess) {
                await handleLogin(email, password);
            } else {
                setError(signUpResult.message);
                setIsLoading(false);
            }
        } catch (error: any) {
            setError(error);
            setIsLoading(false);
        }
    };

    const handleLogin = async (email: string, password: string) => {
        try {
            const logInResult = await logIn(email, password);
            if (logInResult.isSuccess) {
                const user = await getUser();
                if (user && user.username) {
                    // If successfully created a user, add the unique organization id to the cognito user
                    if(currentOrganization?.id) {
                        const orgIdUpdateResult = await updateUserOrganizationId(user, currentOrganization.id);
                        if (!orgIdUpdateResult) {
                            setError("Sorry, a problem occurred. Please contact hello@ringsidepro.com.");
                        }
                    }
                    // If successfully created a user, create a person in the DB
                    const personId = await handleCreatePerson(email);
                    if (personId) {
                        // If successfully added the person to the DB, add the unique id to the cognito user
                        const updateResult = await updateUserPersonId(user, personId);
                        if (updateResult) {
                            navigateToDashboard();
                        } else {
                            setError("Sorry, a problem occurred. Please contact hello@ringsidepro.com.");
                            setIsLoading(false);
                        }
                    } else {
                        setError("Sorry, a problem occurred. Please try again or contact hello@ringsidepro.com.");
                        setIsLoading(false);
                    }
                } else {
                    setError("Sorry, a problem occurred. Please try again or contact hello@ringsidepro.com.");
                    setIsLoading(false);
                }
            } else {
                setError(logInResult.message);
                setIsLoading(false);
            }
        } catch (error: any) {
            setError(error);
            setIsLoading(false);
        }
    };

    const handleCreatePerson = async (email: string) => {
        try {
            const input: CreatePersonInput = {
                email: email,
                isVerified: false
            };
            const createResult = await createPerson(input);
            if (createResult.isSuccess) {
                const person = createResult.result;
                // After creating the person, add the person to Stripe
                const stripeResult = await handleCreateStripeAccount(email, person); 
                return person.id;
            }
        } catch (error: any) {
            console.error("Could not create a person.", error);
            return "";
        }
    };

    const handleCreateStripeAccount = async (email: string, person: Person) => {
        try {
            const customerResult = await createStripeCustomer("", email, person.id);
            if (customerResult.isSuccess) {
                const stripeCustomer = customerResult.result;
                if (stripeCustomer.id) {
                    const personInput: UpdatePersonInput = {
                        id: person.id,
                        stripeId: stripeCustomer.id
                    }
                    await updatePerson(personInput);
                }
            }
            return person.id;
        } catch (error: any) {
            console.error("Could not create a person.", error);
            return "";
        }
    };

    const navigateToDashboard = () => {
        const path = id ? ("/index/" + id + "/home") : "/index/home";
        history.push(path);
    };
    
    return (
        <IonPage id="orgRegisterPage" className="bg-light">
            <IonContent color="primary">
                {currentOrganization ? <OrganizationAuthNavbar organization={currentOrganization} /> : <AuthNavbar/>}
                <section className="section bg-primary">
                    <div>
                        <IonRow class="ion-justify-content-center">
                            <IonCol sizeXs="6" sizeMd="2">
                                {orgLogoURL && orgLogoURL.length > 0 && (<img
                                    src={orgLogoURL}
                                    alt={currentOrganization?.name}
                                />)}
                            </IonCol>
                        </IonRow>
                        <IonRow class="ion-justify-content-center ion-padding-bottom">
                            <IonCol sizeSm="8" sizeMd="6">
                                <IonCard color="light">
                                    <IonCardHeader className="ion-margin-top">
                                        <IonCardTitle color="primary" className="ion-text-center h3">
                                            <IonRow>
                                                <IonCol>
                                                    Create An Account
                                                </IonCol>
                                            </IonRow>
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    {isLoading ?
                                        <IonRow>
                                            <IonCol className="ion-text-center">
                                                <Spinner/>
                                            </IonCol>
                                        </IonRow>
                                        :
                                        <IonCardContent>
                                            {error && (
                                                <ErrorAlert width="12" error={error} />
                                            )}
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8">
                                                    <form>
                                                        <IonItem color="light">
                                                            <IonLabel position="floating">Email Address</IonLabel>
                                                            <IonInput 
                                                                id="register-email"
                                                                name="register-email"
                                                                type="email"
                                                                required={true}
                                                                aria-required={true}
                                                                ref={emailInputRef} 
                                                            />
                                                        </IonItem>
                                                        <IonItem color="light">
                                                            <IonLabel position="floating">Password</IonLabel>
                                                            <IonInput 
                                                                id="register-password"
                                                                type="password" 
                                                                required={true}
                                                                aria-required={true}
                                                                ref={passwordInputRef} 
                                                            />
                                                        </IonItem>
                                                        <p className="description text-light">Password must be 8 characters or longer.</p>
                                                        <IonItem
                                                            button={false}
                                                            lines="none"
                                                            color="light"
                                                            className="ion-no-padding"
                                                        >
                                                            <IonCheckbox
                                                                id="termsAndConditionsCheckbox"
                                                                checked={isTermsAndConditionsChecked}
                                                                onIonChange={e => setIsTermsAndConditionsChecked(e.detail.checked)}
                                                                slot="start"
                                                                className="ion-margin-end"
                                                                aria-required={true}
                                                            />
                                                            <TermsAndConditionsModal handleAgreement={setIsTermsAndConditionsChecked}/>
                                                        </IonItem>
                                                        <IonButton
                                                            id="registerBtn"
                                                            className="ion-margin-top"
                                                            color="tertiary"
                                                            expand="block"
                                                            onClick={handleCreateAccount}
                                                        >
                                                            Create Account
                                                        </IonButton>
                                                    </form>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className="ion-justify-content-center">
                                                <IonCol sizeSm="12" sizeMd="8" className="ion-text-center">
                                                    <IonText>Already have an account? <IonRouterLink id="loginLink" routerLink={`/${currentOrganization?.urlBackHalf}/login`}>Log In</IonRouterLink></IonText>
                                                </IonCol>
                                            </IonRow>
                                        </IonCardContent>
                                    }
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </div>
                </section>
                <Footer />
            </IonContent>
        </IonPage>
    );
};

export default OrganizationRegisterPage;
