import {
    IonContent,
    IonPage,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import Header from "../../../components/Headers/Header";
import { Organization } from "../../../models";
import PageTitle from "../../../components/PageTitle/PageTitle";
import Spinner from "../../../components/Spinners/Spinner";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import { getOrganizationById } from "../../../utilities/organization/Organization";
import OrganizationMembershipsTable from "../../../components/Memberships/OrganizationMembershipsTable";

interface _Props extends RouteComponentProps<{
    id: string;
}> {
    setOrganizationHandler: Function;
}

const ViewAllMembershipsPage: React.FC<_Props> = ({ match, setOrganizationHandler }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [organization, setOrganization] = useState<Organization>();

    useEffect(() => {
        getOrganization(match.params.id);
    }, [match, match.params.id]);

    async function getOrganization(id: string) {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationById(id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
                setOrganizationHandler(queryResult.result);
            } else {
                setError(queryResult.message);
            }
            setIsLoading(false);
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    return (
        <IonPage className="bg-light" id="view-all-memberships-page">
            <Header />
            <IonContent>
                <PageTitle title={`${(organization?.nickname || organization?.name) ?? ""} Members`}/>
                {error && <ErrorAlert width="12" error={error} />}
                {organization && !isLoading? (
                   <OrganizationMembershipsTable organizationId={organization.id} isViewOnly={true}/>
                ) : (
                    <Spinner/>
                )}
            </IonContent>
        </IonPage>
    );
};

export default ViewAllMembershipsPage;
