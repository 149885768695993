import "./../../theme/main.css";
import "./../../theme/images.css";

import {
    IonButton,
    IonCol,
    IonFooter,
    IonIcon,
    IonRow,
    IonText
} from "@ionic/react";
import {cafeSharp, logoFacebook, logoInstagram, mailSharp} from "ionicons/icons";
import React from "react";

import logo from "./../../assets/img/brand/WhiteLogo.png";

const Footer: React.FC = () => {
    return (
        <IonFooter className="ion-padding-top ion-padding-bottom bg-primary">
            <IonRow class="ion-justify-content-center">
                <IonCol sizeMd="6">
                    <IonRow>
                        <IonCol className="ion-text-center">
                            <IonText><a className="text-white" href="/contact-us">Contact Us</a></IonText>
                        </IonCol>
                        <IonCol className="ion-text-center">
                            <img
                                className="nav-img"
                                alt="RingSide Pro"
                                src={logo}
                            />
                        </IonCol>
                        <IonCol className="ion-text-center">
                            <IonText><a className="text-white" href="/privacy-policy">Privacy Policy</a></IonText>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
            <IonRow class="ion-justify-content-center">
                <IonCol sizeMd="6" className="ion-text-center">
                    <IonButton fill="clear" href="https://www.instagram.com/ringsideproinsta/">
                        <IonIcon icon={logoInstagram} color="tertiary" className="ion-padding-horizontal"></IonIcon>
                    </IonButton>
                    <IonButton fill="clear" href="https://www.facebook.com/ringsidepro">
                        <IonIcon icon={logoFacebook} color="tertiary" className="ion-padding-horizontal"></IonIcon>
                    </IonButton>
                    <IonButton fill="clear" href="mailto:hello@ringsidepro.com">
                        <IonIcon icon={mailSharp} color="tertiary" className="ion-padding-horizontal"></IonIcon>
                    </IonButton>
                    <IonButton fill="clear" href="https://www.buymeacoffee.com/ringsidepro">
                        <IonIcon icon={cafeSharp} color="tertiary" className="ion-padding-horizontal"></IonIcon>
                    </IonButton>
                </IonCol>     
            </IonRow>
            <IonRow class="ion-justify-content-center">
                <IonCol sizeMd="3" className="ion-text-center">
                    <IonText color="light" className="description">
            © {new Date().getFullYear()}{" "} RingSide Pro
                    </IonText>
                </IonCol>
            </IonRow>
        </IonFooter>
    );
};

export default Footer;
