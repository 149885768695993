import moment from "moment";
import { Block, Event, Text } from "../../models";
import { getTextsByEventId } from "../text/Text";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

interface blockData {
    name: string
    timePurchased: string
    totalCredits: number
    price: number
    currentCreditUsedCount: number
}

export async function generateEventCreditReport(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Event Credit Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Credit Block Used", "Time Purchased", "Credits Used From Block", "Cost of Credits Used"];
    // define an empty array of rows
    const tableRows: string[][] = [];

    let blockMap = new Map();

    const queryResult = await getTextsByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventTexts = queryResult.result as Text[];
        for (let i = 0; i < eventTexts.length; i++) {
            const currentText = eventTexts[i];
            const currentBlockUsed: Block | null | null | undefined = currentText.block;
            if (currentBlockUsed) {
                const currentBlockId = currentBlockUsed.id;
        
                const result = blockMap.get(currentBlockId);
                const timePurchased = result?.timePurchased || currentBlockUsed.createdOn ? moment(currentBlockUsed.createdOn).format("MM/DD/YY h:mma") : "";
                const totalCredits = result?.totalCredits || currentBlockUsed.totalCredits || 0;
                const price = result?.price || currentBlockUsed.price || 0;
                const creditsUsedInText = (currentText.alert?.messageSegments || 1);
                const currentCreditUsedCount = result?.currentCreditUsedCount ? (result.currentCreditUsedCount + creditsUsedInText) : creditsUsedInText;

                const blockData: blockData = {
                    name: totalCredits.toLocaleString(),
                    timePurchased,
                    totalCredits,
                    price,
                    currentCreditUsedCount
                };

                blockMap.set(currentBlockId, blockData);
            }
        }
    }

    let totalCredits = 0;
    let totalCost = 0;

    blockMap.forEach(element => {
        const name = element?.name || "unknown name";
        const timePurchased = element?.timePurchased || "unknown time";
        const credits = element?.currentCreditUsedCount || "unknown time";
        const costPerCredit = ( (element?.price || 0) / (element?.totalCredits || 0) ) || 0;
        const cost = "$" + (costPerCredit * (element?.currentCreditUsedCount || 0)).toFixed(2);
        const row = [
            name, 
            timePurchased,
            credits,
            cost
        ];
        tableRows.push(row);

        totalCredits = totalCredits + (element?.currentCreditUsedCount || 0);
        totalCost = totalCost + parseFloat((costPerCredit * (element?.currentCreditUsedCount || 0)).toFixed(2));
    });

    const totalCreditCostRow = [
        "Total",
        "",
        totalCredits.toLocaleString(),
        "$" + totalCost.toFixed(2)
    ];

    tableRows.push(totalCreditCostRow);
     
    // startY is basically margin-top
    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    const fileName = event.nickname ? (event.nickname + " credits.pdf") : (event.name ? event.name + " credits.pdf" : "credits.pdf");
    doc.save(fileName);
}