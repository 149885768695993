import moment from "moment";
import { Event, EventEntry, EventEntryFee } from "../../models";
import { getEventEntryFeesByEventId } from "../eventEntryFee/EventEntryFee";
import { getFeeById } from "../fee/Fee";
import { addFooters } from "./ReportFooter";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

interface feeData {
    name: string
    quantity: number
    amount: number
    totalAmount: number
    tax: number
    entryList?: EventEntryFeeData[]
}

interface EventEntryFeeData {
    entry?: EventEntry
    fee: EventEntryFee
}

export async function generateRevenueFromEventFees(event: Event) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(10);
    doc.text(`Event Fee Analysis Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    // define the columns we want and their titles
    const tableColumn = ["Charge", "Quantity", "Amount", "Total (no tax)", "Tax", "Total (tax)"];
    // define an empty array of rows
    const tableRows = [];

    let totalEventFeeAmount = 0;
    let totalEventFeeAmountWithTax = 0;
    
    let feeMap = new Map<string, feeData>();

    const queryResult = await getEventEntryFeesByEventId(event.id);
    if (queryResult.isSuccess) {
        const eventEntryFees: EventEntryFee[] = queryResult.result;
        if (eventEntryFees.length > 0) {
            for (let i = 0; i < eventEntryFees.length; i++) {
                const eventEntryFee: EventEntryFee = eventEntryFees[i];
                const eventFee = eventEntryFee.eventFee;

                let currentFeeTotal = 0;
                let currentQuantityTotal = 0;

                if (eventFee && !eventFee.eventClass) {
                    // Check the results that are currently stored in the Fee Map
                    const result = feeMap.get(eventFee.id);
                    currentFeeTotal = (result?.totalAmount ? result?.totalAmount : 0);
                    currentQuantityTotal = (result?.quantity ? result?.quantity : 0);

                    // Add this EventEntryFee to the list for this fee
                    const currentEntryList = result?.entryList || [];
                    const currentEventEntryFeeData: EventEntryFeeData = {
                        entry: eventEntryFee.entry || undefined,
                        fee: eventEntryFee
                    };
                    const updatedEntryList = eventEntryFee.entry ? currentEntryList.concat([currentEventEntryFeeData]) : [];

                    // Get the organization's fee data that relates to this EventEntryFee
                    const feeQueryResult = await getFeeById(eventFee.feeId);

                    // If this EventEntryFee has the correct status, then count it towards the totals
                    if (eventEntryFee.status === "accepted") {
                        currentFeeTotal = (result?.totalAmount ? result?.totalAmount : 0) + eventFee.amount;
                        currentQuantityTotal = (result?.quantity ? result?.quantity : 0) + 1;
                        totalEventFeeAmount = totalEventFeeAmount + eventFee.amount;
                        totalEventFeeAmountWithTax = totalEventFeeAmountWithTax + (eventFee.amount + (eventFee.amount * (feeQueryResult.result?.taxA || 0)/100));
                    }

                    // Format the fee data and store it in the Fee Map for later use
                    const feeData: feeData = {
                        name: eventFee.name,
                        quantity: currentQuantityTotal,
                        amount: eventFee.amount,
                        totalAmount: currentFeeTotal,
                        tax: feeQueryResult.result?.taxA || 0,
                        entryList: updatedEntryList
                    };
                    feeMap.set(eventFee.id, feeData);
                } else {
                    // If the EventEntryFee does not have an EventFee attached, then it was created as a Miscellaneous Fee by event admin
                    let currentTotal = 0;

                    // If this EventEntryFee has the correct status, then count it towards the totals
                    if (eventEntryFee.status === "accepted") {
                        const currentFeeAmount = eventEntryFee.amount;
                        const currentQuantityTotal = eventEntryFee.quantity || 0;
                        currentTotal = currentFeeAmount * currentQuantityTotal;
                        totalEventFeeAmount = totalEventFeeAmount + currentTotal;
                        totalEventFeeAmountWithTax = totalEventFeeAmountWithTax + (currentTotal + (eventEntryFee.amount * (eventEntryFee?.taxA || 0)/100));
                    }

                    const currentEventEntryFeeData: EventEntryFeeData = {
                        entry: eventEntryFee.entry || undefined,
                        fee: eventEntryFee
                    };

                    // Format the fee data and store it in the Fee Map for later use
                    const feeData: feeData = {
                        name: eventEntryFee.name,
                        quantity: eventEntryFee.quantity || 0,
                        amount: eventEntryFee.amount,
                        totalAmount: currentTotal,
                        tax: eventEntryFee.taxA || 0,
                        entryList: [currentEventEntryFeeData]
                    };
                    feeMap.set(eventEntryFee.id, feeData);
                }
            }
        }
    }

    // Print a main table with each Fee name and the total info for that fee
    feeMap.forEach(element => {
        const name = element?.name || "unknown name";
        const quantity = element?.quantity.toString() || "unknown quantity";
        const amount = "$" + element?.amount.toLocaleString() || "unknown amount";
        const total = "$" + element?.totalAmount.toLocaleString();
        const tax = element?.tax.toString() + "%";
        const totalWithTax = "$" + (element?.totalAmount + (element?.totalAmount * (element?.tax || 0)/100)).toLocaleString();
        const row = [
            name, 
            quantity,
            amount,
            total,
            tax,
            totalWithTax
        ];
        tableRows.push(row);
    });

    doc.setFontSize(12);

    // The final row of the main table is for the Fee totals
    const totalEventFeeRow = [
        "Total Event Fees",
        "",
        "",
        "$" + totalEventFeeAmount.toLocaleString(),
        "",
        "$" + totalEventFeeAmountWithTax.toLocaleString()
    ];

    tableRows.push(totalEventFeeRow);
     

    doc.autoTable(tableColumn, tableRows, { 
        theme: "grid",
        headStyles: {fillColor: "#73a4d3"},
        startY: 35 
    });

    // Now print a table for each of the Fees - includes the breakdown of EventEntryFees
    feeMap.forEach(element => {
        const name = element?.name || "unknown name";
        doc.setFontSize(16);
        doc.text(`Fee Type: ${name}`, 14, doc.lastAutoTable.finalY + 20);
        doc.setFontSize(12);
        const feeTableColumns = ["Count", "Entry #", "Horse Name", "Rider Name", "Fee Status", "Time Fee Created"];
        const feeRows = [];
        const entries = element.entryList;
        const sorted = entries?.sort((a: EventEntryFeeData, b: EventEntryFeeData) => {
            const aFee = a.fee;
            const bFee = b.fee;
            const statusResult = (aFee.status || "").localeCompare(bFee.status || "");
            const timeResult = aFee.createdOn.localeCompare(bFee.createdOn);
            return statusResult || timeResult;
        });
        if (sorted) {
            for (let i = 0; i < sorted.length; i++) {
                const currentData: EventEntryFeeData = sorted[i];
                const currentEntry = currentData.entry;
                const currentFee = currentData.fee;
                const row = [
                    i + 1,
                    currentEntry?.number || "",
                    currentEntry?.horse?.name || currentEntry?.horseName || "",
                    currentEntry?.rider?.name || currentEntry?.riderName || "",
                    currentFee?.status || "",
                    currentFee.createdOn ? moment(currentFee.createdOn).format("MM-DD-YYYY h:mm a") : "",
                ];
                feeRows.push(row);
            }
        }
        doc.autoTable(feeTableColumns, feeRows, { 
            theme: "grid",
            headStyles: {fillColor: "#73a4d3"},
            startY: doc.lastAutoTable.finalY + 30,
            rowPageBreak: "avoid", 
        });
    });

    // page footer
    addFooters(doc);

    // we define the name of our PDF file.
    doc.save(`revenue_from_event_fees.pdf`);
}