import { 
    IonButton,
    IonButtons,
    IonCol, 
    IonContent, 
    IonIcon, 
    IonInput, 
    IonItem, 
    IonLabel, 
    IonModal, 
    IonRow, 
    IonTitle, 
    IonToggle, 
    IonToolbar
} from "@ionic/react";
import React, { useState } from "react";
import { Event } from "../../../models";
import EventReportCard from "../EventReportCard";
import { close } from "ionicons/icons";
import { VHSAMembershipApplicationObject, generateVHSAAssociateResultsReport } from "../../../utilities/reports/VHSAResultsReport";
import { generateECResultsReportDataRows } from "../../../utilities/reports/ECResultsReport";
import { generateUSEFResultsReportDataRows } from "../../../utilities/reports/USEFResultsReport";
import { generateBasicDivisionResultsReport, generateDivisionPointTotalsReport } from "../../../utilities/reports/DivisionResultsReports";
import { generateClassPointsReport, generatePointsByYearReport } from "../../../utilities/reports/PointsReports";
import moment from "moment";
import { generateBasicClassResultsReport } from "../../../utilities/reports/ClassResultsReport";
import constants from "../../../constant/constant";
import { Input, Table } from "reactstrap";
import FileViewer from "../../FileViewer/FileViewer";
import InformationBanner from "../../Banners/InformationBanner";
import { UpdateEventInput } from "../../../API";
import { updateEvent } from "../../../utilities/events/Event";

interface _Props {
    event: Event
}

const EventReportResultsSection: React.FC<_Props> = ({event}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [progressMessage, setProgressMessage] = useState("");
    const [error, setError] = useState("");

    const [showUSEFReportModal, setShowUSEFReportModal] = useState(false);
    const [eventUSEFId, setEventUSEFId] = useState(event.usefID);

    const [showVHSACommentsModal, setShowVHSACommentsModal] = useState(false);
    const [judgeName, setJudgeName] = useState("");
    const [judgeNumber, setJudgeNumber] = useState("");
    const [secName, setSecName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [memberships, setMemberships] = useState<VHSAMembershipApplicationObject[] | null | undefined>();
    const [currentMemberName, setCurrentMemberName] = useState("");
    const [currentMemberPaymentMethod, setCurrentMemberPaymentMethod] = useState("");
    const [divisionsCancelled, setDivisionsCancelled] = useState<string[] | null | undefined>();
    const [currentDivisionName, setCurrentDivisionName] = useState("");
    const [didHoldVHSAMedalClasses, setDidHoldVHSAMedalClasses] = useState(false);
    const [comments, setComments] = useState("");
    const [pdfFile, setpdfFile] = useState<File | null | undefined>();
    const [showReportWebViewer, setShowReportWebViewer] = useState(false);

    const generateBasicClassResults = async () => {
        if (event) {
            setIsLoading(true); 
            await generateBasicClassResultsReport(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generatePointsByClassResults = async () => {
        if (event) {
            setIsLoading(true); 
            await generateClassPointsReport(event, true);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generatePointsByClassResultsNoDownload = async () => {
        if (event) {
            setIsLoading(true); 
            const resultPDF = await generateClassPointsReport(event, false);
            const file = new File([resultPDF], "Results.pdf", {
                type: "application/pdf",
            });
            setpdfFile(file);
            setShowReportWebViewer(true);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generatePointsByYearDownload = async () => {
        if (event) {
            setIsLoading(true); 
            const currentYear = moment(new Date()).format("YYYY");
            if (event.organizationId) await generatePointsByYearReport(event.organizationId, currentYear);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateBasicDivisionResults = async () => {
        if (event) {
            setIsLoading(true); 
            await generateBasicDivisionResultsReport(event);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateDivisionPointTotalResults = async () => {
        if (event) {
            setIsLoading(true); 
            await generateDivisionPointTotalsReport(event, setProgressMessage);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateVHSAAssociateResults = async () => {
        if (event) {
            setIsLoading(true);
            setShowVHSACommentsModal(true);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const handleAddMembershipObject = () => {
        const newMembershipObject: VHSAMembershipApplicationObject = {
            name: currentMemberName,
            paymentMethod: currentMemberPaymentMethod
        };

        let updatedArray: VHSAMembershipApplicationObject[] = [newMembershipObject];

        if (memberships) {
            updatedArray = memberships.concat([newMembershipObject]);
        }

        setMemberships(updatedArray);
        setCurrentMemberName("");
        setCurrentMemberPaymentMethod("");
    }

    const handleRemoveMembershipObject = (index: number) => {
        if (memberships) {
            let updatedArray: VHSAMembershipApplicationObject[] = [
                ...memberships.slice(0, index),
                ...memberships.slice(index + 1)
            ];

            setMemberships(updatedArray);
        }
    }

    const handleAddDivisionName = () => {
        let updatedArray: string[] = [currentDivisionName];

        if (divisionsCancelled) {
            updatedArray = divisionsCancelled.concat([currentDivisionName]);
        }

        setDivisionsCancelled(updatedArray);
        setCurrentDivisionName("");
    }

    const handleRemoveDivisionName = (index: number) => {
        if (divisionsCancelled) {
            let updatedArray: string[] = [
                ...divisionsCancelled.slice(0, index),
                ...divisionsCancelled.slice(index + 1)
            ];

            setDivisionsCancelled(updatedArray);
        }
    }

    const downloadVHSAAssociateResults = async () => {
        if (event) {
            setIsLoading(true); 
            setShowVHSACommentsModal(false);
            const resultPDF = await generateVHSAAssociateResultsReport(event, comments, judgeName, judgeNumber, secName, phoneNumber, memberships, divisionsCancelled, didHoldVHSAMedalClasses);
            const file = new File([resultPDF], "Results.pdf", {
                type: "application/pdf",
            });
            setpdfFile(file);
            setShowReportWebViewer(true);
            setIsLoading(false);
            
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateUSEFReport = async (isCSV: boolean) => {
        if (event) {
            setIsLoading(true);
            setShowUSEFReportModal(false);
            if (eventUSEFId && eventUSEFId !== event.usefID) {
                const updateEventInput: UpdateEventInput = {
                    id: event.id,
                    usefID: eventUSEFId
                };
                await updateEvent(updateEventInput);
            }
            await generateUSEFResultsReportDataRows(event, (eventUSEFId || undefined), isCSV);
            setIsLoading(false);
        }
        else setError("No event found. Cannot generate report.");
    }

    const generateECReport = async () => {
        if (event) {
            if (event.ecID) {
                setIsLoading(true); 
                await generateECResultsReportDataRows(event);
                setIsLoading(false);
            } else {
                setError("No EC id set for the show. Switch to the Settings tab to add your show's EC number.");
            }
        }
        else setError("No event found. Cannot generate report.");
    }

    const reportLinks = (
        <>
            {showReportWebViewer ? 
                <IonRow>
                    <IonCol>
                        <IonRow className="ion-justify-content-center">
                            <IonCol className="ion-text-center">
                                <IonButton color="danger" onClick={() => setShowReportWebViewer(false)}>
                                    Close Document Viewer
                                </IonButton>
                            </IonCol>
                        </IonRow>
                        <FileViewer file={pdfFile} />
                    </IonCol>
                </IonRow>
                :
                <IonRow>
                    <IonCol>
                        <IonRow>
                            <IonCol>
                                <p onClick={generateBasicClassResults} className="link ion-text-wrap">Class Results</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={generateBasicDivisionResults} className="link ion-text-wrap">Division Results</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={generateDivisionPointTotalResults} className="link ion-text-wrap">Division Point Totals</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={generateVHSAAssociateResults} className="link ion-text-wrap">VHSA Associate Results Report</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={() => setShowUSEFReportModal(true)} className="link ion-text-wrap">USEF Results Report (CSV or DAT)</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={generateECReport} className="link ion-text-wrap">EC Results Report</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={generatePointsByClassResults} className="link ion-text-wrap">Points by Class Report - Download</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <p onClick={generatePointsByClassResultsNoDownload} className="link ion-text-wrap">Points by Class Report - No Download</p>
                            </IonCol>
                        </IonRow>
                        {(event.organizationId === constants.RHSC_ORGANIZATION.id) && (
                            <IonRow>
                                <IonCol>
                                    <p onClick={generatePointsByYearDownload} className="link ion-text-wrap">Points by Year</p>
                                </IonCol>
                            </IonRow>
                        )}
                    </IonCol>
                </IonRow>
            }
        </>
    )

    return (
        <>
            <EventReportCard title="Results Reports" content={reportLinks} isLoading={isLoading} progressMessage={progressMessage} error={error} />
            <IonModal backdropDismiss={false} isOpen={showVHSACommentsModal} id="vhsaCommentsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Title Page
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowVHSACommentsModal(false)}
                        >
                            <p id="closeAddressModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <p>Fill out the additional information you would like on the cover page.</p>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Judge Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={judgeName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setJudgeName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Judge Membership Number</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={judgeNumber}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setJudgeNumber(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Show Secretary Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={secName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setSecName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Phone Number</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={phoneNumber}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setPhoneNumber(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="pt-4">
                        <IonCol>
                            <h4 className="underline">Membership/Horse Recording Applications Enclosed</h4>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {(memberships && memberships.length > 0) && (
                                <Table hover> 
                                    {memberships.map((membership, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p key={index}>{membership.name} - {membership.paymentMethod}</p>
                                            </td>
                                            <td>
                                                <IonButton color="danger" onClick={() => handleRemoveMembershipObject(index)}>X</IonButton>
                                            </td>
                                        </tr>
                                    ))}
                                </Table>
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="5">
                            <IonItem color="white">
                                <IonLabel position="stacked">Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={currentMemberName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setCurrentMemberName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="5">
                            <IonItem color="white">
                                <IonLabel position="stacked">Cash or Check #</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={currentMemberPaymentMethod}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setCurrentMemberPaymentMethod(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="2" className="ion-text-right">
                            <IonButton color="tertiary" onClick={handleAddMembershipObject}>Add</IonButton>
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow className="pt-4">
                        <IonCol>
                            <h4 className="underline">Divisions Cancelled</h4>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {(divisionsCancelled && divisionsCancelled.length > 0) && (
                                    <Table hover> 
                                    {divisionsCancelled.map((division, index) => (
                                        <tr key={index}>
                                            <td>
                                                <p key={index}>{division}</p>
                                            </td>
                                            <td>
                                                <IonButton color="danger" onClick={() => handleRemoveDivisionName(index)}>X</IonButton>
                                            </td>
                                        </tr>
                                    ))}
                                </Table>
                            )}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs="12" sizeMd="10">
                            <IonItem color="white">
                                <IonLabel position="stacked">Name</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={currentDivisionName}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setCurrentDivisionName(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                        <IonCol sizeXs="12" sizeMd="2" className="ion-text-right">
                            <IonButton color="tertiary" onClick={handleAddDivisionName}>Add</IonButton>
                        </IonCol>
                    </IonRow>
                    <hr />
                    <IonRow className="pt-4">
                        <IonCol size="12">
                            <IonItem lines="none">
                                <IonLabel>Did you hold VHSA Medal Classes?</IonLabel>
                                <IonToggle id="vhsaToggle" color="tertiary" checked={didHoldVHSAMedalClasses} onIonChange={e => setDidHoldVHSAMedalClasses(e.detail.checked)}/>
                            </IonItem> 
                        </IonCol>
                    </IonRow>
                    <IonRow className="pt-4"> 
                        <IonCol>
                            <IonLabel>Comments</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <Input
                                rows="5"
                                type="textarea"
                                name="declineReason"
                                value={comments}
                                spellCheck="true"
                                onChange={(event) => setComments(event.target.value)}
                                data-hj-whitelist
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol className="ion-text-center">
                            <IonButton onClick={downloadVHSAAssociateResults}>Download</IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
            <IonModal backdropDismiss={false} isOpen={showUSEFReportModal} id="vhsaCommentsModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        USEF Results Report
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowUSEFReportModal(false)}
                        >
                            <p id="closeUSEFReportModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow>
                        <IonCol>
                            <p>If your event is USEF rated, please include the event's USEF id.</p>
                            <InformationBanner info="Otherwise, the report will still download. It just cannot be submitted to USEF." />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Event Id</IonLabel>
                                <IonInput 
                                    type="text"
                                    value={eventUSEFId}
                                    aria-required={true}
                                    onIonChange={e => {
                                        setEventUSEFId(e.detail.value!)
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton onClick={() => generateUSEFReport(true)}>Download CSV</IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton onClick={() => generateUSEFReport(false)}>Download DAT</IonButton>
                        </IonCol>
                    </IonRow>
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventReportResultsSection;
