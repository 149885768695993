import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import SelectState from "./SelectState";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import SelectCountry from "./SelectCountry";
import { createAddress, updateAddress } from "../../utilities/address/Address";
import ErrorAlert from "../Errors/ErrorAlert";
import { Address } from "../../models";
import { createAddressName } from "../../utilities/address/FormatAddress";
import { CreateAddressInput, UpdateAddressInput } from "../../API";

interface _Props {
    address?: (Address | null)
    onSubmit: Function
}

const CondensedAddressForm: React.FC<_Props> = ({address, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isMounted, setIsMounted] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [streetAddressLine1, setStreetAddressLine1] = useState<string>(address?.streetAddress1 || "");
    const [streetAddressLine2, setStreetAddressLine2] = useState<string>(address?.streetAddress2 || "");
    const [city, setCity] = useState<string>(address?.city || "");
    const [provState, setProvState] = useState<string>(address?.provState || "");
    const [zip, setZip] = useState<string>(address?.zip || "");
    const [country, setCountry] = useState<string>(address?.country || "");
    const [error, setError] = useState<string>("");

    const handleProvStateInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setProvState(value);
    }

    const handleCountryInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-") setCountry(value);
    }

    const handleCreateAddress = async () => {
        try {
            const addressInput: CreateAddressInput = {
                name: createAddressName(user.id, "mailing"),
                type: "mailing",
                streetAddress1: streetAddressLine1,
                streetAddress2: streetAddressLine2,
                city: city,
                provState: provState,
                zip: zip,
                country: country
            };
            const createResult = await createAddress(addressInput);
            if (createResult.isSuccess) {
                onSubmit(createResult.result);
            } else {
                setError(createResult.message);
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleEditAddress = async () => {
        try {
            if (address) {
                const addressInput: UpdateAddressInput = {
                    id: address.id,
                    name: createAddressName(user.id, "mailing"),
                    type: "mailing",
                    streetAddress1: streetAddressLine1,
                    streetAddress2: streetAddressLine2,
                    city: city,
                    provState: provState,
                    zip: zip,
                    country: country
                };
                const updateResult = await updateAddress(addressInput);
                if (updateResult.isSuccess) {
                    onSubmit(updateResult.result);
                } else {
                    setError(updateResult.message);
                }
            }
        } catch (error: any) {
            setError(error);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        if (address) {
            await handleEditAddress();
        } else {
            await handleCreateAddress();
        }
        setIsDisabled(true);
        setIsLoading(false);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            <form>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Street Address Line 1</IonLabel>
                            <IonInput 
                                type="text"
                                value={streetAddressLine1}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setStreetAddressLine1(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Street Address Line 2</IonLabel>
                            <IonInput 
                                type="text"
                                value={streetAddressLine2}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setStreetAddressLine2(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">City</IonLabel>
                            <IonInput 
                                type="text"
                                value={city}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setCity(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <SelectState onInputChange={handleProvStateInputChange} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonItem color="white">
                            <IonLabel position="stacked">Postal Code</IonLabel>
                            <IonInput 
                                type="text"
                                value={zip}
                                aria-required={true}
                                onIonChange={e => {
                                    if(isMounted) setIsDisabled(false);
                                    else setIsMounted(true);
                                    setZip(e.detail.value!)
                                }}
                            />
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <SelectCountry onInputChange={handleCountryInputChange} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol sizeMd="4">
                        {isLoading ?
                            <Spinner />
                            :
                            <IonButton
                                disabled={isDisabled}
                                className="ion-margin-top"
                                color="tertiary"
                                expand="block"
                                onClick={handleSubmit}
                            >
                                {isDisabled ? "Saved" : "Save"}
                            </IonButton>
                        }
                    </IonCol>
                </IonRow>
            </form>
        </>
    );
};

export default CondensedAddressForm;