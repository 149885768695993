import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PersonNameForm from "../../../components/SettingsPage/PersonNameForm";
import React from "react";
import BirthDateForm from "../../../components/SettingsPage/BirthDateForm";
import ProfilePictureForm from "../../../components/SettingsPage/ProfilePictureForm";
import RolesForm from "../../../components/SettingsPage/RolesForm";

const EditAccountPage: React.FC = () => {
    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Edit Personal Info" />
                <IonRow id="profile-picture" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Profile Picture</IonCardTitle>
                            <IonCardContent>
                                <ProfilePictureForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow id="name" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Name</IonCardTitle>
                            <IonCardContent>
                                <PersonNameForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow id="birthday" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Birthday</IonCardTitle>
                            <IonCardContent>
                                <p>We collect birthdays to ensure you qualify for divisions with age groups restrictions.</p>
                                <BirthDateForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <IonRow id="roles" className="ion-justify-content-center">
                    <IonCol sizeSm="12" sizeMd="10">
                        <IonCard mode="md" className="ion-padding bg-white">
                            <IonCardTitle>Your Roles</IonCardTitle>
                            <IonCardContent>
                                <RolesForm />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EditAccountPage;