import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateOwnerInput, CreateOwnerMutation, DeleteOwnerInput, DeleteOwnerMutation, GetOwnerQuery, ListOwnersQuery, UpdateOwnerInput, UpdateOwnerMutation } from "../../API";
import moment from "moment";
import { Owner } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: owner. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created owner.
*/
export async function createOwner(input: CreateOwnerInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Owner", "No input", "Create Owner received no input.");
   try {
        const fullInput: CreateOwnerInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createOwner, { input: fullInput }))) as GraphQLResult<CreateOwnerMutation>;
       else result = (await API.graphql({
           query: mutations.createOwner,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateOwnerMutation>;
       const owner = result.data?.createOwner;
       return formatResult(true, "Owner", owner, "Successfully created the owner.");
   } catch (error: any) {
       return formatResult(false, "Owner", error, "Error creating record in the database for type: owner");
   }
}

/**
* Handle updating a new record in the database for type: owner. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated owner.
*/
export async function updateOwner(input: UpdateOwnerInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Owner", "No input", "Update Owner received no input.");
   try {
        const fullInput: UpdateOwnerInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateOwner, { input: fullInput }))) as GraphQLResult<UpdateOwnerMutation>;
       else result = (await API.graphql({
           query: mutations.updateOwner,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateOwnerMutation>;
       const owner = result.data?.updateOwner;
       return formatResult(true, "Owner", owner, "Successfully updated the owner.");
   } catch (error: any) {
       return formatResult(false, "Owner", error, "Error updating record in the database for type: owner");
   }
}

/**
* Handle deleting a new record in the database for type: owner. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted owner.
*/
export async function deleteOwner(input: DeleteOwnerInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Owner", "No input", "Delete Owner received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteOwner, { input: input }))) as GraphQLResult<DeleteOwnerMutation>;
       else result = (await API.graphql({
           query: mutations.deleteOwner,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteOwnerMutation>;
       const owner = result.data?.deleteOwner;
       return formatResult(true, "Owner", owner, "Successfully deleted the owner.");
   } catch (error: any) {
       return formatResult(false, "Owner", error, "Error deleting record in the database for type: owner");
   }
}

/**
* Get all records in the database for type: owner. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the owner object
*/
export async function getAllOwners(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listOwners,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOwnersQuery>;
        
        let items = result.data?.listOwners?.items as Owner[];
        let nextToken = result.data?.listOwners?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOwners,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOwnersQuery>;

            const nextItems = nextResult.data?.listOwners?.items as Owner[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOwners?.nextToken;
        }

        return formatResult(true, "Owner", items, "Successfully got the Owners.");
    } catch (error: any) {
        return formatResult(false, "Owner", error, "Error reading record in the database for type: owners");
    }
}

/**
* Read a specific record in the database for type: owner. 
* 
* @param {string}  id                  The owner id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the owner object
*/
export async function getOwnerById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getOwner,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetOwnerQuery>;
       const owner = result.data?.getOwner;
       return formatResult(true, "Owner", owner, "Successfully got the owner.");
   } catch (error: any) {
       return formatResult(false, "Owner", error, "Error reading record in the database for type: owner");
   }
}

/**
* Get all records in the database that match the given criteria for type: owner. 
* 
* @param {string}  personId            The personId of the owner.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the owner object
*/
export async function getOwnerByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listOwners,
            variables: {
                limit: 100,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOwnersQuery>;

        let items = result.data?.listOwners?.items as Owner[];
        let nextToken = result.data?.listOwners?.nextToken;

        while (nextToken && (!items || (items && items.length === 0))) {
            const nextResult = await API.graphql({
                query: queries.listOwners,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOwnersQuery>;

            const nextItems = nextResult.data?.listOwners?.items as Owner[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOwners?.nextToken;
        }
        
        const owners = items;
        if (owners && owners[0]) return formatResult(true, "Owner", owners[0], "Successfully got the owner.");
        else return formatResult(false, "Owner", null, "Could not find the owner.");
    } catch (error: any) {
        return formatResult(false, "Owner", error, "Error reading record in the database for type: owner");
    }
}

/**
* Get all records in the database that match the given criteria for type: owner. 
* 
* @param {string}  personId            The person id of the "owner" of the owner
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the owner object
*/
export async function getOwnersByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listOwners,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOwnersQuery>;
        
        let items = result.data?.listOwners?.items as Owner[];
        let nextToken = result.data?.listOwners?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOwners,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOwnersQuery>;

            const nextItems = nextResult.data?.listOwners?.items as Owner[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOwners?.nextToken;
        }

        return formatResult(true, "Owner", items, "Successfully got the Owners.");
    } catch (error: any) {
        return formatResult(false, "Owner", error, "Error reading record in the database for type: owners");
    }
}

/**
* Get all records in the database that match the given criteria for type: owner. 
* 
* @param {string}  personId            The person id of the person who created the owner
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the owner object
*/
export async function getOwnersByCreatedBy(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {createdBy: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listOwners,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOwnersQuery>;
        
        let items = result.data?.listOwners?.items as Owner[];
        let nextToken = result.data?.listOwners?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOwners,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOwnersQuery>;

            const nextItems = nextResult.data?.listOwners?.items as Owner[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOwners?.nextToken;
        }

        return formatResult(true, "Owner", items, "Successfully got the Owners.");
    } catch (error: any) {
        return formatResult(false, "Owner", error, "Error reading record in the database for type: owners");
    }
}

/**
* Get all records in the database that match the given criteria for type: owner. 
* 
* @param {string}  barnId              The barn id of the person 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the owner object
*/
export async function getOwnersByBarnId(barnId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {barnId: {eq: barnId}};
        const result = (await API.graphql({
            query: queries.listOwners,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOwnersQuery>;
        
        let items = result.data?.listOwners?.items as Owner[];
        let nextToken = result.data?.listOwners?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOwners,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOwnersQuery>;

            const nextItems = nextResult.data?.listOwners?.items as Owner[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOwners?.nextToken;
        }

        return formatResult(true, "Owner", items, "Successfully got the Owners.");
    } catch (error: any) {
        return formatResult(false, "Owner", error, "Error reading record in the database for type: owners");
    }
}

/**
* Get all records in the database that match the given criteria for type: owner. 
* 
* @param {string}  ownerName           The name of the owner
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the owner object
*/
export async function getOwnersByName(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {name: {contains: personId}};
        const result = (await API.graphql({
            query: queries.listOwners,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListOwnersQuery>;
        
        let items = result.data?.listOwners?.items as Owner[];
        let nextToken = result.data?.listOwners?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listOwners,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListOwnersQuery>;

            const nextItems = nextResult.data?.listOwners?.items as Owner[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listOwners?.nextToken;
        }

        return formatResult(true, "Owner", items, "Successfully got the Owners.");
    } catch (error: any) {
        return formatResult(false, "Owner", error, "Error reading record in the database for type: owners");
    }
}