import {
    IonButton,
    IonCol,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { Event, EventDivision, EventClass, EventClassEntry, EventResult } from "../../models";
import ErrorAlert from "../Errors/ErrorAlert";
import { PersonContext } from "../../context/PersonContext";
import Spinner from "../Spinners/Spinner";
import SuccessBanner from "../Banners/SuccessBanner";
import SelectEventClassEntry from "../EventClassEntry/SelectEventClassEntry";
import { getEventClassEntriesByEventClassId } from "../../utilities/eventClassEntry/EventClassEntry";
import { CreateEventResultInput } from "../../API";
import moment from "moment";
import { createEventResult, deleteEventResult, getEventResultsByEventDivisionId } from "../../utilities/eventResult/EventResult";
import { getEventDivisionById } from "../../utilities/eventDivision/EventDivision";

interface _Props {
    event: Event
    eventDivision?: EventDivision
    canEdit?: boolean
    onSubmit: Function
}

const EventDivisionResultsForm: React.FC<_Props> = ({eventDivision, event, canEdit, onSubmit}) => {
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [currentDivision, setCurrentDivision] = useState<EventDivision | null | undefined>();
    const [currentClass, setCurrentClass] = useState<EventClass | null | undefined>();
    const [currentClassEntries, setCurrentClassEntries] = useState<EventClassEntry[] | null | undefined>();
 
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState<string>("");
    const [error, setError] = useState<string>("");

    const [firstPlace, setFirstPlace] = useState<EventClassEntry | null | undefined>();
    const [secondPlace, setSecondPlace] = useState<EventClassEntry | null | undefined>();

    const [firstPlaceResult, setFirstPlaceResult] = useState<EventResult | null | undefined>();
    const [secondPlaceResult, setSecondPlaceResult] = useState<EventResult | null | undefined>();

    async function getEventClassEntries(eventClasses: EventClass[]) {
        let eventClassEntryList: EventClassEntry[] = [];

        for (let i = 0; i < eventClasses.length; i++) {
            const eventClass: EventClass = eventClasses[i];
            const queryResult = await getEventClassEntriesByEventClassId(eventClass.id);
            if (queryResult.isSuccess) {
                const currentEventClassEntries: EventClassEntry[] = queryResult.result;
                if (i === 0) eventClassEntryList = currentEventClassEntries;
                else {
                    for (let j = 0; j < currentEventClassEntries.length; j++) {
                        const current = currentEventClassEntries[j];
                        const found = eventClassEntryList.findIndex(eventClassEntry => eventClassEntry.id === current.id);
                        if (found === -1) eventClassEntryList.push(current);
                    }
                }
            }
        }
        setCurrentClassEntries(eventClassEntryList);
    }

    function setResult(currentEventResult: EventResult) {
        if (currentEventResult.place === 1) setFirstPlaceResult(currentEventResult);
        if (currentEventResult.place === 2) setSecondPlaceResult(currentEventResult);
    }

    async function getEventDivisionResults(eventDivisionId: string) {
        const queryResult = await getEventResultsByEventDivisionId(eventDivisionId);
        if (queryResult.isSuccess) {
            setIsEditing(false);
            const resultsArray: EventResult[] = queryResult.result;
            resultsArray.forEach(currentEventResult => {
                setResult(currentEventResult);
            });
        }
    }

    useEffect(() => {
        async function setEventDivision(eventDivision: EventDivision) {
            clearAllForm();
            const queryResult = await getEventDivisionById(eventDivision.id);
            if (queryResult.isSuccess) {
                const eventDivisionResult = queryResult.result;
                setCurrentDivision(eventDivisionResult);
                if (eventDivisionResult && eventDivisionResult.eventClasses && eventDivisionResult.eventClasses.items && eventDivisionResult.eventClasses.items[0]) {
                    const eventClass = eventDivisionResult.eventClasses.items[0];
                    setCurrentClass(eventClass);
                    getEventClassEntries(eventDivisionResult.eventClasses);
                } else {
                    setError("Could not find entries.");
                }

                if (eventDivisionResult && eventDivisionResult.id) {
                    getEventDivisionResults(eventDivisionResult.id);
                }
            } else {
                setError("Could not find the division");
            }
        }
        
        if (eventDivision) setEventDivision(eventDivision);
    }, [eventDivision]);

    const verifyForm = () => {
        try {
            return true;
        } catch (error: any) {
            return false;
        }
    }

    const clearForm = () => {
        setFirstPlace(undefined);
        setSecondPlace(undefined);
        setError("");
    }
    
    const clearAllForm = () => {
        setIsEditing(false);
        setFirstPlace(undefined);
        setSecondPlace(undefined);
        setError("");
        eventDivision = undefined;
    }

    const handleSelectedEventClassEntry = (place: string, eventClassEntry: EventClassEntry) => {
        if (place && eventClassEntry) {
            if (place === "first") setFirstPlace(eventClassEntry);
            if (place === "second") setSecondPlace(eventClassEntry);
        }
        
        setIsDisabled(false);
    }

    const createResult = async (place: number, eventClassEntry: EventClassEntry) => {
        if (currentDivision) {
            const input: CreateEventResultInput = {
                eventId: event.id,
                entryId: eventClassEntry.eventEntry?.id || "",
                eventResultEntryId: eventClassEntry.eventEntry?.id || "",
                eventClassEntryId: eventClassEntry.id,
                eventResultEventClassEntryId: eventClassEntry.id,
                eventDivisionId: currentDivision.id,
                // eventResultEventDivisionId: currentDivision.id,
                eventDivisionName: currentDivision.name,
                place: place,
                prizeMoney: 0,
                createdBy: user.id,
                createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
            };
            const createResult = await createEventResult(input);
            if (createResult.isSuccess) setResult(createResult.result);
            
        } else {
            setError("Could not find the division.");
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);

        const isValid = verifyForm();
        if (!isValid) {
            setIsLoading(false);
            return;
        }

        if (firstPlaceResult) await deleteEventResult({id: firstPlaceResult.id});
        if (secondPlaceResult) await deleteEventResult({id: secondPlaceResult.id});

        if (firstPlace) await createResult(1, firstPlace);
        if (secondPlace) await createResult(2, secondPlace);

        setIsLoading(false);
        onSubmit();
    }

    const handleDelete = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        setIsLoading(false);
        onSubmit();
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {success && <SuccessBanner width="12" success={success}/>}
            <IonRow>
                <IonCol>
                    {eventDivision ?
                        <h3>{eventDivision.name}</h3>
                        :
                        <p>Error</p>
                    }
                </IonCol>
            </IonRow>
            {!isEditing ?
                <>
                    <IonRow>
                        <IonCol>
                            {firstPlaceResult && <p>Champion: {firstPlaceResult.entry.number} - {firstPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            {secondPlaceResult && <p>Reserve: {secondPlaceResult.entry.number} - {secondPlaceResult.entry.horseName}</p>}
                        </IonCol>
                    </IonRow>
                    {canEdit && (
                        <IonRow>
                            <IonCol sizeMd="12">
                                <IonButton
                                    className="ion-margin-top"
                                    color="tertiary"
                                    expand="block"
                                    onClick={() => setIsEditing(true)}
                                >
                                    Edit Results
                                </IonButton>
                            </IonCol>
                        </IonRow>
                    )}
                </>
                :
                <>
                    {isLoading ?
                        <Spinner />
                        :
                        <form>
                            <IonRow>
                                <IonCol>
                                <IonLabel>Champion</IonLabel>
                                    {currentClass ?
                                        <SelectEventClassEntry eventClassId={currentClass.id} values={currentClassEntries} selectedValue={firstPlace?.id || firstPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("first", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                <IonLabel>Reserve</IonLabel>
                                    {currentClass ?
                                        <SelectEventClassEntry eventClassId={currentClass.id} values={currentClassEntries} selectedValue={secondPlace?.id || secondPlaceResult?.eventClassEntryId} onSelect={(event: any) => handleSelectedEventClassEntry("second", event)} />
                                        :
                                        <p>Error</p>
                                    }
                                </IonCol>
                            </IonRow>
                            {error && <ErrorAlert width="12" error={error}/>}
                            <IonRow>
                                <IonCol sizeMd="12">
                                    {isLoading ?
                                        <Spinner />
                                        :
                                        <IonButton
                                            className="ion-margin-top"
                                            color="tertiary"
                                            expand="block"
                                            onClick={handleSubmit}
                                        >
                                            Save Results
                                        </IonButton>
                                    }
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                {eventDivision && (
                                    <>
                                        <IonCol sizeMd="4">
                                            {isLoading ?
                                                <Spinner />
                                                :
                                                <IonButton
                                                    className="ion-margin-top"
                                                    color="primary"
                                                    expand="block"
                                                    onClick={clearForm}
                                                >
                                                    Reset Form
                                                </IonButton>
                                            }
                                        </IonCol>
                                        <IonCol offsetMd="4" sizeMd="4">
                                            {isLoading ?
                                                <Spinner />
                                                :
                                                <IonButton
                                                    disabled={isDisabled}
                                                    className="ion-margin-top"
                                                    color="danger"
                                                    expand="block"
                                                    onClick={handleDelete}
                                                >
                                                    Delete
                                                </IonButton>
                                            }
                                        </IonCol>
                                    </>
                                )}
                            </IonRow>
                        </form>
                    }
                </>
            }
        </>
    );
};

export default EventDivisionResultsForm;