import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import AddressFormGroup from "../Address/AddressFormGroup";
import ErrorAlert from "../Errors/ErrorAlert";
import SelectOrganizationType from "../Organization/SelectOrganizationType";
import SelectOrganizationIndustry from "../Organization/SelectOrganizationIndustry";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { createAddress } from "../../utilities/address/Address";
import { createOrganization, updateOrganization } from "../../utilities/organization/Organization";
import {useHistory} from "react-router";
import { createStripeCustomer } from "../../utilities/stripe/CreateCustomer";
import { createContact } from "../../utilities/contact/Contact";
import { formatURL } from "../../utilities/contact/FormatURL";
import { CreateAddressInput, CreateContactInput, CreateOrganizationInput, OrganizationType, UpdateOrganizationInput } from "../../API";
import InformationBanner from "../Banners/InformationBanner";
import { Input } from "reactstrap";
import { getUser, updateUserOrganizationId, UserAttributes } from "../../utilities/user/User";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

const CreateEventOrganizationForm: React.FC = () => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [nickname, setNickname] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [streetAddressLine1, setStreetAddressLine1] = useState("");
    const [streetAddressLine2, setStreetAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [provState, setProvState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [type, setType] = useState("");
    const [industry, setIndustry] = useState("");
    const [error, setError] = useState("");

    const verifyIsValid = () => {
        if (!name) {
            setError("You must include a name for the organization.");
            return false;
        }
        if (!email) {
            setError("You must include a email for the organization.");
            return false;
        }
        if (!type) {
            setError("You must include a type for the organization.");
            return false;
        }
        if (!industry) {
            setError("You must include an industry for the organization.");
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        setIsDisabled(true);
        const isValid = verifyIsValid();
        if (isValid) {
            await handleCreateOrganization();
        }
        setIsLoading(false);
    }

    const handleTypeInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-"){
            setType(value);
        } else {
            setType("");
        }
    }

    const handleIndustryInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-"){ 
            setIndustry(value);
        } else {
            setIndustry("");
        }
    }

    const handleAddressInputChange = (type: string, value: string) => {
        if (type === "streetAddressLine1") setStreetAddressLine1(value);
        if (type === "streetAddressLine2") setStreetAddressLine2(value);
        if (type === "city") setCity(value);
        if (type === "provState") setProvState(value);
        if (type === "zip") setZip(value);
        if (type === "country") setCountry(value);
    };

    const handleCreateOrganization = async () => {
        try {
            let addressid = "";
            let contactid = "";
            let contact = undefined;

            // First, create the Address Object
            const addressName = name + "_mailing_address";
            const addressInput: CreateAddressInput = {
                name: addressName,
                type: "mailing",
                streetAddress1: streetAddressLine1,
                streetAddress2: streetAddressLine2,
                city: city,
                provState: provState,
                zip: zip,
                country: country
            };
            const createAddressResult = await createAddress(addressInput);
            if (createAddressResult.isSuccess) {
                addressid = createAddressResult.result.id;
            }

            // Second, create the Contact Object
            const contactInput: CreateContactInput = {
                name: name,
                workEmail: email,
                mailingAddress: addressid,
                website: website ? formatURL(website) : undefined
            };
            const createContactResult = await createContact(contactInput);
            if (createContactResult.isSuccess) {
                contactid = createContactResult.result.id;
                contact = createContactResult.result;
            } else {
                setError("An error occurred - please check that your contact information is correct.");
                return;
            }
            const organizationType : OrganizationType = type as OrganizationType;
            // Next, create the organization
            const input: CreateOrganizationInput = {
                createdBy: user.id,
                name: name,
                nickname: nickname,
                type: organizationType,
                industry: industry,
                description: description,
                website: website,
                addresses: [addressid],
                contactId: contactid
            };
            const createOrgResult = await createOrganization(input);
            if (createOrgResult.isSuccess) {
                const createCustomerResult = await createStripeCustomer(name, email, user.id, createOrgResult.result.id);
                if (createCustomerResult.isSuccess) {
                    const updateInput: UpdateOrganizationInput = {
                        id: createOrgResult.result.id,
                        stripeCustomerId: createCustomerResult.result.id
                    };
                    await updateOrganization(updateInput);
                    const user = await getUser();
                    if (user) {
                        const attributes: UserAttributes = user.attributes;
                        const prevOrganizationIds = attributes["custom:organizationids"] ?? "";
                        const orgIdsArray = prevOrganizationIds.split(",");
                        // Add Organization Id to custom attribute if not present
                        if(!orgIdsArray.includes(createOrgResult.result.id)) {
                            await updateUserOrganizationId(user, createOrgResult.result.id);
                        }
                    }
                    navigateBack(createOrgResult.result.id);
                }
            } else {
                setError("Could not create the organization");
            }
        } catch (error: any) {
            const message = "Could not create the organization: " + error;
            setError(message);
        }
    }

    const navigateBack = (organizationId: string) => {
        const location = {
            pathname: "/index/staff/organization",
            state: { organizationId: organizationId }
        }
        history.replace(location);
    };

    return (
        <form>
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol>
                    <InformationBanner info="When you set up a new organization, you will be set as the primary contact person of this organization."/>
                </IonCol>                
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="floating">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            id="create-org-name"
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setName(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6" >
                    <IonItem color="white">
                        <IonLabel position="floating">Nickname/Abbreviation</IonLabel>
                        <IonInput 
                            id="create-org-nickname"
                            type="text"
                            value={nickname}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setNickname(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonLabel position="floating" className="text-darker pl-3">Description</IonLabel>
                    <Input
                        id="create-org-description"
                        rows="3"
                        type="textarea"
                        name="description"
                        value={description}
                        spellCheck="true"
                        onChange={e => {
                            setIsDisabled(false);
                            setDescription(e.target.value!);
                        }}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="floating">Email Address <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            id="create-org-email"
                            type="email"
                            value={email}
                            aria-required={true}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setEmail(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="floating">Website</IonLabel>
                        <IonInput 
                            id="create-org-website"
                            type="text"
                            value={website}
                            onIonChange={e => {
                                setIsDisabled(false);
                                setWebsite(e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectOrganizationType isRequired={true} onInputChange={handleTypeInputChange}/>

                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectOrganizationIndustry isRequired={true} onInputChange={handleIndustryInputChange} />
                </IonCol>
            </IonRow>
            <AddressFormGroup id="create-org" onChange={handleAddressInputChange} setIsDisabled={setIsDisabled} />
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            id="submit-org-btn"
                            disabled={isDisabled}
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Create Organization
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default CreateEventOrganizationForm;