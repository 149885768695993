import {
    IonCol,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import logo from "./../../assets/img/brand/MainLogo.png";
import { Organization } from "../../models";
import { LogoInput } from "../../API";
interface _Props {
    organization?: Organization
}

const AuthMenu: React.FC<_Props> = ({organization}) => {
    const [logos, setLogos] = useState<(LogoInput | null)[] | null[]>(organization?.logos ?? []);
    const [logoUrl, setLogoUrl] = useState<string>(logos[0]?.logoURL ?? "");
    const [showAllMenus, setShowAllMenus] = useState<boolean>(true);
    useEffect(() => {
        setLogos(organization?.logos ?? []);
        setLogoUrl(logos[0]?.logoURL ?? "");
        if(organization?.name) {
            setShowAllMenus(false);
        }
    }, [organization]);
    return (
        <IonMenu contentId="unauth" side="end">
            <IonContent>
                <IonList id="menu-list" className="ion-margin-top">
                    <IonRow className="ion-justify-content-center ion-margin-bottom">
                        <IonCol sizeMd="6" className="text-center">  
                            <IonListHeader>
                                <IonMenuToggle autoHide={false}>
                                    <IonItem
                                        lines="none"
                                        detail={false}
                                        routerDirection="none"
                                        routerLink={organization?.urlBackHalf ? "/" + organization.urlBackHalf + "/login" : "/home"}
                                    >
                                        <img
                                            className="main-img"
                                            src={logoUrl.length > 0 ? logoUrl : logo}
                                            alt={organization?.name ? organization.name : "RingSide Pro"}
                                        />
                                    </IonItem>
                                </IonMenuToggle>
                            </IonListHeader>
                        </IonCol>
                    </IonRow>
                    {showAllMenus && (<IonMenuToggle autoHide={false}>
                        <IonItem 
                            button
                            routerLink={"/home"} 
                            routerDirection="none"
                            lines="none"
                            detail={false}
                        >
                            <IonLabel>Home</IonLabel>
                        </IonItem>
                        <IonItem 
                            button
                            href="/about"
                            routerLink={"/about"} 
                            routerDirection="none"
                            lines="none"
                            detail={false}
                        >
                            <IonLabel>About</IonLabel>
                        </IonItem>
                        <IonItem 
                            button
                            routerLink={"/online-entries"} 
                            routerDirection="none"
                            lines="none"
                            detail={false}
                        >
                            <IonLabel>Online Entry Software</IonLabel>
                        </IonItem>
                        <IonItem 
                            button
                            routerLink={"/text-alerts"} 
                            routerDirection="none"
                            lines="none"
                            detail={false}
                        >
                            <IonLabel>Text Alert Software</IonLabel>
                        </IonItem>
                        <IonItem 
                            button
                            routerLink={"/online-clinics"} 
                            routerDirection="none"
                            lines="none"
                            detail={false}
                        >
                            <IonLabel>Online Clinics</IonLabel>
                        </IonItem>
                    </IonMenuToggle>)}
                    <hr className="ion-no-margin"></hr>
                    <IonMenuToggle autoHide={false}>
                        <IonItem 
                            button
                            routerLink={organization?.urlBackHalf ? "/" + organization.urlBackHalf + "/login" : "/login"}
                            routerDirection="none"
                            lines="none"
                            detail={false}
                        >
                            <IonLabel>Login</IonLabel>
                        </IonItem>
                        <IonItem 
                            button
                            routerLink={organization?.urlBackHalf ? "/" + organization.urlBackHalf + "/register" : "/register"}
                            routerDirection="none"
                            lines="none"
                            detail={false}
                        >
                            <IonLabel>Create Account</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                </IonList>
            </IonContent>
        </IonMenu>
    );
};
  
export default AuthMenu;
  