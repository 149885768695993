import {
    IonInput,
    IonItem,
    IonRow,
    IonCol,
    isPlatform,
    IonLabel
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Address } from "../../models";
import SelectState from "./SelectState";
import SelectCountry from "./SelectCountry";
import { isWindows } from "../../utilities/platform/Platform";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    id?: string
    address?: Address
    onChange: Function
    setIsDisabled: Function
    isRequired?: boolean
    isTextDarker?: boolean
}

const AddressFormGroup: React.FC<_Props> = ({id, address, onChange, setIsDisabled, isRequired, isTextDarker}) => {

    const [streetAddressLine1, setStreetAddressLine1] = useState<string>(address?.streetAddress1 || "");
    const [streetAddressLine2, setStreetAddressLine2] = useState<string>(address?.streetAddress2 || "");
    const [city, setCity] = useState<string>(address?.city || "");
    const [zip, setZip] = useState<string>(address?.zip || "");

    useEffect(() => {
        if (address) {
            setStreetAddressLine1(address.streetAddress1 || "");
            setStreetAddressLine2(address.streetAddress2 || "");
            setCity(address.city || "");
            setZip(address.zip || "");
        } else {
            setStreetAddressLine1("");
            setStreetAddressLine2("");
            setCity("");
            setZip("");
        }
    }, [address])

    const handleProvStateInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-"){
            onChange("provState", value);
        } else {
            onChange("provState", "");
        }
    };
    
    const handleCountryInputChange = (value: string) => {
        setIsDisabled(false);
        if (value && value !== "-"){
            onChange("country", value);
        } else {
            onChange("country", "");
        }
    };

    return (
        <>
            <IonRow className="mb-3">
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel
                            position={isWindows() || isPlatform("desktop") ? "floating": "stacked"}
                            className="text-darker"
                        >
                            Street Address Line 1{" "}
                            {isRequired && (<RequiredInputIndicator />)}
                        </IonLabel>
                        <IonInput
                            id={id ? `${id}-streetadd1` : "streetadd1"}
                            type="text"
                            value={streetAddressLine1}
                            onIonChange={(e) => {
                                setIsDisabled(false);
                                setStreetAddressLine1(e.detail.value!);
                                onChange("streetAddressLine1", e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel
                            position={isWindows() || isPlatform("desktop") ? "floating": "stacked"}
                        >
                            Street Address Line 2
                        </IonLabel>
                        <IonInput
                            id={id ? `${id}-streetadd2` : "streetadd2"}
                            type="text"
                            value={streetAddressLine2}
                            onIonChange={(e) => {
                                setIsDisabled(false);
                                setStreetAddressLine2(e.detail.value!);
                                onChange("streetAddressLine2", e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow className="mb-3">
                <IonCol
                    sizeXs="12"
                    sizeMd="6"
                    className={isWindows() || isPlatform("desktop") ? "" : "mb-3"}
                >
                    <IonItem color="white">
                        <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                            City{" "}
                            {isRequired && (<RequiredInputIndicator />)}
                        </IonLabel>
                        <IonInput
                            id={id ? `${id}-city` : "city"}
                            type="text"
                            value={city}
                            onIonChange={(e) => {
                                setIsDisabled(false);
                                setCity(e.detail.value!);
                                onChange("city", e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectState
                        id={id ? `${id}-provState` : "provState-input"}
                        selectedValue={address?.provState || ""}
                        isRequired={isRequired || false}
                        isTextDarker={isTextDarker || false}
                        onInputChange={handleProvStateInputChange}
                    />
                </IonCol>
            </IonRow>
            <IonRow className="mb-3">
                <IonCol sizeXs="12" sizeMd="6" className={isWindows() || isPlatform("desktop") ? "" : "mb-3"}>
                    <IonItem color="white">
                        <IonLabel position={isWindows() || isPlatform("desktop") ? "floating" : "stacked"}>
                            Postal Code{" "}
                            {isRequired && (<RequiredInputIndicator />)}
                        </IonLabel>
                        <IonInput
                            id={id ? `${id}-postalcode` : "postalcode"}
                            type="text"
                            value={zip}
                            onIonChange={(e) => {
                                setIsDisabled(false);
                                setZip(e.detail.value!);
                                onChange("zip", e.detail.value!);
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectCountry
                        id={id ? `${id}-country` : "country-input"}
                        countryValue={address?.country || ""}
                        isRequired={isRequired || false}
                        isTextDarker={isTextDarker || false}
                        onInputChange={handleCountryInputChange}
                    />
                </IonCol>
            </IonRow>
        </>
    );
};

export default AddressFormGroup;