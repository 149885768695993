import { IonCol, IonRow, IonSearchbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ErrorAlert from "../Errors/ErrorAlert";
import { Event } from "../../models";
import { getAllEvents } from "../../utilities/events/Event";
import { sortEventsByDate } from "../../utilities/events/SortEvents";
import { formatStartEndDatesStrings } from "../../utilities/dates/FormatDates";
import { Table } from "reactstrap";
import { useHistory } from "react-router";

var moment = require("moment");

const EventsListEditor: React.FC = () => {

    const history = useHistory();

    const [events, setEvents] = useState<Event[] | null | undefined>();
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const [searchText, setSearchText] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEvents() {
            const eventsResult = await getAllEvents();
            console.log("Event Result: ", eventsResult);
            if (eventsResult.isSuccess) {
                const allEvents: Event[] = eventsResult.result;
                const sorted = sortEventsByDate(allEvents);
                setEvents(sorted || allEvents);
                setFilteredEvents(sorted || allEvents);
                setError("");
            } else {
                setError("Could not get the events from the database.");
            }
        }

        getEvents();
    }, []);

    const filterEvents = (value: string) => {
        if (events) {
            const result = events.filter(event => event.name.toLocaleLowerCase().toString().includes(value.toLocaleLowerCase().toString()));
            setFilteredEvents(result);
        }
    }

    const handleSearchInput = async (value: string) => {
        setSearchText(value);
        if (value) {
            filterEvents(value);
        } else if (value === "" && events) {
            setFilteredEvents(events);
        } else {
            setFilteredEvents([]);
        }
    }

    const handleRowClick = (event: Event) => {
        const path = "/index/staff/event/" + event.id;
        history.push(path);
    }

    return (
        <>
           {error && <ErrorAlert width="12" error={error} />}
            <IonRow className="ion-justify-content-center">
                <IonCol sizeSm="12" sizeMd="10">
                    <IonSearchbar value={searchText} placeholder="Search by Name" onIonChange={e => handleSearchInput(e.detail.value!)} color="white"></IonSearchbar>
                </IonCol>
            </IonRow>
            {(filteredEvents && filteredEvents.length > 0) ?
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Date(s)</th>
                            <th>Org Name</th>
                            <th>Created On</th>
                            <th>Text Alerts</th>
                            <th>Online Entries</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredEvents.map((e, i) => (
                            <tr key={i} onClick={() => handleRowClick(e)}>
                                <td className="ion-text-wrap">{e.name}</td>
                                <td className="ion-text-wrap">{e.status}</td>
                                <td className="ion-text-wrap">{e.startDate ? formatStartEndDatesStrings(e.startDate, e.endDate) : ""}</td>
                                <td className="ion-text-wrap">{e.organization?.nickname || e.organization?.name}</td>
                                <td className="ion-text-wrap">{moment(e.createdOn).format("MM/DD/YY h:mm a")}</td>
                                <td className={"ion-text-wrap" + (e.eventOptions?.usesTextAlerts ? " text-success": "")}>{e.eventOptions?.usesTextAlerts ? "Yes" : "No"}</td>
                                <td className={"ion-text-wrap" + (e.eventOptions?.usesEventManagement ? " text-success": "")}>{e.eventOptions?.usesEventManagement ? "Yes" : "No"}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                :
                <p>No events found</p>
            }
        </>
    );
};

export default EventsListEditor;
