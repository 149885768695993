import {
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Event, EventJudge } from "../../../models";
import { getEventJudgesByEventId } from "../../../utilities/eventJudge/EventJudge";
import { getHorseShowByEventId } from "../../../utilities/horseShow/HorseShow";

interface _Props {
    event: Event
}

const EventDisplayJudges: React.FC<_Props> = ({event}) => {

    const [eventJudges, setEventJudges] = useState<(EventJudge | null)[] | null | undefined>();

    useEffect(() => {
        async function getHorseShowJudges(eventId: string) {
            const queryResult = await getEventJudgesByEventId(eventId);
            if (queryResult.isSuccess) {
                setEventJudges(queryResult.result);
            }
        }
        if (event) getHorseShowJudges(event.id);
    }, [event]);

    return (
        <>
            {eventJudges && eventJudges.length ?
                <IonRow>
                    <IonCol>
                        <IonList className="bg-white">
                            {eventJudges.map((judge, index) => (
                                <IonItem key={index}>
                                    <IonLabel><h3 className="ion-text-wrap text-darker">{judge && judge.name}</h3></IonLabel>
                                </IonItem>
                            ))}
                        </IonList>
                    </IonCol>
                </IonRow>
                :
                <>
                    <IonRow>
                        <IonCol>
                            <IonItem>
                                <IonLabel><h3 className="ion-text-wrap text-darker">No judges listed.</h3></IonLabel>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default EventDisplayJudges;