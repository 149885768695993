import moment from "moment";
import { Event, EventEntry, Rider } from "../../models";
import { getEventClassesByEventId } from "../eventClass/EventClass";
import { sortEventClassesByNumber } from "../eventClass/SortEventClass";
import { getAcceptedEventClassEntriesByEventClassId } from "../eventClassEntry/EventClassEntry";
import { sortEventClassEntriesByHorse, sortEventClassEntriesByNumber } from "../eventClassEntry/SortEventClassEntries";
import { getEventEntryById } from "../eventEntry/EventEntry";

const { jsPDF } = require("jspdf");
require('jspdf-autotable');

export async function generateQuickClassSheets(event: Event, isSortedByNumber?: boolean) {
    // initialize jsPDF
    const doc = new jsPDF();

    // page title. and margin-top + margin-left
    doc.text(`${event.name}`, 14, 15);
    doc.setFontSize(12);
    doc.text(`Quick Class Sheets Report`, 14, 20);
    doc.text(`Time generated: ${moment().format("dddd MMM DD, YYYY hh:mm a")}`, 14, 25);

    let yValue = 25;

    const eventClassesResult = await getEventClassesByEventId(event.id);
    if (eventClassesResult.isSuccess) {
        const eventClasses = sortEventClassesByNumber(eventClassesResult.result);
        if (eventClasses && eventClasses.length > 0) {
            for (let i = 0; i < eventClasses.length; i++) {
                yValue = yValue + 15;
                const eventClass = eventClasses[i];
                doc.setFontSize(12);
                doc.text(`${eventClass.number}) ${eventClass.name} `, 14, yValue);

                yValue = yValue + 5;
                doc.setFontSize(10);
                const entriesResult = await getAcceptedEventClassEntriesByEventClassId(eventClass.id);
                if (entriesResult.isSuccess && entriesResult.result && entriesResult.result.length > 0) {
                    doc.text(`Entries Count: ${entriesResult.result.length}`, 14, yValue);

                    let eventClassEntries = entriesResult.result;
                    const firstSort = sortEventClassEntriesByHorse(eventClassEntries); //Sort first by horse in case back numbers aren't in yet
                    const sorted = sortEventClassEntriesByNumber(firstSort || eventClassEntries);
                    if (sorted) eventClassEntries = sorted;

                    // define the columns we want and their titles
                    const tableColumn = [
                        {header: "Count", dataKey: "count"}, 
                        {header: "Back #", dataKey: "number"}, 
                        {header: "Horse", dataKey: "horse"}, 
                        {header: "Rider", dataKey: "rider"},
                        {header: "Trainer", dataKey: "trainer"}
                    ];
                    // define an empty array of rows
                    const tableRows = [];

                    let yOffset = 0;

                    if (eventClassEntries && eventClassEntries.length) {
                        for (let j = 0; j < eventClassEntries.length; j++) {
                            yOffset = yOffset + 10;
                            const eventClassEntry = eventClassEntries[j];
                            if (eventClassEntry?.eventEntryId) {
                                const queryResult = await getEventEntryById(eventClassEntry?.eventEntryId);
                                if (queryResult.isSuccess) {
                                    const entry: EventEntry = queryResult.result;
                                    if (entry) {
                                        const entryNo = entry.number || "";
                                        const horseName = entry.horseName;
                                        const rider: (Rider | null | undefined) = eventClassEntry.rider;
                                        let riderName = rider?.name || "unknown";
                                        const trainerName = entry.trainerName;
                                        
                                        const currentData = [
                                            j+1,
                                            entryNo,
                                            horseName,
                                            riderName,
                                            trainerName
                                        ];
                                        tableRows.push(currentData);
                                    } else {
                                        const currentData = [
                                            j+1,
                                            eventClassEntry.id,
                                            "none",
                                            "none"
                                        ];
                                        tableRows.push(currentData);
                                    }
                                } else {
                                    const currentData = [
                                        j+1,
                                        eventClassEntry.id,
                                        "none",
                                        "none"
                                    ];
                                    tableRows.push(currentData);
                                }
                            } else {
                                const currentData = [
                                    j+1,
                                    eventClassEntry.id,
                                    "none",
                                    "none"
                                ];
                                tableRows.push(currentData);
                            }
                        }
                    }

                    yValue = yValue + 5;
                    // startY is basically margin-top
                    doc.autoTable(tableColumn, tableRows, { 
                        theme: "grid",
                        headStyles: {fillColor: "#73a4d3"},
                        startY: yValue 
                    });

                    yValue = yValue + yOffset;

                    if (yValue > doc.internal.pageSize.height) {
                        yValue = 0;
                        doc.addPage();
                    }

                } else {
                    doc.text(`Entries Count: 0`, 14, yValue);
                }

            }
        }
    }

    // page footer
    doc.setFontSize(10);
    doc.text("Created using RingSide Pro: www.ringsidepro.com", 14, doc.internal.pageSize.height - 10);

    // we define the name of our PDF file.
    doc.save(`Quick_Class_Sheets.pdf`);
}