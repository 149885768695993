import {
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useState, useEffect } from "react";
import BasicEventOrganizationInfo from "../../../../components/EventOrganization/BasicEventOrganizationInfo";
import { Organization } from "../../../../models";
import PageList from "../../../../components/Lists/PageList";
import Header from "../../../../components/Headers/Header";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import SelectOrganization from "../../../../components/Organization/SelectOrganization";
import Spinner from "../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../context/PersonContext";
import { organizationListOptions } from "../../../../interfaces/Page";
import { useHistory } from "react-router";
import { getAllOrganizations } from "../../../../utilities/organization/Organization";
import NewEventOrganizationPage from "../eventStaff/eventOrganization/NewEventOrganizationPage";
import { formatRolesForDisplay } from "../../../../utilities/roles/FormatRoles";

const AdminOrganizationManagementPage: React.FC = () => {
    const history = useHistory();
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [userOrganizations, setUserOrganizations] = useState<Organization[] | null>(null);

    async function getOrganizations() {
        const queryResult = await getAllOrganizations();
        if (queryResult.isSuccess) {
            setUserOrganizations(queryResult.result);
            setOrganization(queryResult.result[0]); //To Do: make alphabetical or chronological?
        }
        setIsLoading(false);
    }

    useEffect(() => {
        // Check user roles and only display Orgs if user is an admin
        if (user.roles) {
            const rolesArray = formatRolesForDisplay(user.roles);
            if (rolesArray) {
                rolesArray.forEach(role => {
                    if (role.value === "admin") getOrganizations();
                });
            }
        }
    }, [user, user.id]);

    useEffect(() => {
        if (!organization) getOrganizations();
    }, []);

    useEffect(() => {
        if (!organization) getOrganizations();
    }, [history.location]);

    const handleOrganizationSelection = (selectedOrganization: Organization) => {
        setOrganization(selectedOrganization);
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Organizations" />
                {organization && userOrganizations ?
                    <>
                        <IonRow>
                            <IonCol sizeXs="9" sizeMd="4">
                                <SelectOrganization canClear={false} currentOrganization={organization} organizations={userOrganizations} onInputChange={handleOrganizationSelection} />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <BasicEventOrganizationInfo organization={organization} />
                            </IonCol>
                        </IonRow>
                        <IonRow className="ion-text-center ion-justify-content-center">
                            <IonCol sizeSm="12" sizeMd="8">
                                <PageList id={organization.id} includeId={true} listOptions={organizationListOptions} />
                            </IonCol>
                        </IonRow>
                    </>
                    :
                    <>
                        {isLoading ?
                            <Spinner />
                            :
                            <NewEventOrganizationPage />
                        }
                    </>
                }
            </IonContent>
        </IonPage>
    );
};

export default AdminOrganizationManagementPage;