import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonLabel,
    IonModal,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import React, {useState} from "react";

const EventClassExplanationModal: React.FC = () => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <IonLabel>
            <IonButton 
                id="organizationClassExplanationBtn"
                onClick={() => setShowModal(true)}
                fill="clear"
                className="ion-no-padding"
            >
                <IonText className="font-weight-normal text-primary text-capitalize initialism ion-text-wrap ion-text-start">
                    Learn More
                </IonText>
            </IonButton>
            <IonModal backdropDismiss={false} isOpen={showModal} id="organizationClassExplanationModal">
                <IonHeader>
                    <IonToolbar color="light">
                        <IonTitle className="ion-text-center">
                            Event Classes
                        </IonTitle>
                        <IonButtons slot="end">
                            <IonButton
                                fill="clear"
                                onClick={() => setShowModal(false)}
                            >
                                <p id="closeOrganizationClassExplanationModalBtn" className="font-weight-normal text-medium text-capitalize">
                                    X
                                </p>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <IonText>Add the classes to your event. These will be the classes people can enter.</IonText>
                    <hr/>
                    <IonText>After you add classes, you can add them to your event's schedule.</IonText>
                </IonContent>
                <IonButton id="CloseOrganizationClassExplanationModalBtn" onClick={() => handleClose()}>
                    Close
                </IonButton>
            </IonModal>
        </IonLabel>
    );
};

export default EventClassExplanationModal;
