import { Block } from "../../models";

export function getCreditCountFromBlocks(blocks: Block[]) {
    let count = 0;
    if (blocks && blocks.length) {
        blocks.forEach(block => {
            let remainingCredits = 0;
            if (block.totalCredits) {
                remainingCredits = block.totalCredits;
                if (block.usedCredits) {
                    remainingCredits = block.totalCredits - block.usedCredits;
                }
            }
            count = count + remainingCredits;
        });
    }
    return count;
}