import React, { useEffect, useState } from "react";
import { IonItem, IonLabel, IonRow, IonCol, IonInput, IonText, IonButton } from "@ionic/react";
import BasicTooltip from "../../Tooltip/BasicTooltip";

interface _Props {
    selectedEntryFee?: number
    onSelect: Function
}

const ClassEntryFee: React.FC<_Props> = ({selectedEntryFee, onSelect}) => {
    const [isHidden, setIsHidden] = useState(true);
    const [entryFee, setEntryFee] = useState(0);

    useEffect(() => {
        if (selectedEntryFee) setEntryFee(selectedEntryFee);
        else setEntryFee(0);
    }, [selectedEntryFee]);

    const handleInput = (input: number) => {
        setEntryFee(input);
        onSelect("class", input);
    }
    
    return (
        <>
            {isHidden ?
                <IonText>
                    Entry Fee: ${entryFee.toFixed(2)} per class <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(false)}>Edit</IonButton>
                </IonText>
                :
                <>
                    <IonRow>
                        <IonCol className="text-center">
                            <BasicTooltip 
                                label="Class Entry Fee"
                                tip="Set the entry fee each exhibitor will be charged if they enter this singular class."
                            />
                            <IonButton fill="clear" color="primary" className="ion-margin-bottom" onClick={() => setIsHidden(true)}>Hide</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem color="white">
                                <IonLabel position="stacked">Class Entry Fee</IonLabel>
                                <IonInput 
                                    type="number"
                                    value={entryFee}
                                    placeholder="$0.00"
                                    onIonChange={e => {
                                        handleInput(parseFloat(e.detail.value!))
                                    }}
                                />
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </>
            }
        </>
    );
};

export default ClassEntryFee;
