import { Clinician } from "../../models";
import React, { useEffect, useState } from "react";
import { getAllActiveClinicians } from "../../utilities/clinician/Clinician";
import { IonItem, IonList, IonLabel, IonRow, IonCol, IonIcon } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";
import { getClinicPrice } from "../../utilities/clinics/calculatePrice";
import { Col, Row } from "reactstrap";
import { getFormattedDisciplineString } from "../../utilities/discipline/FormatDisciplines";

interface formattedOption {
    label: string
    value: string
    clinician: Clinician
    isExpanded: boolean
    isSelected: boolean
}

const DisplayClinicianTable: React.FC = () => {

    const [clinicianOptions, setClinicianOptions] = useState<formattedOption[] | null | undefined>();

    const formatClinicians = (clinicians: Clinician[]) => {
        let formattedClinicians = [];
        for (var i = 0; i < clinicians.length; i++) {
            let object = {
                value: clinicians[i].id,
                label: clinicians[i].name,
                clinician: clinicians[i],
                isExpanded: false,
                isSelected: false
            };
            formattedClinicians.push(object);
        }
        setClinicianOptions(formattedClinicians);
    }

    const getClinicians = async () => {
        const queryResult = await getAllActiveClinicians();
        if (queryResult.isSuccess) {
            formatClinicians(queryResult.result);
        }
    }

    useEffect(() => {
        getClinicians();
    }, []);

    const handleExpand = (selectedClinicianOption: formattedOption, index: number, isExpand: boolean) => {
        const newClinicianOption: formattedOption = {
            ...selectedClinicianOption,
            isExpanded: isExpand
        };
        if (clinicianOptions && clinicianOptions.length > 0) {
            const newClinicianOptions = [
                ...clinicianOptions?.slice(0, index),
                newClinicianOption,
                ...clinicianOptions?.slice(index + 1)
            ];
            setClinicianOptions(newClinicianOptions);
        } else {
            setClinicianOptions([newClinicianOption]);
        }
    }

    return (
        <>
            {clinicianOptions ? 
                <IonList className="bg-white">
                    {clinicianOptions.map((clinician, index) => (
                        <IonItem key={index}>
                            <IonLabel>
                                <>
                                    <Row>
                                        <Col xs="9" sm="11">
                                            <Row>
                                                <Col xs={{size: 12, order: 1}} sm={{size: 4, order: 1}}>
                                                    <h2 className="ion-wrap-text" onClick={() => handleExpand(clinician, index, !clinician.isExpanded)}>{clinician.label}</h2>
                                                </Col>
                                                <Col xs={{size: 12, order: 2}} sm={{size: 4, order: 2}}>
                                                    <h2 className="ion-wrap-text" onClick={() => handleExpand(clinician, index, !clinician.isExpanded)}>${getClinicPrice(clinician.clinician.price).toFixed(2)}</h2>
                                                </Col>
                                                <Col xs={{size: 12, order: 3}} sm={{size: 12, order: 4}}>
                                                    <h3 className="ion-text-wrap" onClick={() => handleExpand(clinician, index, !clinician.isExpanded)}>{getFormattedDisciplineString(clinician.clinician.disciplines || "")}</h3>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs="3" sm="1" className="text-right">
                                            {clinician.isExpanded ? <IonIcon icon={chevronUp} ariaLabel="Collapse" onClick={() => handleExpand(clinician, index, false)} /> : <IonIcon icon={chevronDown} ariaLabel="Expand" onClick={() => handleExpand(clinician, index, true)} />}
                                        </Col>
                                    </Row>
                                    {clinician.isExpanded && (
                                        <>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2>Experience: </h2>
                                                    <p className="ion-text-wrap">{clinician.clinician.experience}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2 className="ion-text-wrap">Ratings: {clinician.clinician.rating}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2 className="ion-text-wrap">IHSA Experience: {clinician.clinician.hasIHSAExperience ? "Yes" : "No"}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2 className="ion-text-wrap">IEA Experience: {clinician.clinician.hasIEAExperience ? "Yes" : "No"}</h2>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2>Personal: </h2>
                                                    <p className="ion-text-wrap">{clinician.clinician.personal}</p>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <h2>Preferences: </h2>
                                                    <p className="ion-text-wrap">{clinician.clinician.preferences}</p>
                                                </IonCol>
                                            </IonRow>
                                        </>
                                    )}
                                </>
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
                :
                <p>Loading...</p>
            }
        </>
    );
};

export default DisplayClinicianTable;