import { 
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonIcon,
    IonItem,
    IonModal,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import { Organization } from "../../models";
import { useHistory } from "react-router";
import ErrorAlert from "../Errors/ErrorAlert";
import Spinner from "../Spinners/Spinner";
import { deleteEntireOrganization } from "../../utilities/organization/DeleteOrganization";

interface _Props {
    organization: Organization;
}


const DeleteEventOrganization: React.FC<_Props> = ({organization}) => {
    const history = useHistory();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");


    const handleDeleteOrganization = async () => {
        setError("");
        setIsLoading(true);
        if (organization) {
            const deleteOrganizationResult = await deleteEntireOrganization(organization);
            if (deleteOrganizationResult.isSuccess) {
                const location = {
                    pathname : "/index/staff/organization"
                }
                history.push(location);
            } else {
                setError(deleteOrganizationResult.message);
            }
        } else {
            setError("No organization found.")
        }
        setShowModal(false);
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <IonModal
                backdropDismiss={false}
                isOpen={showModal}
                id="deletemodel">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Delete Organization
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            id="delete-org-modal-close-icon"
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p
                                id="closeAddressModalBtn"
                                className="font-weight-normal text-medium text-capitalize"
                            >
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="12">
                            <h3>Are you sure you want to permanently delete this organization: </h3>
                        </IonCol>
                    </IonRow>
                    <IonRow className="ion-justify-content-center">
                        <IonCol size="10" className="ion-text-center">
                            <h2>{organization?.name}</h2>
                        </IonCol>
                    </IonRow>
                </IonContent>
                { isLoading ?
                    <Spinner/> 
                    :
                    <IonRow className="ion-justify-content-center ion-padding-top">
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-org-modal-cancel-btn"
                                expand="block"
                                color="light"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </IonButton>
                        </IonCol>
                        <IonCol size="6" className="ion-text-center">
                            <IonButton
                                id="delete-org-modal-delete-btn"
                                expand="block" 
                                color="danger" 
                                onClick={handleDeleteOrganization}
                            >
                                Delete
                            </IonButton>
                        </IonCol>
                    </IonRow>
                }
            </IonModal>
            <IonRow className="ion-justify-content-center">
                {error && <ErrorAlert width="12" error={error} />}
                <IonCol size="12">
                    <IonCard mode="md" className="ion-padding bg-white">
                        <IonCardTitle>{organization?.name}</IonCardTitle>
                        <IonCardContent className="ion-padding">
                            <IonItem lines="none">
                                <IonText>
                                    Deleting this organization will permanently
                                    remove it as well as all of its data. You
                                    cannot undo this action.
                                </IonText>
                            </IonItem>
                            <IonItem lines="none">
                                <IonText>This action will remove:</IonText>
                            </IonItem>
                            <IonItem lines="none">
                                    <ul>
                                        <li>The organization</li>
                                        <li>The organization's branding information</li>
                                        <li>The organization's addresses</li>
                                        <li>The organization's contact information</li>
                                        <li>The organization's settings</li>
                                    </ul>
                            </IonItem>
                        </IonCardContent>
                        <IonCardContent>
                            <IonItem lines="none">
                                <IonText>
                                    Would you like to remove this organization?
                                </IonText>
                            </IonItem>
                            <IonRow className="ion-justify-content-center">
                                <IonButton
                                    id="delete-org-modal-open-btn"
                                    color="danger"
                                    onClick={() => setShowModal(true)}
                                >
                                    Delete Organization
                                </IonButton>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
        </React.Fragment>
    );
};

export default DeleteEventOrganization;