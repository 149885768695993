import React from "react";

export default <div>
    <div>
        Your use of RingSide Pro’s software as a service product is subject to the terms set forth below (this “Agreement”). “RingSide Pro” means RingSide Pro LLC, together with its affiliated entities, specifically “Clinics Pro.” In order to use the Products, you must first agree to this Agreement. Any new features or tools which are added to the current Products shall be also subject to the Terms of Service. RingSide Pro reserves the right to update and change the Terms of Service by posting updates and changes to the RingSide Pro website. You represent and warrant that you have the necessary and full right, power, authority, and capability to accept this Agreement, to bind your organization, and to perform your obligations hereunder. You can accept this Agreement by: (i) clicking to accept or agree to this Agreement, where this option is made available to you by RingSide Pro online or in the user interface for any Product; (ii) where a link to this Agreement appears in an order form or other document provided to you by RingSide Pro; (iii) by signing this Agreement, if there is a designated area to sign; or (iv) by actually using the Products. In the case of (iv), you understand and agree that RingSide Pro will treat your use of the Products as acceptance of this Agreement from that point onwards. You may not use the Products and may not accept this Agreement if (a) you are not of legal age to form a binding contract with RingSide Pro, or (b) you are a person barred from receiving the Products under the laws of the United States or other countries including the country in which you are resident or from which you use the Products. You may not use the Products if you do not accept this Agreement. “Effective Date” means the date that you accept this Agreement. By accepting this Agreement, you agree as follows:
        <ol>
            <li>
                FEES
                <ul>
                    The time period that you are permitted to use the Products, the features, services, options, and the fees associated with your use may be described more fully on web pages describing the Products, and/or in an applicable schedule, pricing form, order form, or similar document (each, a “Schedule”). Each Schedule will be governed by this Agreement and incorporated herein by this reference. You will pay the fees as more fully described in the applicable Schedule.
                </ul>
            </li>
            <li>
                LICENSES
                <ul>
                    Scope of Licenses. Subject to the terms and conditions set forth herein and in the applicable Schedule, RingSide Pro grants to you a limited, non-exclusive, non-transferable license to use the Products solely in accordance with this Agreement, the Schedule, and the related written user documentation, if any. You hereby grant to RingSide Pro a limited license to use information provided by you relating to your organization and events, which may include content regarding the events, your organization’s name, trademarks, service marks, and logo, solely in connection with the promotion of your organization or events and the Products that we provide.
                </ul>
                <ul>
                    The following are the licenses associated with RingSide Pro: 
                    <ol>
                        <li>
                            Clinician License: This license is to an individual. This license type may only be used for administrative and performance tasks associated with the online clinics this clinician is performing. The software licensed to this individual may not be shared with any other individual.
                        </li>
                        <li>
                            Participant License: This license is to an individual. This license may only be used by the individual it is licensed to. 
                            <ol>
                                <li>
                                    The following additional terms are applicable to your use of SaaS Products only (for example, the website): (i) RingSide Pro will provide you access to the SaaS which you may use to deliver content and data to end users. You acknowledge and agree that end users may be required to purchase access to content and data delivered by you through the SaaS in accordance with the applicable Schedule. You may have the right to receive revenue sharing from such purchases in accordance to the extent set forth in the applicable Schedule. (ii) By submitting, delivering and/or uploading content and data through the SaaS, you agree you have the right to edit, copy, publish, distribute and otherwise use the content and data made available by you. You further agree that you will have dedicated resources and capability to use the SaaS for its intended purposes and that you will use commercially reasonable efforts to publish applicable content and data to end users as quickly as reasonably practicable. Content and data includes but is not limited to the following: show schedule, class entry counts, order of go sheets, barn and stall assignments, class and entry results and class prize money.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </ul>
            </li>
            <li>
                RESTRICTIONS
                <div>
                    You shall: (i) use the Products exclusively for authorized and legal purposes, consistent with all applicable laws, regulations, and the rights of others, including privacy and anti-spamming laws; (ii) not reverse engineer, disassemble, or decompile any Product or prepare derivative works thereof; (iii) not copy, modify, transfer, display, or use any portion of the Products except as expressly authorized in this Agreement or in the applicable documentation; (iv) not contest or do or aid others in contesting or doing anything which impairs the validity of any proprietary or intellectual property rights, title, or interest of RingSide Pro in and to the Products; (v) not obliterate, alter, or remove any proprietary or intellectual property notices from the Products in physical or electronic forms; (vi) not use the Products to transmit, publish, or distribute any material or information: (a) for which you do not have all necessary rights and licenses, including any material or information that infringes, violates, or misappropriates the intellectual property rights of any third party; (b) that contains a computer virus or other code, files, or programs designed to disrupt or interfere with the functioning of the Products; or (c) that is or that may reasonably be perceived as being harmful, threatening, offensive, obscene, or otherwise objectionable; and (vii) not attempt to gain access to any systems or networks that connect thereto except for the express purpose of using the Products for their intended use.
                </div>
            </li>
            <li>
                OWNERSHIP
                <div>
                    RingSide Pro retains all right, title and interest in and to the Products including, but not limited to, all copyrights, trademarks, trade secrets, trade names, proprietary rights, patents, titles, computer codes, improvements, enhancements, modifications and derivative works thereof. All rights not expressly granted to you herein are reserved by RingSide Pro.
                </div>
            </li>
            <li>
                INFORMATION COLLECTION
                <div>
                    RingSide Pro’s privacy policy as amended from time to time is is applicable to this Agreement. RingSide Pro’s Privacy Policy defines how, why and to which extent RingSide Pro collects and uses personal and non-personal information in relation to RingSide Pro's products and services. By installing, accessing or using the Products, you expressly agree with the terms and conditions of RingSide Pro’s Privacy Policy and to any terms and conditions included therein by reference.
                </div>
            </li>
            <li>
                DISCLAIMER OF WARRANTY/LIMITATION OF LIABILITY.
                <div>
                    RINGSIDE PRO EXPRESSLY DISCLAIMS ANY WARRANTY THAT THE USE OF THE PRODUCTS WILL BE UNINTERRUPTED OR ERROR FREE OR THAT THE PRODUCTS WILL MEET YOUR REQUIREMENTS. THE PRODUCTS ARE PROVIDED TO YOU ON AN “AS-IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. RINGSIDE PRO SHALL NOT BE LIABLE FOR INDIRECT DAMAGES OR LOSSES (IN CONTRACT, TORT, OR OTHERWISE), INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, LOST SAVINGS, COST OF REPLACEMENT SERVICES, LOST DATA, LOSS OF USE OF INFORMATION OR SERVICES, OR ANY INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE, OR SPECIAL DAMAGES. RINGSIDE PRO’S TOTAL AGGREGATE LIABILITY FOR ALL MATTERS ARISING FROM OR RELATED TO THIS AGREEMENT IS LIMITED TO THE AMOUNT OF FEES ACTUALLY PAID BY YOU AS CONSIDERATION FOR THE PRODUCTS GIVING RISE TO SUCH CLAIMS DURING THE TWELVE (12) MONTH PERIOD PRECEDING THE DATE ON WHICH THE FIRST CAUSE OF ACTION AROSE.
                </div>
            </li>
            <li>
                INDEMNITY
                <div>
                    You agree to indemnify, defend and hold Active, its partners, affiliates, contractors, officers, directors, employees and agents harmless from and against any and all damages, losses and expenses arising directly or indirectly from: (i) your violation of any applicable law, rule, or regulation; and/or (ii) your breach of this Agreement.
                </div>
            </li>
            <li>
                FEEDBACK AND REVIEWS
                <div>
                    RingSide Pro welcomes any ideas and/or suggestions regarding improvements or additions to the Services. Under no circumstances shall any disclosure of any idea, suggestion or related material or any review of the Services, Products or any Third Party Provider (collectively, “Feedback") to RingSide Pro be subject to any obligation of confidentiality or expectation of compensation. By submitting Feedback to RingSide Pro (whether submitted directly to RingSide Pro or posted on any RingSide Pro hosted forum or page), you waive any and all rights in the Feedback and that RingSide Pro is free to implement and use the Feedback if desired, as provided by you or as modified by RingSide Pro, without obtaining permission or license from you or from any third party. Any reviews of a Third Party Service or Third Party Provider that you submit to RingSide Pro must be accurate to the best of your knowledge, and must not be illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable. RingSide Pro reserves the right (but not the obligation) to remove or edit Feedback of Third Party Services or Third Party Providers, but does not regularly inspect posted Feedback.
                </div>
            </li>
            <li>
                MISCELLANEOUS
                <ul>
                    <li>
                        RingSide Pro may assign any of its rights or obligations under this Agreement. You may not resell, assign, or transfer any of your rights or obligations hereunder, and any attempt to resell, assign, or transfer such rights or obligations without RingSide Pro’s prior written approval will be null and void.
                    </li>
                    <li>
                        Any notices required to be given under this Agreement shall be in writing sent to the address set forth below for Client or, in the case of Active, to the address set forth above to the attention of Chief Legal Officer. Notices will be deemed received the next day if sent via overnight mail or courier with confirmation of receipt, or three (3) days after deposited in the mail sent certified or registered.
                    </li>
                    <li>
                        This Agreement shall be governed by the laws of the State of Delaware, without giving effect to the conflict of laws provisions thereof. Neither the United Nations Convention of Contracts for the International Sale of Goods nor the Uniform Computer Information Transactions Act shall apply to this Agreement. The parties irrevocably agree that any legal action or proceeding relating to this Agreement shall be instituted only in any state or federal court in Virginia.
                    </li>
                    <li>
                        This Agreement contains the entire understanding of the parties regarding the subject matter hereof and can only be modified or amended by a subsequent written agreement executed by both parties.
                    </li>
                    <li>
                        Sections 1, 4, 5, 6 and 7 of this Agreement and any fees owed by you shall survive any termination or expiration of this Agreement.
                    </li>
                    <li>
                        If any provision of this Agreement is held to be unenforceable by a court of competent jurisdiction for any reason whatsoever, (i) the validity, legality, and enforceability of the remaining provisions of this Agreement (including without limitation, all portions of any provisions containing any such unenforceable provision that are not themselves unenforceable) shall not in any way be affected or impaired thereby, and (ii) to the fullest extent possible, the unenforceable provision shall be deemed modified and replaced by a provision that approximates the intent and economic effect of the unenforceable provision and the Agreement shall be deemed amended accordingly.
                    </li>
                    <li>
                        No waiver of any provision of this Agreement or any attachment shall be effective unless it is in writing and signed by the party against which it is sought to be enforced.
                    </li>
                    <li>
                        Neither party will be deemed to be in default hereunder, or will be liable to the other, for delay or failure to perform any of its obligations under this Agreement to the extent that such delay or failure results from any event or circumstance beyond that party’s reasonable control, including without limitation, delays or failures of any Internet service provider, third-party payment processor or other third party.
                    </li>
                    <li>
                        Client has not received or been offered any illegal or improper bribe, kickback, payment, gift, or thing of value from an Active employee or agent in connection with this Agreement.
                    </li>
                    <li>
                        The Products are provided with restricted rights. Use, duplication, or disclosure by the U.S. Government is subject to restrictions as set forth in subparagraph (c) of The Rights in Technical Data and Computer Software clause at DFARS 252.227-7013, or subparagraphs (b)(1) and (2) of the Commercial Computer Software - Restricted Rights at 48 CFR 52.227-19, as applicable. The Manufacturer is Active Network, LLC or one of its affiliates or subsidiaries.
                    </li>
                </ul>
            </li>
        </ol>
    </div>
</div>