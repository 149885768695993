import {
    IonButton,
    IonCol,
    IonInput,
    IonItem,
    IonLabel,
    IonRow
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import Spinner from "../Spinners/Spinner";
import { PersonContext } from "../../context/PersonContext";
import { updateOrganization } from "../../utilities/organization/Organization";
import { Contact, Organization } from "../../models";
import { CreateContactInput, OrganizationType, UpdateContactInput, UpdateOrganizationInput } from "../../API";
import SelectOrganizationIndustry from "../Organization/SelectOrganizationIndustry";
import SelectOrganizationType from "../Organization/SelectOrganizationType";
import ErrorAlert from "../Errors/ErrorAlert";
import { formatURL } from "../../utilities/contact/FormatURL";
import { createContact, getContactById, updateContact } from "../../utilities/contact/Contact";
import { Input } from "reactstrap";
import SuccessBanner from "../Banners/SuccessBanner";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    organization: Organization
}

const UpdateEventOrganizationForm: React.FC<_Props> = ({organization}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState<string>(organization.name || "");
    const [nickname, setNickname] = useState<string>(organization.nickname || "");
    const [description, setDescription] = useState(organization.description || "");
    const [email, setEmail] = useState(organization.contact?.workEmail || "");
    const [website, setWebsite] = useState(organization.contact?.website || "");
    const [type, setType] = useState(organization.type || "");
    const [industry, setIndustry] = useState(organization.industry || "");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState<string>("");

    const getOrganizationData = async (organization: Organization) => {
        if (organization) {
            if (organization.name) setName(organization.name);
            if (organization.nickname) setNickname(organization.nickname);
            if (organization.description) setDescription(organization.description);
            if (organization.type) {
                if (organization.type === "COMPANY") setType(OrganizationType.company);
                if (organization.type === "INDIVIDUAL") setType(OrganizationType.individual);
                if (organization.type === "NONPROFIT") setType(OrganizationType.nonprofit);
            }
            if (organization.contactId) {
                const queryResult = await getContactById(organization.contactId);
                if (queryResult.isSuccess) {
                    const contact: Contact = queryResult.result;
                    if (contact.workEmail) setEmail(contact.workEmail);
                    if (contact.website) setWebsite(contact.website);
                }
            } else {
                if (organization.contact?.workEmail) setEmail(organization.contact?.workEmail);
                if (organization.website) setWebsite(organization.website);
                else if (organization.contact?.website) setWebsite(organization.contact?.website);
            }
        }
    }

    useEffect(() => {
        if (organization) getOrganizationData(organization);
    }, [organization]);

    const verifyIsValid = () => {
        if (!name) {
            setError("You must include a name for the organization.");
            return false;
        }
        if (!email) {
            setError("You must include a email for the organization.");
            return false;
        }
        if (!type) {
            setError("You must include a type for the organization.");
            return false;
        }
        if (!industry) {
            setError("You must include an industry for the organization.");
            return false;
        }
        return true;
    }

    const handleTypeInputChange = (value: string) => {
        if (value && value !== "-"){
            setType(value);
        } else {
            setType("");
        }
    }

    const handleIndustryInputChange = (value: string) => {
        if (value && value !== "-"){ 
            setIndustry(value);
        } else {
            setIndustry("");
        }
    }

    const handleUpdateOrganization = async () => {
        setError("");
        setSuccess("");
        try {
            let contactid = "";
            let contact = undefined;

            if (organization.contactId) {
                // Update the Contact Object
                const contactInput: UpdateContactInput = {
                    id: organization.contactId,
                    name: name,
                    workEmail: email,
                    website: website ? formatURL(website) : undefined
                };
                const updateContactResult = await updateContact(contactInput);
                if (updateContactResult.isSuccess) {
                    contactid = updateContactResult.result.id;
                    contact = updateContactResult.result;
                } else {
                    setError(updateContactResult.message);
                    return;
                }
            } else {
                // Create the Contact Object
                const contactInput: CreateContactInput = {
                    name: name,
                    workEmail: email,
                    website: website ? formatURL(website) : undefined
                };
                const createContactResult = await createContact(contactInput);
                if (createContactResult.isSuccess) {
                    contactid = createContactResult.result.id;
                    contact = createContactResult.result;
                } else {
                    setError(createContactResult.message);
                    return;
                }
            }

            const organizationType : OrganizationType = type as OrganizationType;

            // Next, update the organization
            const input: UpdateOrganizationInput = {
                id: organization.id,
                createdBy: user.id,
                name: name,
                nickname: nickname,
                type: organizationType,
                industry: industry,
                description: description,
                website: website,
                contactId: contactid
            };
            const updateOrgResult = await updateOrganization(input);
            if (updateOrgResult.isSuccess) {
                setSuccess("Successfully updated the organization.");
            } else {
                setError("Could not Update the organization");
            }
        } catch (error: any) {
            const message = "Could not update the organization: " + error;
            setError(message);
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const isValid = verifyIsValid();
        if (isValid) {
            await handleUpdateOrganization();
        }
        setIsLoading(false);
    }

    return (
        <form>
            {success && <SuccessBanner width="12" success={success}/>}
            {error && <ErrorAlert width="12" error={error} />}
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="floating">Name <RequiredInputIndicator /></IonLabel>
                        <IonInput 
                            id="update-org-name"
                            type="text"
                            value={name}
                            aria-required={true}
                            onIonChange={e => {
                                setName(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="floating">Nickname/Abbreviation</IonLabel>
                        <IonInput 
                            id="update-org-nickname"
                            type="text"
                            value={nickname}
                            aria-required={true}
                            onIonChange={e => {
                                setNickname(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    <IonLabel position="floating" className="text-darker pl-3">Description</IonLabel>
                    <Input
                        id="update-org-description"
                        rows="3"
                        type="textarea"
                        name="description"
                        value={description}
                        spellCheck="true"
                        onChange={e => {
                            setDescription(e.target.value!)
                        }}
                    />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="floating">Email Address <RequiredInputIndicator /></IonLabel>
                        <IonInput
                            id="update-org-email" 
                            type="email"
                            value={email}
                            aria-required={true}
                            onIonChange={e => {
                                setEmail(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <IonItem color="white">
                        <IonLabel position="floating">Website</IonLabel>
                        <IonInput
                            id="update-org-website"
                            type="text"
                            value={website}
                            onIonChange={e => {
                                setWebsite(e.detail.value!)
                            }}
                        />
                    </IonItem>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectOrganizationType isRequired={true} selectedValue={type ? type as OrganizationType : undefined}  onInputChange={handleTypeInputChange} />
                </IonCol>
                <IonCol sizeXs="12" sizeMd="6">
                    <SelectOrganizationIndustry isRequired={true}  selectedValue={industry}  onInputChange={handleIndustryInputChange} />
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol sizeMd="4">
                    {isLoading ?
                        <Spinner />
                        :
                        <IonButton
                            id="update-org-btn"
                            className="ion-margin-top"
                            color="tertiary"
                            expand="block"
                            onClick={handleSubmit}
                        >
                            Save
                        </IonButton>
                    }
                </IonCol>
            </IonRow>
        </form>
    );
};

export default UpdateEventOrganizationForm;