import {
    IonButton,
    IonCard,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonText,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Horse, Membership } from "../../models";
import { deleteMembership, getMembershipsByHorseId } from "../../utilities/membership/Membership";
import ViewMembershipInfoModal from "./ViewMembershipInfoModal";
import { close } from "ionicons/icons";
import ErrorAlert from "../Errors/ErrorAlert";
import * as subscriptions from "../../graphql/subscriptions";
import { useSubscriptionByItself } from "../../utilities/subscription/Subscription";

interface _Props {
    horse: Horse
}

const EditHorseMembershipsTable: React.FC<_Props> = ({horse}) => {

    const createSubscription = useSubscriptionByItself({
        config: {
            query: subscriptions.onCreateMembership,
            key: "onCreateMembership"
        }
    });

    const [showMembershipModal, setShowMembershipModal] = useState(false);
    const [membership, setMembership] = useState<Membership | null | undefined>();
    const [memberships, setMemberships] = useState<Membership[] | null | undefined>();
    const [currentSubscriptionItem, setCurrentSubscriptionItem] = useState<any>();
    const [error, setError] = useState("");

    const getMembershipsByHorse = async (horse: Horse) => {
        const queryResult = await getMembershipsByHorseId(horse.id);
        if (queryResult.isSuccess) {
            setMemberships(queryResult.result);
        }
    }

    useEffect(() => {
        if (createSubscription && createSubscription[0] !== undefined && createSubscription[0] !== currentSubscriptionItem) {
            setCurrentSubscriptionItem(createSubscription[0]);
            getMembershipsByHorse(horse);
        }
    }, [createSubscription]);

    useEffect(() => {
        if (horse) getMembershipsByHorse(horse);
    }, [horse]);

    const onSelectMembership = (membership: Membership) => {
        setMembership(membership);
        setShowMembershipModal(true);
    }

    const handleDelete = async (membership?: Membership) => {
        if (membership) {
            const deleteResult = await deleteMembership({id: membership.id});
            if (deleteResult.isSuccess) {
                let newMemberships: Membership[] = [];
                memberships?.forEach(current => {
                    if (membership.id !== current.id) newMemberships.push(current);
                });
                setMemberships(newMemberships);
            } else {
                setError("Could not delete the membership.");
            }
        } else {
            setError("Could not delete the membership.");
        }
    }

    return (
        <IonCard mode="md" className="ion-padding bg-white">
            <IonCardTitle>
                <IonRow>
                    <IonCol size="12">
                        <h3>Memberships</h3>
                    </IonCol>
                </IonRow>
            </IonCardTitle>
            <IonCardSubtitle>Click on a row to view more.</IonCardSubtitle>
            {error && <ErrorAlert error={error} />}
            <IonList className="bg-white">
                {memberships && memberships.length ?
                    <>
                        {memberships.map((membership, index) => (
                            <IonItem className="ion-text-wrap" key={index}>
                                <IonLabel className="ion-text-wrap" onClick={() => onSelectMembership(membership)}>
                                    <IonText className="ion-text-wrap">{membership.name} {membership.membershipStatus ? (" - " + membership.membershipStatus) : ""} {membership.membershipId ? (" - " + membership.membershipId) : ""}</IonText>
                                </IonLabel>
                                <IonButton slot="end" color="danger" onClick={() => handleDelete(membership as Membership)}><IonIcon icon={close}/></IonButton>
                            </IonItem>
                        ))}
                    </>
                    :
                    <p>No memberships yet.</p>
                }
            </IonList>
            {membership && <ViewMembershipInfoModal membership={membership} show={showMembershipModal} allowOptionToDelete={false} />}
        </IonCard>
    );
};

export default EditHorseMembershipsTable;