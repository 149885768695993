import { IonButton, IonButtons, IonContent, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { getPointTablesByOrganizationId } from "../../utilities/pointTable/PointTable";
import CreatableSelect from 'react-select/creatable';
import ErrorAlert from "../Errors/ErrorAlert";
import InformationBanner from "../Banners/InformationBanner";
import { PointTable } from "../../models";
import PointTableEditForm from "./PointTableEditForm";
import Spinner from "../Spinners/Spinner";
import { close } from "ionicons/icons";

interface _Props {
    organizationId: string
    pointTable?: (PointTable | null)
    onSelect: Function
}

interface formattedOption {
    label: string
    value: string
    pointTable: PointTable
}

const SelectCreatePointTable: React.FC<_Props> = ({organizationId, pointTable, onSelect}) => {

    const [formattedPointTables, setFormattedPointTables] = useState<formattedOption[] | null | undefined>();
    const [selectedOption, setSelectedOption] = useState<formattedOption | null | undefined>();
    const [selectedLabel, setSelectedLabel] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");

    const formatPointTables = async () => {
        const queryResult = await getPointTablesByOrganizationId(organizationId);
        if (queryResult.isSuccess) {
            const pointTables = queryResult.result;
            let formattedPointTables = [];
            for (var i = 0; i < pointTables.length; i++) {
                const tableLabel = pointTables[i].name;
                let object = {
                    label: tableLabel,
                    value: pointTables[i].id,
                    pointTable: pointTables[i]
                };
                formattedPointTables.push(object);
            }
            setFormattedPointTables(formattedPointTables);
            setPointTable(formattedPointTables);
        }
    }

    const setPointTable = (formattedTables?: formattedOption[]) => {
        let tables = formattedTables || formattedPointTables;

        if (pointTable && tables) {
            for (var i = 0; i < tables?.length; i++) {
                const formattedTable = tables[i];

                if (formattedTable.pointTable.id === pointTable.id) {
                    setSelectedOption(formattedTable);
                }
            }
        } else {
            setSelectedOption(undefined);
        }
    }

    useEffect(() => {
        async function getPointTables(organizationId: string) {
            await formatPointTables();
        }

        if (organizationId) getPointTables(organizationId);
    }, [organizationId]);

    useEffect(() => {
        async function getPointTables() {
            await formatPointTables();
        }
        
        if (!formattedPointTables) {
            // Get the tables and then try to set the current table
            getPointTables();
        } else {
            setPointTable();
        }
        
    }, [pointTable]);


    const handleOnChange = async (event: any) => {
        if (event && event.pointTable) {
            // Chose a pre-configured pointTable
            onSelect(event.pointTable);
        } else if (event && event.label) {
            // Create a new pointTable
            setSelectedLabel(event.label);
            setShowModal(true);
        } else {
            setSelectedOption(undefined);
        }
    }

    const handleCreatedPointTable = async (pointTable: PointTable) => {
        await formatPointTables();
        setShowModal(false);
        onSelect(pointTable);
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error} />}
            {formattedPointTables && (
                <CreatableSelect
                    styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    value={selectedOption}
                    menuPortalTarget={document.body}
                    menuPlacement="top"
                    isClearable
                    options={formattedPointTables}
                    onChange={handleOnChange}
                />
            )}
            <IonModal backdropDismiss={false} isOpen={showModal} id="addPointTableModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        Add Point Table
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closePointTableModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    {isLoading ?
                        <Spinner />
                        :
                        <>
                            <InformationBanner info="After you create a prize money table, you'll be able to re-use it for future clases." />
                            {organizationId && (
                                <PointTableEditForm tableName={selectedLabel} organizationId={organizationId} onSubmit={handleCreatedPointTable} />
                            )}
                        </>
                    }
                </IonContent>
            </IonModal>
        </>
    );
};

export default SelectCreatePointTable;
