import { 
    IonCol,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
    IonText
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Invitation, Organization } from "../../models";
import { getPendingAndDeclinedInvitationsByOrganizationId } from "../../utilities/invitation/Invitation";
import Spinner from "../Spinners/Spinner";

import moment from "moment";

interface _Props {
    organization: Organization
}

const OrganizationInvitationsList: React.FC<_Props> = ({organization}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [invitations, setInvitations] = useState<Invitation[] | null>(null);

    useEffect(() => {
        async function getInvitationsForOrganization() {
            const queryResult = await getPendingAndDeclinedInvitationsByOrganizationId(organization.id);
            if (queryResult.isSuccess) {
                setInvitations(queryResult.result);
            }
            setIsLoading(false);
        }
  
        getInvitationsForOrganization();
    }, []);

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {invitations && invitations?.length ?
                        <IonList className="bg-white">
                            {invitations.map((invitation: Invitation, index: number) => (
                                <IonItem key={index}>
                                    <IonLabel className="ion-text-wrap">
                                        <IonRow>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonText>{invitation.inviteeEmail ? invitation.inviteeEmail : "TEST"}</IonText>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonText>{invitation.status}</IonText>
                                            </IonCol>
                                            <IonCol sizeXs="12" sizeMd="4">
                                                <IonText>{moment(invitation.createdOn).format("MM/DD/YY")}</IonText>
                                            </IonCol>
                                        </IonRow>
                                    </IonLabel>
                                </IonItem> 
                            ))}
                        </IonList>
                        :
                        <IonText>No invitations found yet.</IonText>
                    }
                </>
            }
        </>
    );
};

export default OrganizationInvitationsList;
