import { OrganizationIndustryOption, organizationIndustries } from "../../utilities/organization/OrganizationIndustry";
import { FormGroup, Input } from "reactstrap";
import React, { useEffect, useState } from "react";
import { IonLabel } from "@ionic/react";
import RequiredInputIndicator from "../Forms/RequiredInputIndicator";

interface _Props {
    selectedValue?: (string | null | undefined),
    onInputChange: Function,
    isRequired?: (boolean | null)
}

const SelectOrganizationIndustry: React.FC<_Props> = ({selectedValue, onInputChange, isRequired}) => {
    const [organizationIndustry, setOrganizationIndustry] = useState<string>("-");

    useEffect(() => {
        if (selectedValue) setOrganizationIndustry(selectedValue);
    }, [selectedValue]);

    return (
        <>
            <FormGroup>
                <IonLabel className="description pl-3">Industry {isRequired && <RequiredInputIndicator />}</IonLabel>
                <Input 
                    id="create-org-industry"
                    name="OrganizationIndustry" 
                    type="select" 
                    value={organizationIndustry} 
                    onChange={e => {
                        setOrganizationIndustry(e.target.value);
                        onInputChange(e.target.value)
                    }}
                >
                    <option>-</option>
                    {organizationIndustries?.map((organizationIndustry: OrganizationIndustryOption, index: number) => (
                        <option key={index} value={organizationIndustry.value}>{organizationIndustry.name}</option>
                    ))}
                </Input>
            </FormGroup>
        </>
    );
};

export default SelectOrganizationIndustry;