import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import { Barn, Rider } from "../../models";
import { getAllRiders, getRidersByBarnId } from "../../utilities/rider/Rider";
import Spinner from "../Spinners/Spinner";
const { v4: uuidv4 } = require('uuid');

interface _Props {
    selectedBarn?: (Barn | null)
    onSelect: Function
    isHeightRequired?: boolean
    height?: string
}

interface formattedOption {
    value: string //Rider's id
    label: string //Used for filtering options as a user types
    rider?: Rider
    riderName: string
    riderNameLabel?: string
    barnLabel?: string
}

interface formattedGroupOfOptions {
    label: string
    options: formattedOption[]
}

/**
 * This function is passed into the select component to define what should be shown when an option is selected
 * Instead of showing the 3 lines from the option,
 * it will only show the 1 line defined below: riderName
 * @param props 
 * @returns 
 */
const SingleValue = (props: any) => (
    <components.SingleValue {...props}>
        {props.data.riderName || props.data.label}
    </components.SingleValue>
);

const SelectOrCreateRider: React.FC<_Props> = ({selectedBarn, onSelect, isHeightRequired, height}) => {

    // We group the options into: 1-Currently Selected Barn's riders or 2-All Riders
    const [formattedBarnRiders, setFormattedBarnRiders] = useState<formattedOption[] | null | undefined>();
    const [formattedRiders, setFormattedRiders] = useState<formattedOption[] | null | undefined>();

    // We then put the formatted options into defined Groups
    const [barnRidersGroup, setBarnRidersGroup] = useState<formattedGroupOfOptions | null | undefined>();
    const [allRidersGroup, setAllRidersGroup] = useState<formattedGroupOfOptions | null | undefined>();

    const [currentValue, setCurrentValue] = useState<formattedOption | null | undefined>();
    const [isLoading, setIsLoading] = useState(false);

    const sortRiderOptions = (riderArray: Rider[]) => {
        return riderArray.sort((a,b) => a.name.trim().localeCompare(b.name.trim()));
    }

    const removeBarnRidersFromAllRidersGroup = async (barnRiders: formattedOption[]) => {
        if (allRidersGroup && formattedRiders) {
            const updatedFormattedRiders = formattedRiders.filter((el) => !barnRiders.find((bh => bh.value === el.value)));
            const allGroup: formattedGroupOfOptions = {
                label: "All Riders in RingSide Pro database",
                options: updatedFormattedRiders
            };
            setAllRidersGroup(allGroup);
        }
    };

    const getBarnRiders = async () => {
        setIsLoading(true);

        // Get the current list of riders
        let currentRiderList: Rider[] = [];
        if (selectedBarn) {
            const queryRidersByBarn = await getRidersByBarnId(selectedBarn.id);
            if (queryRidersByBarn.isSuccess) {
                currentRiderList = queryRidersByBarn.result;
            }
        }

        // Sort and format the list
        const sortedRiderList = sortRiderOptions(currentRiderList);
        const currentFormattedRiders = await formatRiders(sortedRiderList);

        // Set the formatted options and group in state
        setFormattedBarnRiders(currentFormattedRiders);
        const barnGroup: formattedGroupOfOptions = {
            label: (selectedBarn?.name || "Barn") + " Riders",
            options: currentFormattedRiders
        };
        setBarnRidersGroup(barnGroup);

        // Remove the selected barn's riders from the list of all riders
        removeBarnRidersFromAllRidersGroup(currentFormattedRiders);

        setIsLoading(false);
    };

    const getRiders = async () => {
        setIsLoading(true);

        // Get the current list of riders
        let currentRiderList: Rider[] = [];
        const queryResult = await getAllRiders();
        if (queryResult.isSuccess) {
            currentRiderList = queryResult.result;
        }

        // Sort and format the list
        const sortedRiderList = sortRiderOptions(currentRiderList);
        const currentFormattedRiders = await formatRiders(sortedRiderList);
        
        // Set the formatted options and group in state
        setFormattedRiders(currentFormattedRiders);


        // If barn riders are already there, remove them from the full list and set All Riders Group
        if (formattedBarnRiders) {
            removeBarnRidersFromAllRidersGroup(formattedBarnRiders);
        } else {
            const allGroup: formattedGroupOfOptions = {
                label: "All Riders in RingSide Pro database",
                options: currentFormattedRiders
            };
            setAllRidersGroup(allGroup);    
        }
        
        setIsLoading(false);
    };

    const formatSingleRider = (rider: Rider) => {
        const value = rider.id;
        const name = rider.name || "Unknown - No name found";
        const barnName = rider?.barnName || rider.barn?.name || "No Associated Barn Found";

        let object: formattedOption = {
            value: value,
            label: name + " " + barnName,
            riderName: name,
            riderNameLabel: name,
            barnLabel: barnName,
            rider: rider
        };
        return object;
    }

    const formatRiders = async (riderArray: Rider[]) => {
        setIsLoading(true);

        let formattedRiders = [];

        for (var i = 0; i < riderArray.length; i++) {
            const rider = riderArray[i];
            let object: formattedOption = formatSingleRider(rider);
            formattedRiders.push(object);
        }
       
        return formattedRiders;
    };

    const findValueInList = (value: string, riderList?: formattedOption[]) => {
        let optionArray = riderList || formattedRiders;
        if (optionArray) {
            for (var i = 0; i < optionArray.length; i++) {
                const currentOption = optionArray[i];
                if (currentOption.value === value) {
                    setCurrentValue(currentOption);
                }
            }
        }
    };

    useEffect(() => {
        getRiders();
    }, []);

    useEffect(() => {
        getBarnRiders();
    }, [selectedBarn]);

    const handleOnChange = (event?: any) => {
        if (event && event.rider) {
            setCurrentValue(event);
            onSelect(event.rider);
        } else if (event && event.value) {
            // When a new option is selected from the "Create" option, it will only have a value
            if (event.riderName) {
                // This option was created by the user and then selected again
                setCurrentValue(event);
                onSelect(null, event.riderName);
            } else {
                // Format the new option
                const newFormattedOption: formattedOption = {
                    value: uuidv4(),
                    label: event.value + " " + (selectedBarn?.name || ""),
                    riderName: event.value,
                    riderNameLabel: event.value,
                    barnLabel: selectedBarn?.name || ""
                };

                if (selectedBarn) {
                    // If a barn is currently selected, then add the new option to the list of barn riders
                    const updatedFormattedBarnRiders = formattedBarnRiders ? formattedBarnRiders.concat([newFormattedOption]) : [newFormattedOption];
                    const sortedFormattedBarnRiders = updatedFormattedBarnRiders.sort((a,b) => a.riderName.trim().localeCompare(b.riderName.trim()));
                    setFormattedBarnRiders(updatedFormattedBarnRiders);
                    const barnGroup: formattedGroupOfOptions = {
                        label: (selectedBarn?.name || "Barn") + " Riders",
                        options: sortedFormattedBarnRiders
                    };
                    setBarnRidersGroup(barnGroup);
                } else {
                    // Add the rider to the full list of options
                    const updatedFormattedRiders = formattedRiders ? formattedRiders.concat([newFormattedOption]) : [newFormattedOption];
                    setFormattedRiders(updatedFormattedRiders);
                    const allGroup: formattedGroupOfOptions = {
                        label: "All Riders in RingSide Pro database",
                        options: updatedFormattedRiders
                    };
                    setAllRidersGroup(allGroup);
                }
                // Set this option as the currently selected option
                setCurrentValue(newFormattedOption);
                console.log("send back event.value: ", event.value);
                onSelect(null, event.value);
            }
        } else {
            setCurrentValue(null);
            onSelect(null);
        }
    }

    return (
        <>
            {isLoading ?
                <Spinner />
                :
                <>
                    {(barnRidersGroup && allRidersGroup) && (
                        <CreatableSelect
                            id="select-or-create-rider"
                            inputId="select-or-create-rider-input"
                            placeholder="Type the rider's name"
                            styles={{
                                // Fixes the overlapping problem of the component
                                control: baseStyles =>(isHeightRequired ? {...baseStyles, height} : {...baseStyles}),
                                menu: provided => ({ ...provided, zIndex: 9999 }),
                            }}
                            defaultValue={currentValue}
                            value={currentValue}
                            menuPortalTarget={document.body}
                            isClearable
                            options={[
                                barnRidersGroup,
                                allRidersGroup
                            ]}
                            formatOptionLabel={(option: any) => (
                                option.riderNameLabel ?
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold text-default">{option.riderNameLabel}</div>
                                        <div className="display-5 text-medium">Barn: {option.barnLabel}</div>
                                    </div>
                                )
                                :
                                (
                                    <div className="p-0">
                                        <div className="font-weight-bold text-default">Add New Rider: {option.value}</div>
                                        <div className="display-5 text-medium">To Barn: {selectedBarn ? selectedBarn.name : "None Selected"}</div>
                                    </div>
                                )
                            )}
                            components={{ SingleValue }}
                            onChange={(event: any) => handleOnChange(event)}
                            createOptionPosition={"last"}
                        />
                    )}
                </>
            }
        </>
    )
};

export default SelectOrCreateRider;
