/**
 * The USHJA offers an outreach program where competitions can offer special outreach classes
 * When the results are reported to USHJA, we must be able to display which class was offered
 * https://www.ushja.org/competition/outreach
 */

export enum USHJAOutreachDiscipline {
    Hunter,
    Jumper,
    Equitation
}

export interface USHJAOutreachSection {
    name: string
    discipline: USHJAOutreachDiscipline
    height?: number //USHJA requires that the height be reported along with the results. Some sections have options for the height, while others have a standard height.
}

export const sectionList: USHJAOutreachSection[] = [
    {
        name: `USHJA Outreach Hunter Walk Trot Poles`,
        discipline: USHJAOutreachDiscipline.Hunter,
        height: 0 
    },
    {
        name: `USHJA Outreach Hunters 18" or X-rail`,
        discipline: USHJAOutreachDiscipline.Hunter,
        height: 18 
    },
    {
        name: `USHJA Outreach Hunters 2'0"`,
        discipline: USHJAOutreachDiscipline.Hunter,
        height: 24
    },
    {
        name: `USHJA Outreach Hunters 2'3"`,
        discipline: USHJAOutreachDiscipline.Hunter,
        height: 27
    },
    {
        name: `USHJA Outreach Hunters 2'6"`,
        discipline: USHJAOutreachDiscipline.Hunter,
        height: 30
    },
    {
        name: `USHJA Outreach Hunters 2'9"`,
        discipline: USHJAOutreachDiscipline.Hunter,
        height: 33
    },
    {
        name: `USHJA Outreach Hunters 3'0"`,
        discipline: USHJAOutreachDiscipline.Hunter,
        height: 36
    },
    {
        name: `USHJA Outreach Hunter Derby`,
        discipline: USHJAOutreachDiscipline.Hunter
    },
    {
        name: `USHJA Outreach Jumper .60m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 0.60
    },
    {
        name: `USHJA Outreach Jumper .65m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 0.65
    },
    {
        name: `USHJA Outreach Jumper .70m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 0.70
    },
    {
        name: `USHJA Outreach Jumper .75m-.80m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 0.80
    },
    {
        name: `USHJA Outreach Jumper .85m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 0.85
    },
    {
        name: `USHJA Outreach Jumper .90m-.95m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 0.95
    },
    {
        name: `USHJA Outreach Jumper 1.0m-1.05m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 1.05
    },
    {
        name: `USHJA Outreach Jumper 1.10.-1.15m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 1.15
    },
    {
        name: `USHJA Outreach Jumper 1.20m`,
        discipline: USHJAOutreachDiscipline.Jumper,
        height: 1.20
    },
    {
        name: `USHJA Outreach Walk Trot Equitation`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 0
    },
    {
        name: `USHJA Outreach Walk, Trot, Canter Equitation`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 0
    },
    {
        name: `USHJA Outreach Equitation 18" or X-rail`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 18
    },
    {
        name: `USHJA Outreach Equitation 2'0"`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 24
    },
    {
        name: `USHJA Outreach Equitation 2'3"`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 27
    },
    {
        name: `USHJA Outreach Equitation 2'6"`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 30
    },
    {
        name: `USHJA Outreach Equitation 2'9"`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 33
    },
    {
        name: `USHJA Outreach Equitation 3'0"`,
        discipline: USHJAOutreachDiscipline.Equitation,
        height: 36
    }
];