import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateSubmissionInput, CreateSubmissionMutation, DeleteSubmissionInput, DeleteSubmissionMutation, GetSubmissionQuery, ListSubmissionsQuery, UpdateSubmissionInput, UpdateSubmissionMutation } from "../../API";
import moment from "moment";
import { Submission } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: submission. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created submission.
*/
export async function createSubmission(input: CreateSubmissionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Submission", "No input", "Create Submission received no input.");
   try {
        const fullInput: CreateSubmissionInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createSubmission, { input: fullInput }))) as GraphQLResult<CreateSubmissionMutation>;
       else result = (await API.graphql({
           query: mutations.createSubmission,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateSubmissionMutation>;
       const submission = result.data?.createSubmission;
       return formatResult(true, "Submission", submission, "Successfully created the submission.");
   } catch (error: any) {
       return formatResult(false, "Submission", error, "Error creating record in the database for type: submission");
   }
}

/**
* Handle updating a new record in the database for type: submission. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated submission.
*/
export async function updateSubmission(input: UpdateSubmissionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Submission", "No input", "Update Submission received no input.");
   try {
        const fullInput: UpdateSubmissionInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateSubmission, { input: fullInput }))) as GraphQLResult<UpdateSubmissionMutation>;
       else result = (await API.graphql({
           query: mutations.updateSubmission,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateSubmissionMutation>;
       const submission = result.data?.updateSubmission;
       return formatResult(true, "Submission", submission, "Successfully updated the submission.");
   } catch (error: any) {
       return formatResult(false, "Submission", error, "Error updating record in the database for type: submission");
   }
}

/**
* Handle deleting a new record in the database for type: submission. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted submission.
*/
export async function deleteSubmission(input: DeleteSubmissionInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "Submission", "No input", "Delete Submission received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteSubmission, { input: input }))) as GraphQLResult<DeleteSubmissionMutation>;
       else result = (await API.graphql({
           query: mutations.deleteSubmission,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteSubmissionMutation>;
       const submission = result.data?.deleteSubmission;
       return formatResult(true, "Submission", submission, "Successfully deleted the submission.");
   } catch (error: any) {
       return formatResult(false, "Submission", error, "Error deleting record in the database for type: submission");
   }
}

/**
* Get all records in the database for type: submission. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submission object
*/
export async function getAllSubmissions(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listSubmissions,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListSubmissionsQuery>;
        
        let items = result.data?.listSubmissions?.items as Submission[];
        let nextToken = result.data?.listSubmissions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listSubmissions,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListSubmissionsQuery>;

            const nextItems = nextResult.data?.listSubmissions?.items as Submission[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listSubmissions?.nextToken;
        }

        return formatResult(true, "Submission", items, "Successfully got the Submissions.");
    } catch (error: any) {
        return formatResult(false, "Submission", error, "Error reading record in the database for type: submissions");
    }
}

/**
* Read a specific record in the database for type: submission. 
* 
* @param {string}  id                  The submission id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submission object
*/
export async function getSubmissionById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getSubmission,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetSubmissionQuery>;
       const submission = result.data?.getSubmission;
       return formatResult(true, "Submission", submission, "Successfully got the submission.");
   } catch (error: any) {
       return formatResult(false, "Submission", error, "Error reading record in the database for type: submission");
   }
}

/**
* Get all records in the database that match the given criteria for type: submission. 
* 
* @param {string}  personId            The person id of the "owner" of the submission
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the submission object
*/
export async function getSubmissionsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listSubmissions,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListSubmissionsQuery>;

        let items = result.data?.listSubmissions?.items as Submission[];
        let nextToken = result.data?.listSubmissions?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listSubmissions,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListSubmissionsQuery>;

            const nextItems = nextResult.data?.listSubmissions?.items as Submission[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listSubmissions?.nextToken;
        }

        return formatResult(true, "Submission", items, "Successfully got the Submissions.");
    } catch (error: any) {
        return formatResult(false, "Submission", error, "Error reading record in the database for type: submissions");
    }
}