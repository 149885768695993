import React, { useEffect, useState } from "react";
import { Event, EventEntry } from "../../../models";
import EntryInfoBarn from "./EntryInfo/EntryInfoBarn"
import EntryInfoHorse from "./EntryInfo/EntryInfoHorse";
import EntryInfoOwner from "./EntryInfo/EntryInfoOwner";
import EntryInfoPrimaryContact from "./EntryInfo/EntryInfoPrimaryContact";
import EntryInfoRider from "./EntryInfo/EntryInfoRider";
import EntryInfoRider2 from "./EntryInfo/EntryInfoRider2";
import EntryInfoTrainer from "./EntryInfo/EntryInfoTrainer";
import { IonCol, IonRow } from "@ionic/react";

interface _Props {
    entry: EventEntry
    event: Event
}

const EditEntryAbout: React.FC<_Props> = ({entry, event}) => {

    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();

    useEffect(() => {
        if (entry) {
            setCurrentEntry(entry);
        }
    }, [entry]);

    return (
        <>
            {currentEntry && (
                <IonRow>
                    <IonCol sizeXs="12" sizeMd="6">
                        <EntryInfoHorse event={event} entry={currentEntry} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <EntryInfoOwner entry={currentEntry} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <EntryInfoRider event={event} entry={currentEntry} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <EntryInfoRider2 entry={currentEntry} />
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <EntryInfoTrainer entry={currentEntry}/>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <EntryInfoBarn entry={currentEntry}/>
                    </IonCol>
                    <IonCol sizeXs="12" sizeMd="6">
                        <EntryInfoPrimaryContact entry={currentEntry}/>
                    </IonCol>
                </IonRow>
            )}
        </>
    );
};

export default EditEntryAbout;