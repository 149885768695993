import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";

// Configure Amplify
import Amplify from "aws-amplify";
import awsmobile from "../../aws-exports";

import formatResult from "../ReturnType/ReturnType";
import { CreateStablingRequestInput, CreateStablingRequestMutation, DeleteStablingRequestInput, DeleteStablingRequestMutation, GetStablingRequestQuery, ListStablingRequestsQuery, UpdateStablingRequestInput, UpdateStablingRequestMutation } from "../../API";
import moment from "moment";
import { StablingRequest } from "../../models";

Amplify.configure(awsmobile);

/**
* Handle creating a new record in the database for type: stablingRequest. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly created stablingRequest.
*/
export async function createStablingRequest(input: CreateStablingRequestInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "StablingRequest", "No input", "Create StablingRequest received no input.");
   try {
        const fullInput: CreateStablingRequestInput = {
            ...input,
            createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.createStablingRequest, { input: fullInput }))) as GraphQLResult<CreateStablingRequestMutation>;
       else result = (await API.graphql({
           query: mutations.createStablingRequest,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<CreateStablingRequestMutation>;
       const stablingRequest = result.data?.createStablingRequest;
       return formatResult(true, "StablingRequest", stablingRequest, "Successfully created the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "StablingRequest", error, "Error creating record in the database for type: stablingRequest");
   }
}

/**
* Handle updating a new record in the database for type: stablingRequest. 
* 
* @param {object}  input                   Check schema for input.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly updated stablingRequest.
*/
export async function updateStablingRequest(input: UpdateStablingRequestInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "StablingRequest", "No input", "Update StablingRequest received no input.");
   try {
        const fullInput: UpdateStablingRequestInput = {
            ...input,
            updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
        };
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.updateStablingRequest, { input: fullInput }))) as GraphQLResult<UpdateStablingRequestMutation>;
       else result = (await API.graphql({
           query: mutations.updateStablingRequest,
           variables: {
               input: fullInput
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<UpdateStablingRequestMutation>;
       const stablingRequest = result.data?.updateStablingRequest;
       return formatResult(true, "StablingRequest", stablingRequest, "Successfully updated the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "StablingRequest", error, "Error updating record in the database for type: stablingRequest");
   }
}

/**
* Handle deleting a new record in the database for type: stablingRequest. 
* 
* @param {object}  input                   Just requires the ID of the object.
* @param {string}  authMode                API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {string} Returns the newly deleted stablingRequest.
*/
export async function deleteStablingRequest(input: DeleteStablingRequestInput, authMode?: GRAPHQL_AUTH_MODE) {
   if (!input) return formatResult(false, "StablingRequest", "No input", "Delete StablingRequest received no input.");
   try {
       let result;
       if (!authMode) result = (await API.graphql(graphqlOperation(mutations.deleteStablingRequest, { input: input }))) as GraphQLResult<DeleteStablingRequestMutation>;
       else result = (await API.graphql({
           query: mutations.deleteStablingRequest,
           variables: {
               input: input
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<DeleteStablingRequestMutation>;
       const stablingRequest = result.data?.deleteStablingRequest;
       return formatResult(true, "StablingRequest", stablingRequest, "Successfully deleted the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "StablingRequest", error, "Error deleting record in the database for type: stablingRequest");
   }
}

/**
* Get all records in the database for type: stablingRequest. 
* 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getAllStablingRequests(authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.listStablingRequests,
            variables: {
                limit: 100
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListStablingRequestsQuery>;
        
        let items = result.data?.listStablingRequests?.items as StablingRequest[];
        let nextToken = result.data?.listStablingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listStablingRequests,
                variables: {
                    limit: 100,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListStablingRequestsQuery>;

            const nextItems = nextResult.data?.listStablingRequests?.items as StablingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listStablingRequests?.nextToken;
        }

        return formatResult(true, "StablingRequest", items, "Successfully got the StablingRequests.");
    } catch (error: any) {
        return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Read a specific record in the database for type: stablingRequest. 
* 
* @param {string}  id                  The stablingRequest id.
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getStablingRequestById(id: string, authMode?: GRAPHQL_AUTH_MODE) {
   try {
       const result = (await API.graphql({
           query: queries.getStablingRequest,
           variables: {
               id: id
           },
           authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
       })) as GraphQLResult<GetStablingRequestQuery>;
       const stablingRequest = result.data?.getStablingRequest;
       return formatResult(true, "StablingRequest", stablingRequest, "Successfully got the stablingRequest.");
   } catch (error: any) {
       return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequest");
   }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  personId            The person id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getStablingRequestsByPersonId(personId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}};
        const result = (await API.graphql({
            query: queries.listStablingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListStablingRequestsQuery>;
        
        let items = result.data?.listStablingRequests?.items as StablingRequest[];
        let nextToken = result.data?.listStablingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listStablingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListStablingRequestsQuery>;

            const nextItems = nextResult.data?.listStablingRequests?.items as StablingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listStablingRequests?.nextToken;
        }

        return formatResult(true, "StablingRequest", items, "Successfully got the StablingRequests.");
    } catch (error: any) {
        return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getStablingRequestsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.stablingRequestsByEventId,
            variables: {
                limit: 1000,
                eventId: eventId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.stablingRequestsByEventId?.items as StablingRequest[];
        let nextToken = result.data?.stablingRequestsByEventId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.stablingRequestsByEventId,
                variables: {
                    limit: 100,
                    eventId: eventId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.stablingRequestsByEventId?.items as StablingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.stablingRequestsByEventId?.nextToken;
        }

        return formatResult(true, "StablingRequest", items, "Successfully got the StablingRequests.");
    } catch (error: any) {
        return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getAcceptedStablingRequestsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, status: {eq: "accepted"}};
        const result = (await API.graphql({
            query: queries.listStablingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListStablingRequestsQuery>;
        
        let items = result.data?.listStablingRequests?.items as StablingRequest[];
        let nextToken = result.data?.listStablingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listStablingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListStablingRequestsQuery>;

            const nextItems = nextResult.data?.listStablingRequests?.items as StablingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listStablingRequests?.nextToken;
        }

        return formatResult(true, "StablingRequest", items, "Successfully got the StablingRequests.");
    } catch (error: any) {
        return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getNotAcceptedStablingRequestsByEventId(eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {eventId: {eq: eventId}, status: {ne: "accepted"}};
        const result = (await API.graphql({
            query: queries.listStablingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListStablingRequestsQuery>;
        
        let items = result.data?.listStablingRequests?.items as StablingRequest[];
        let nextToken = result.data?.listStablingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listStablingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListStablingRequestsQuery>;

            const nextItems = nextResult.data?.listStablingRequests?.items as StablingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listStablingRequests?.nextToken;
        }

        return formatResult(true, "StablingRequest", items, "Successfully got the StablingRequests.");
    } catch (error: any) {
        return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  personId            The person id 
* @param {string}  eventId             The event id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getStablingRequestsByPersonIdEventId(personId: string, eventId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const filter = {personId: {eq: personId}, eventId: {eq: eventId}};
        const result = (await API.graphql({
            query: queries.listStablingRequests,
            variables: {
                limit: 1000,
                filter
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<ListStablingRequestsQuery>;
        
        let items = result.data?.listStablingRequests?.items as StablingRequest[];
        let nextToken = result.data?.listStablingRequests?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.listStablingRequests,
                variables: {
                    limit: 100,
                    filter,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<ListStablingRequestsQuery>;

            const nextItems = nextResult.data?.listStablingRequests?.items as StablingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.listStablingRequests?.nextToken;
        }

        return formatResult(true, "StablingRequest", items, "Successfully got the StablingRequests.");
    } catch (error: any) {
        return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}

/**
* Get all records in the database that match the given criteria for type: stablingRequest. 
* 
* @param {string}  entryId             The entry id 
* @param {string}  authMode            API_KEY (default), AWS_AIM or AMAZON_COGNITO_USER_POOLS
* 
* @return {object} Returns the stablingRequest object
*/
export async function getStablingRequestsByEntryId(entryId: string, authMode?: GRAPHQL_AUTH_MODE) {
    try {
        const result = (await API.graphql({
            query: queries.stablingRequestsByEntryId,
            variables: {
                limit: 1000,
                submittedEntry: entryId
            },
            authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
        })) as GraphQLResult<any>;
        
        let items = result.data?.stablingRequestsByEntryId?.items as StablingRequest[];
        let nextToken = result.data?.stablingRequestsByEntryId?.nextToken;

        while (nextToken) {
            const nextResult = await API.graphql({
                query: queries.stablingRequestsByEntryId,
                variables: {
                    limit: 100,
                    submittedEntry: entryId,
                    nextToken
                },
                authMode: authMode || GRAPHQL_AUTH_MODE.API_KEY
            }) as GraphQLResult<any>;

            const nextItems = nextResult.data?.stablingRequestsByEntryId?.items as StablingRequest[];
            if (nextItems && nextItems.length) {
                items = items?.concat(nextItems);
            }

            nextToken = nextResult.data?.stablingRequestsByEntryId?.nextToken;
        }

        return formatResult(true, "StablingRequest", items, "Successfully got the StablingRequests.");
    } catch (error: any) {
        return formatResult(false, "StablingRequest", error, "Error reading record in the database for type: stablingRequests");
    }
}