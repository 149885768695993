import {
    IonButton,
    IonButtons,
    IonContent,
    IonIcon,
    IonModal,
    IonTitle,
    IonToolbar,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import { close } from "ionicons/icons";
import { Event, EventAuditor } from "../../models";
import EventAuditorForm from "./EventAuditorForm";

interface _Props {
    event: Event
    eventAuditor?: (EventAuditor | null)
    show?: Boolean
    onChange: Function
    isAdminView?: Boolean
}

const EventAuditorModal: React.FC<_Props> = ({event, eventAuditor, show, onChange, isAdminView}) => {
    
    const [showModal, setShowModal] = useState(false);
    const [currentEventAuditor, setCurrentEventAuditor] = useState<EventAuditor | null | undefined>();

    useEffect(() => {
        if (eventAuditor) {
            setCurrentEventAuditor(eventAuditor);
            setShowModal(true);
        }
        else setCurrentEventAuditor(undefined);
    }, [eventAuditor]);

    useEffect(() => {
        if (show) setShowModal(true);
        else setShowModal(false);
    }, [show]);

    const handleFoundEventAuditor = (eventAuditor?: EventAuditor) => {
        setCurrentEventAuditor(eventAuditor);
    }

    const handleSubmit = (action: string, eventAuditor: EventAuditor) => {
        setShowModal(false);
        setCurrentEventAuditor(null);
        onChange(action, eventAuditor);
    }

    return (
        <>
            <IonModal backdropDismiss={false} isOpen={showModal} id="contactModal">
                <IonToolbar color="light">
                    <IonTitle className="ion-text-center">
                        {currentEventAuditor ? "Edit Subscriber" : "New Subscriber"}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton
                            fill="clear"
                            onClick={() => setShowModal(false)}
                        >
                            <p id="closeContactModalBtn" className="font-weight-normal text-medium text-capitalize">
                                <IonIcon icon={close} />
                            </p>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonContent className="ion-padding">
                    <EventAuditorForm event={event} eventAuditor={currentEventAuditor} isAdminView={isAdminView} onFindCurrentEventAuditor={handleFoundEventAuditor} onCreate={(eventAuditor: EventAuditor) => handleSubmit("create", eventAuditor)} onEdit={(eventAuditor: EventAuditor) => handleSubmit("update", eventAuditor)} onDelete={(eventAuditor: EventAuditor) => handleSubmit("delete", eventAuditor)}/>
                </IonContent>
            </IonModal>
        </>
    );
};

export default EventAuditorModal;
