import {
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import {Event, EventEntry} from "../../../models";
import Header from "../../../components/Headers/Header";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { RouteComponentProps } from "react-router";
import {getEventById} from "../../../utilities/events/Event";
import ErrorAlert from "../../../components/Errors/ErrorAlert";
import CreateEntriesForm from "../../../components/Entry/CreateEntriesForm";
import { getEventEntryById } from "../../../utilities/eventEntry/EventEntry";

interface EntryPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventEditEntryPage: React.FC<EntryPageProps> = ({match}) => {
    const [currentEvent, setCurrentEvent] = useState<Event>();
    const [currentEventEntry, setCurrentEventEntry] = useState<EventEntry>();
    const [error, setError] = useState("");

    useEffect(() => {
        async function getEventEntry() {
            const queryResult = await getEventEntryById(match.params.id);
            if (queryResult.isSuccess) {
                const entry = queryResult.result;
                setCurrentEventEntry(entry);
                if (entry.eventId) getEvent(entry.eventId);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        async function getEvent(eventId: string) {
            const queryResult = await getEventById(eventId);
            if (queryResult.isSuccess) {
                setCurrentEvent(queryResult.result);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
            }
        }
        getEventEntry();
    }, [match, match.params.id]);

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title={currentEvent && currentEvent.name ? currentEvent.name : currentEventEntry ? (currentEventEntry.horseName ? currentEventEntry.horseName : "Entry") : "Entry"} />
                {error && <ErrorAlert width="12" error={error} />}
                <IonRow id="entryForm" className="ion-align-items-stretch">
                    <IonCol size="12">
                        <IonCard mode="md" className="ion-padding bg-white stretch">
                            <IonCardTitle>
                                Entry Form
                            </IonCardTitle>
                            <IonCardContent>
                                <CreateEntriesForm event={currentEvent} entry={currentEventEntry}/>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
            </IonContent>
        </IonPage>
    );
};

export default EventEditEntryPage;