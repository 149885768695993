import {
    IonButton,
    IonCard,
    IonCardTitle,
    IonCol,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ErrorAlert from "../../../../../components/Errors/ErrorAlert";
import Header from "../../../../../components/Headers/Header";
import { Barn, Horse, Membership, Organization, OrganizationMembershipType, Person, Rider } from "../../../../../models";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import { RouteComponentProps, useHistory } from "react-router";
import Spinner from "../../../../../components/Spinners/Spinner";
import { PersonContext } from "../../../../../context/PersonContext";
import { getOrganizationById } from "../../../../../utilities/organization/Organization";
import { createMembership, getMembershipsByHorseId, getMembershipsByRiderId } from "../../../../../utilities/membership/Membership";
import SelectFromAllBarns from "../../../../../components/Barn/SelectFromAllBarns";
import { createPerson, getPersonByPersonId } from "../../../../../utilities/person/Person";
import { CreateBarnInput, CreateHorseInput, CreateMembershipInput, CreatePersonalInformationInput, CreatePersonInput, CreateRiderInput, UpdateHorseInput, UpdateOrganizationMembershipTypeInput } from "../../../../../API";
import moment from "moment";
import { createHorse, getHorsesByBarnId, updateHorse } from "../../../../../utilities/horse/Horse";
import { createRider, getRiderByPersonId } from "../../../../../utilities/rider/Rider";
import SelectHorse from "../../../../../components/Horse/SelectHorse";
import { createBarn, getBarnByName } from "../../../../../utilities/barn/Barn";
import SelectRiderFromBarn from "../../../../../components/Rider/SelectRiderFromBarn";
import { getOrganizationMembershipTypesByOrganizationId, updateOrganizationMembershipType } from "../../../../../utilities/organizationMembershipType/OrganizationMembershipType";
import SelectOrganizationMembershipType from "../../../../../components/Memberships/SelectOrganizationMembershipType";
import CONSTANT from "../../../../../constant/constant";
import { getNextAvailableMembershipId } from "../../../../../utilities/organizationMembershipType/MembershipIdValues";
import { MembershipTypeCategory } from "../../../../../models";
import { createPersonalInformation } from "../../../../../utilities/personalInformation/PersonalInformation";
import HorseHeightInput from "../../../../../components/Horse/HorseHeightInput";
import RequiredInputIndicator from "../../../../../components/Forms/RequiredInputIndicator";
import { getDateMembershipEnds } from "../../../../../utilities/membership/MembershipStatus";

interface GroupMember {
    name: string;
    birthDate: string;
    backNumber?: number;
    barn?: Barn;
    barns?: Barn[];
    barnName?: string;
    rider?: Rider;
    riders?: Rider[];
    riderName?: string;
    person?: Person;
    firstName?: string;
    lastName?: string;
    horse?: Horse;
    horses?: Horse[];
    horseName?: string;
}

interface OrganizationPageProps extends RouteComponentProps<{
    id: string;
}> {}

const EventOrganizationMembersCreatePage: React.FC<OrganizationPageProps> = ({match}) => {
    const user = useContext(PersonContext);
    const history = useHistory();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [organization, setOrganization] = useState<Organization>();
    const [organizationMembershipTypes, setOrganizationMembershipTypes] = useState<OrganizationMembershipType[] | undefined | null>();
    const [selectedMembershipType, setSelectedMembershipType] = useState<OrganizationMembershipType | undefined | null>();

    const [fullName, setFullName] = useState<string>("");
    const [birthDate, setBirthDate] = useState<string>("");
    const [backNumber, setBackNumber] = useState<number>(0);
    const [riderName, setRiderName] = useState<string>("");
    const [rider, setRider] = useState<Rider | null | undefined>();
    const [membershipId, setMembershipId] = useState<string>("");
    const [horseName, setHorseName] = useState<string>("");
    const [horseHeight, setHorseHeight] = useState<number>(0);
    const [horseOwnerName, setHorseOwnerName] = useState<string>("");
    const [horse, setHorse] = useState<Horse | undefined | null>();
    const [horseOptions, setHorseOptions] = useState<Horse[] | undefined | null>([]);
    const [barn, setBarn] = useState<Barn | undefined | null>();
    const [barnName, setBarnName] = useState<string>("");
    const [groupMembers, setGroupMembers] = useState<(GroupMember | null)[]>([]);
    const [person, setPerson] = useState<Person | undefined | null>();
    const [error, setError] = useState<string>("");

    function clearForm() {
        setFullName("");
        setBirthDate("");
        setPerson(undefined);
        setBarn(undefined);
        setBarnName("");
        setHorseName("");
        setHorseHeight(0);
        setHorseOwnerName("")
        setHorse(undefined);
        setMembershipId("");
        setBackNumber(0);
        setRider(undefined);
        setRiderName("");
        setGroupMembers([]);
        setSelectedMembershipType(undefined);
    }

    async function getOrganizationMembershipTypes(organizationId: string) {
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationMembershipTypesByOrganizationId(organizationId);
            if (queryResult.isSuccess) {
                const organizationMembershipTypes: OrganizationMembershipType[] = queryResult.result;
                setOrganizationMembershipTypes(organizationMembershipTypes);
                setIsLoading(false);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    }

    async function getOrganization() {
        clearForm();
        try {
            setIsLoading(true);
            const queryResult = await getOrganizationById(match.params.id);
            if (queryResult.isSuccess) {
                setOrganization(queryResult.result);
                await getOrganizationMembershipTypes(match.params.id);
                setIsLoading(false);
            } else {
                setError("Sorry, a problem occurred. Please go back and try again.");
                setIsLoading(false);
            }
        } catch (error: any) {
            setError("Sorry, a problem occurred. Please go back and try again.");
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (match.params.id) getOrganization();
    }, [match, match.params, match.params.id]);

    const handleSelectRider = async (rider?: Rider) => {
        if (rider) {
            setRider(rider);
            setRiderName(rider.name);
            setFullName(rider.name);
            setBirthDate(rider.birthdate || "");
            const personId = rider.personId;
            if (personId) {
                const personResult = await getPersonByPersonId(personId);
                if (personResult.isSuccess) {
                    const foundPerson: Person = personResult.result;
                    setPerson(foundPerson);
                }
            }

            const queryResult = await getMembershipsByRiderId(rider.id);
            if (queryResult.isSuccess) {
                const membershipList: Membership[] = queryResult.result;
                if (membershipList && membershipList.length > 0) {
                    const membership = membershipList.find((mem) => {
                        return mem.name === organization?.name && mem.backNumber;
                    });
                    if (membership && membership.backNumber) {
                        setBackNumber(membership.backNumber);
                    }
                }
            }
        } else {
            setRider(undefined);
            setRiderName("");
            setFullName("");
            setBirthDate("");
        }
    }

    const handleSelectBarn = async (barn?: Barn) => {
        if (barn) {
            setBarn(barn);
            setBarnName(barn.name);
            const queryResult = await getHorsesByBarnId(barn.id);
            if (queryResult.isSuccess) {
                setHorseOptions(queryResult.result);
            }
        } else {
            setBarn(undefined);
            setBarnName("");
            setHorseOptions(undefined);
        }
    }

    const handleSelectHorse = async (horse?: Horse) => {
        if (horse) {
            setHorse(horse);
            setHorseName(horse.name || horseName || "");
            if (horse.height) {
                setHorseHeight(parseFloat(horse.height));
            }
            if (horse.ownerName) {
                setHorseOwnerName(horse.ownerName);
            }
            const queryResult = await getMembershipsByHorseId(horse.id);
            if (queryResult.isSuccess) {
                const membershipList: Membership[] = queryResult.result;
                if (membershipList && membershipList.length > 0) {
                    const membership = membershipList.find(mem => rider ? mem.rider?.id === rider.id : true);
                    if (membership && membership.backNumber) {
                        setBackNumber(membership.backNumber);
                    }
                }
            }
        } else {
            setHorse(undefined);
            setHorseName("");
            setHorseOwnerName("");
            setHorseHeight(0);
        }
    }

    const handleHeightChange = (heightInInches: number) => {
        setHorseHeight(heightInInches);
    }

    const handleSelectMembershipType = async (memType?: OrganizationMembershipType) => {
        if (memType) {
            clearForm();
            setError("");
            setSelectedMembershipType(memType);
            if (memType.nextAvailableMembershipId) {
                setMembershipId(memType.nextAvailableMembershipId);
            }
            if (memType.category === MembershipTypeCategory.GROUP) {
                // Populate the group member array with the max number of objects that can be added
                if (memType && memType.applicationFields?.collectMemberDetailsTogether &&
                    memType.applicationFields?.maxNumberOfGroupMembers) {
                    const emptyGroupMemberArray = Array(memType.applicationFields?.maxNumberOfGroupMembers).fill(null);
                    setGroupMembers([...emptyGroupMemberArray]);
                }
                // Handle single group membership
                if (memType.applicationFields?.collectMemberDetailsTogether === false) {
                    const singleGroupMember = {
                        name: "",
                        birthDate: ""
                    };
                    setGroupMembers([singleGroupMember]);
                }
            }
        } else {
            setSelectedMembershipType(undefined);
        }
    }

    const verifyIsValid = () => {
        if (selectedMembershipType?.category === MembershipTypeCategory.INDIVIDUAL) {
            if (!fullName) {
                setError("A required field is missing data: Full Name");
                return false;
            }
            // if (!birthDate) {
            //     setError("A required field is missing data: Birth Date");
            //     return false;
            // }
            if (!backNumber && selectedMembershipType.applicationFields?.backNumber) {
                setError("A required field is missing data: Back Number");
                return false;
            }
        }

        if (selectedMembershipType?.category ===  MembershipTypeCategory.GROUP) {
            if (!groupMembers || groupMembers.length === 0 || !groupMembers[0]?.name) {
                setError("A required field is missing data: Group Member #1 Full Name");
                return false;
            }
            if (!groupMembers || groupMembers.length === 0 || !groupMembers[0]?.birthDate) {
                setError("A required field is missing data: Group Member #1 Birth Date");
                return false;
            }
            if (selectedMembershipType.applicationFields?.backNumber && (!groupMembers || groupMembers.length === 0 || !groupMembers[0]?.backNumber)) {
                setError("A required field is missing data: Group Member #1 Back Number");
                return false;
            }
        }
        if (selectedMembershipType?.category ===  MembershipTypeCategory.HORSE) {
            if (!horseName) {
                setError("A required field is missing data: Horse Name");
                return false;
            }
            if (!horseHeight) {
                setError("A required field is missing data: Horse Height");
                return false;
            }
            if (!horseOwnerName) {
                setError("A required field is missing data: Owner's Name");
                return false;
            }
        }
        return true;
    };

    const handleCreateMembership = async () => {
        const isValid = verifyIsValid();
        if (!isValid) {
            return;
        }
        setIsLoading(true);
        setError("");
        if (selectedMembershipType?.category === MembershipTypeCategory.INDIVIDUAL || selectedMembershipType?.category === MembershipTypeCategory.HORSE) {
            let inputData;
            if (selectedMembershipType?.category === MembershipTypeCategory.INDIVIDUAL) {
                inputData = {
                    personData: person,
                    fullNameData: fullName,
                    birthDateData: birthDate,
                    backNumberData: backNumber,
                    barnData: barn,
                    barnNameData: barnName,
                    horseData: horse,
                    horseNameData: horseName,
                    riderData: rider
                };
            } else {
                inputData = {
                    barnData: barn,
                    barnNameData: barnName,
                    horseData: horse,
                    horseNameData: horseName,
                    horseHeightData: horseHeight,
                    horseOwnerNameData: horseOwnerName
                };
            }
            const createResult = await createNewMembership(inputData);
            if (createResult.isSuccess) {
                // Finally, update the nextAvailableMembershipId
                if(selectedMembershipType && selectedMembershipType?.membershipIdValues) {
                    const newMembershipId = getNextAvailableMembershipId(selectedMembershipType?.membershipIdValues, selectedMembershipType.nextAvailableMembershipId || "");
                    const updateInput:  UpdateOrganizationMembershipTypeInput = {
                        id: selectedMembershipType.id,
                        nextAvailableMembershipId: newMembershipId
                    };
                    await updateOrganizationMembershipType(updateInput);
                }
    
                if (organization?.id) {
                    const path = "/index/staff/organization/membership/edit/" + createResult.result.id;
                    history.push(path);
                }
            } else {
                setError("Could not create the member");
            }
        }
        if (selectedMembershipType?.category === MembershipTypeCategory.GROUP) {
            const memberships = [];
            for (const member of groupMembers) {
                if (member?.name && member?.birthDate) {
                    const inputData = {
                        personData: member.person,
                        fullNameData: member.name,
                        firstNameData: member.firstName,
                        lastNameData: member.lastName,
                        birthDateData: member.birthDate,
                        backNumberData: member.backNumber,
                        barnData: member.barn,
                        barnNameData: member.barnName,
                        horseData: member.horse,
                        horseNameData: member.horseName,
                        riderData: member.rider
                    }
                    const createResult = await createNewMembership(inputData);
                    if (createResult.isSuccess) {
                        memberships.push(createResult.result);
                    }
                }
            }
            // Finally, update the nextAvailableMembershipId
            if(selectedMembershipType && selectedMembershipType?.membershipIdValues) {
                const newMembershipId = getNextAvailableMembershipId(selectedMembershipType?.membershipIdValues, selectedMembershipType.nextAvailableMembershipId || "");
                const updateInput:  UpdateOrganizationMembershipTypeInput = {
                    id: selectedMembershipType.id,
                    nextAvailableMembershipId: newMembershipId
                };
                await updateOrganizationMembershipType(updateInput);
            }
    
            if (organization?.id && memberships.length > 0) {
                const path = "/index/staff/organization/membership/edit/" + memberships[0].id;
                history.push(path);
            }
        }
        setIsLoading(false);
    }
    const createNewMembership = async (inputData: any) => {
        const {personData, fullNameData, birthDateData, backNumberData, barnData, barnNameData,
            horseData, horseNameData, horseHeightData, horseOwnerNameData, riderData} = inputData;
        let currentPerson: Person | undefined | null = personData;
        if (selectedMembershipType?.category === MembershipTypeCategory.INDIVIDUAL ||
            selectedMembershipType?.category === MembershipTypeCategory.GROUP) {
            if (!currentPerson) {
                const splitName = fullNameData.split(' ');
                const personInput: CreatePersonInput = {
                    email: "",
                    firstName: splitName[0],
                    lastName: splitName.length > 1 ? splitName[1] : "",
                    isVerified: false,
                    roles: "rider;"
                };
                const createPersonResult = await createPerson(personInput);
                if (createPersonResult.isSuccess) {
                    currentPerson = createPersonResult.result;
                    const personalInformationInput: CreatePersonalInformationInput = {
                        personId: createPersonResult.result.id,
                        dateOfBirth: birthDateData
                    };
                    await createPersonalInformation(personalInformationInput);
                }
            }
        }

        let currentBarn: Barn | undefined | null = barnData;
        if (!currentBarn && barnNameData && barnNameData !== "") {
            const queryBarnResult = await getBarnByName(barnNameData);
            if (queryBarnResult.isSuccess) {
                currentBarn = queryBarnResult.result;
            } else {
                const barnInput: CreateBarnInput = {
                    name: barnNameData
                };
                const createBarnResult = await createBarn(barnInput);
                if (createBarnResult.isSuccess) {
                    currentBarn = createBarnResult.result;
                }
            }
        }

        let currentHorse: Horse | undefined | null = horseData;
        if (horseNameData && currentHorse) {
            const horseInput: UpdateHorseInput = {
                id: currentHorse.id,
                name: horseNameData,
                ownerName: horseOwnerNameData ? horseOwnerNameData : "",
                height: horseHeightData ? horseHeightData.toString() : "",
                barnId: currentBarn?.id || "",
                barnName: currentBarn?.name || ""
            };
            await updateHorse(horseInput);
        }
        if (!currentHorse) {
            if (horseNameData && currentBarn) {
                const queryResult = await getHorsesByBarnId(currentBarn.id);
                if (queryResult.isSuccess) {
                    const horsesInBarn: Horse[] = queryResult.result;
                    if (horsesInBarn && horsesInBarn.length > 0) {
                        for (let i = 0; i < horsesInBarn.length; i++) {
                            const current = horsesInBarn[i];
                            if (current.name.toLowerCase() === horseNameData.toLowerCase()) {
                                currentHorse = current;
                                break;
                            }
                        }
                    }
                }
            }
            if (!currentHorse && horseNameData) {
                const horseInput: CreateHorseInput = {
                    name: horseNameData,
                    ownerName: horseOwnerNameData ? horseOwnerNameData : "",
                    height: horseHeightData ? horseHeightData.toString() : "",
                    barnId: currentBarn?.id || "",
                    barnName: currentBarn?.name || "",
                    personId: currentPerson?.id || ""
                };
                const createHorseResult = await createHorse(horseInput);
                if (createHorseResult.isSuccess) {
                    currentHorse = createHorseResult.result;
                }
            }
        }
        let currentRider: Rider | undefined | null = riderData;
        if (selectedMembershipType?.category === MembershipTypeCategory.INDIVIDUAL ||
            selectedMembershipType?.category === MembershipTypeCategory.GROUP) {
            if (!currentRider) {
                if (currentPerson) {
                    const riderQueryResult = await getRiderByPersonId(currentPerson.id);
                    if (riderQueryResult.isSuccess) {
                        currentRider = riderQueryResult.result;
                    }
                }

                if (!currentRider && fullNameData) {
                    let riderInput: CreateRiderInput = {
                        name: fullNameData,
                        barnId: currentBarn?.id || "",
                        createdBy: user.id || "", 
                        personId: currentPerson?.id || "",
                        createdOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
                        updatedOn: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                    };
            
                    const riderResult = await createRider(riderInput);
                    if (riderResult.isSuccess) {
                        currentRider = riderResult.result;
                    }
                }
            }
        }

        let dateMembershipEnds = "";
        if (selectedMembershipType) {
            dateMembershipEnds = getDateMembershipEnds(selectedMembershipType);
        }

        let input: CreateMembershipInput = {
            name: organization?.name || organization?.nickname || "",
            membershipId,
            membershipStatus: CONSTANT.MEMBERSHIP.APPLICATION_STATUS.COMPLETE,
            organizationId: organization?.id || "",
            organizationMembershipTypeId: selectedMembershipType?.id || undefined,
            backNumber: backNumberData || 0,
            amountPaid: 0,
            type: selectedMembershipType?.name || "",
            personName: fullNameData,
            personId: currentPerson?.id || "",
            dateMembershipEnds: dateMembershipEnds,
            createdBy: user.id
        };
        // In case of horse membership application, update the person id and person name
        if (selectedMembershipType?.category === MembershipTypeCategory.HORSE) {
            input.personId = user?.id || "";
            const name = (user.firstName ? user.firstName.trim() : "") + 
                (user.firstName && user.lastName ? " " : "") + 
                (user.lastName ? user.lastName.trim() : "");
            input.personName = name;
        }
        if (currentRider) {
            input.riderId = currentRider?.id;
        }
        if (currentBarn) {
            input.barnId = currentBarn?.id;
        }
        if (currentHorse) {
            input.horseId = currentHorse?.id;
        }
        const createResult = await createMembership(input);
        return createResult;
    }

    const handleGroupMemberBarnChange = async (selectedBarn: Barn, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.barn = selectedBarn;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: "",
                    barn: selectedBarn
                };
            }
            currentGroupMember.barnName = selectedBarn ? selectedBarn.name : "";
            if (selectedBarn) {
                const queryResult = await getHorsesByBarnId(selectedBarn.id);
                if (queryResult.isSuccess) {
                    currentGroupMember.horses = queryResult.result;
                }
            } else {
                currentGroupMember.horses = [];
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }
    const handleGroupMemberBarnNameChange = async (barnName: string, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.barnName = barnName;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: "",
                    barnName: barnName
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }

    const handleGroupMemberRiderChange = async (selectedRider: Rider, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.rider = selectedRider;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: "",
                    rider: selectedRider
                };
            }
            currentGroupMember.riderName = selectedRider ? selectedRider.name : "";
            currentGroupMember.name = selectedRider ? selectedRider.name : "";
            currentGroupMember.birthDate = selectedRider ? selectedRider.birthdate || "" : "";
            const personId = selectedRider ? selectedRider.personId : "";
            if (personId) {
                const personResult = await getPersonByPersonId(personId);
                if (personResult.isSuccess) {
                    const foundPerson: Person = personResult.result;
                    currentGroupMember.person = foundPerson;
                    if (foundPerson?.firstName) currentGroupMember.firstName = foundPerson.firstName;
                    if (foundPerson?.lastName) currentGroupMember.lastName = foundPerson.lastName;
                }
            } else {
                currentGroupMember.person = undefined;
                currentGroupMember.firstName = "";
                currentGroupMember.lastName = "";
            }
            if (selectedRider) {
                const queryResult = await getMembershipsByRiderId(selectedRider.id);
                if (queryResult.isSuccess) {
                    const membershipList: Membership[] = queryResult.result;
                    if (membershipList && membershipList.length > 0) {
                        const membership = membershipList.find((mem) => {
                            if (mem.backNumber) {
                                return mem;
                            } else {
                                return null;
                            }
                        });
                        if (membership && membership.backNumber) {
                            currentGroupMember.backNumber = membership.backNumber;
                        }
                    }
                }
            } else {
                currentGroupMember.backNumber = 0;
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }
    const handleGroupMemberRiderNameChange = async (riderName: string, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.riderName = riderName;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: "",
                    riderName: riderName
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }

    const handleGroupMemberHorseChange = async (selectedHorse: Horse, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.horse = selectedHorse;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: "",
                    horse: selectedHorse
                };
            }
            currentGroupMember.horseName = selectedHorse ? selectedHorse.name : "";
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }
    const handleGroupMemberHorseNameChange = async (horseName: string, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.horseName = horseName;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: "",
                    horseName: horseName
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }

    const handleGroupMemberNameChange = async (name: string, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.name = name;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: name,
                    birthDate: ""
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }

    const handleGroupMemberBirthDateChange = async (birthDate: string, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.birthDate = birthDate;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: birthDate
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }

    const handleGroupMemberBackNumberChange = async (backNumber: string, index: number) => {
        if (groupMembers) {
            let currentGroupMember: (GroupMember | null) = groupMembers[index];
            if (currentGroupMember) {
                // If the current group member already exists, only update the applicable field
                currentGroupMember.backNumber = backNumber && backNumber.length ? parseInt(backNumber) : undefined;
            } else {
                // If the current group member does not already exists, create it with the data we currently have
                currentGroupMember = {
                    name: "",
                    birthDate: "",
                    backNumber: backNumber && backNumber.length ? parseInt(backNumber) : undefined
                };
            }
            // Update the group member array
            const updatedGroupMemberArray = [
                ...groupMembers.slice(0, index),
                currentGroupMember,
                ...groupMembers.slice(index + 1)
            ];
            setGroupMembers([...updatedGroupMemberArray]);
        }
    }

    return (
        <IonPage className="bg-light">
            <Header />
            <IonContent>
                <PageTitle title="Members" />
                {isLoading ?
                    <Spinner />
                    :   
                    <IonRow className="ion-justify-content-center">
                        {error && <ErrorAlert width="12" error={error} />}
                        <IonCol size="12">
                            <IonCard mode="md" className="ion-padding bg-white">
                                <IonCardTitle>New Member</IonCardTitle>
                                    {organization ?
                                        <>
                                            <IonRow className="pb-3">
                                                <IonCol sizeXs="12" sizeMd="6">
                                                    <IonLabel position="stacked">Membership Type <RequiredInputIndicator /></IonLabel>
                                                    <SelectOrganizationMembershipType membershipTypes={organizationMembershipTypes} selectedValue={selectedMembershipType?.id} onSelect={(value: OrganizationMembershipType | undefined) => {handleSelectMembershipType(value); }} />
                                                </IonCol>
                                            </IonRow>
                                            {selectedMembershipType?.category === MembershipTypeCategory.INDIVIDUAL && <div>
                                                <IonRow className="mt-3 mb-0">
                                                    <IonCol className="ion-text-center">
                                                        <h4>Member Info</h4>
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the barn in the select. If it doesn't exist, type in the name of the barn.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonLabel position="stacked">Barn</IonLabel>
                                                        <SelectFromAllBarns barn={barn} onSelect={(barn: Barn) => handleSelectBarn(barn)} />
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Barn Name</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={barnName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setBarnName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the rider in the select. If it doesn't exist, type in the name of the rider.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonLabel position="stacked">Rider</IonLabel>
                                                        <SelectRiderFromBarn barn={barn || undefined} selectedValue={rider?.id} onSelect={(rider: Rider) => handleSelectRider(rider)} />
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Rider Name</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={riderName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setRiderName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the horse in the select. Horses load after selecting the barn. If the horse doesn't exist, type in the name of the horse.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonLabel position="stacked">Horse</IonLabel>
                                                        <SelectHorse horses={horseOptions} selectedValue={horse?.id} onSelect={(horse: Horse) => {handleSelectHorse(horse)}} />
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Horse Name</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={horseName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setHorseName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    <IonCol sizeXs="12" sizeMd="4">
                                                        <IonItem color="white">
                                                            <IonLabel position="floating">Member Full Name<RequiredInputIndicator /></IonLabel>
                                                            <IonInput
                                                                id="add-member-full-name"
                                                                type="text"
                                                                value={fullName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setFullName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                    {selectedMembershipType && selectedMembershipType.applicationFields?.dateOfBirth && <IonCol sizeXs="12" sizeMd="4">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Member Birth Date<RequiredInputIndicator /></IonLabel>
                                                            <IonInput
                                                                id="add-member-dob"
                                                                type="date"
                                                                value={birthDate}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setBirthDate(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>}
                                                    {selectedMembershipType && selectedMembershipType.applicationFields?.backNumber &&<IonCol sizeXs="12" sizeMd="4">
                                                        <IonItem color="white">
                                                            <IonLabel position="floating">Member Back Number</IonLabel>
                                                            <IonInput
                                                                id="add-member-back-number"
                                                                type="text"
                                                                value={backNumber}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setBackNumber(parseInt(e.detail.value!))
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>}
                                                </IonRow>
                                            </div>}

                                            {selectedMembershipType?.category === MembershipTypeCategory.GROUP &&
                                                <div>
                                                    {((groupMembers && groupMembers.length > 0) && (
                                                        <>
                                                            {groupMembers.map((groupMember: (GroupMember | null ), i: number) => {
                                                                return (
                                                                    <div key={i}>
                                                                        <IonRow className="mt-3 mb-0">
                                                                            <IonCol className="ion-text-center">
                                                                                <h4>Member #{i + 1} Info</h4>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <p className="text-info description ion-text-wrap">Try finding the barn in the select. If it doesn't exist, type in the name of the barn.</p>
                                                                        <IonRow className="pb-3">
                                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                                <IonLabel position="stacked">Barn</IonLabel>
                                                                                <SelectFromAllBarns barn={groupMember?.barn} onSelect={(barn: Barn) => handleGroupMemberBarnChange(barn, i)} />
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                                <IonItem color="white">
                                                                                    <IonLabel position="stacked">Barn Name</IonLabel>
                                                                                    <IonInput
                                                                                        type="text"
                                                                                        value={groupMember?.barnName}
                                                                                        aria-required={true}
                                                                                        onIonChange={e => {
                                                                                            handleGroupMemberBarnNameChange(e.detail.value!, i)
                                                                                        }}
                                                                                    />
                                                                                </IonItem>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <p className="text-info description ion-text-wrap">Try finding the rider in the select. If it doesn't exist, type in the name of the rider.</p>
                                                                        <IonRow className="pb-3">
                                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                                <IonLabel position="stacked">Rider</IonLabel>
                                                                                <SelectRiderFromBarn barn={groupMember?.barn || undefined} selectedValue={groupMember?.rider?.id} onSelect={(rider: Rider) => handleGroupMemberRiderChange(rider, i)} />
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                                <IonItem color="white">
                                                                                    <IonLabel position="stacked">Rider Name</IonLabel>
                                                                                    <IonInput
                                                                                        type="text"
                                                                                        value={groupMember?.riderName}
                                                                                        aria-required={true}
                                                                                        onIonChange={e => {
                                                                                            handleGroupMemberRiderNameChange(e.detail.value!, i)
                                                                                        }}
                                                                                    />
                                                                                </IonItem>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <p className="text-info description ion-text-wrap">Try finding the horse in the select. Horses load after selecting the barn. If the horse doesn't exist, type in the name of the horse.</p>
                                                                        <IonRow className="pb-3">
                                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                                <IonLabel position="stacked">Horse</IonLabel>
                                                                                <SelectHorse horses={groupMember?.horses} selectedValue={groupMember?.horse?.id} onSelect={(horse: Horse) => {handleGroupMemberHorseChange(horse, i)}} />
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="6">
                                                                                <IonItem color="white">
                                                                                    <IonLabel position="stacked">Horse Name</IonLabel>
                                                                                    <IonInput
                                                                                        type="text"
                                                                                        value={groupMember?.horseName}
                                                                                        aria-required={true}
                                                                                        onIonChange={e => {
                                                                                            handleGroupMemberHorseNameChange(e.detail.value!, i)
                                                                                        }}
                                                                                    />
                                                                                </IonItem>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <IonRow className="pb-3">
                                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                                <IonItem color="white">
                                                                                    <IonLabel position="floating">Member #{i + 1} Full Name<RequiredInputIndicator /></IonLabel>
                                                                                    <IonInput
                                                                                        className="add-member-group-full-name"
                                                                                        type="text"
                                                                                        value={groupMember?.name}
                                                                                        aria-required={true}
                                                                                        onIonChange={e => {
                                                                                            setError("")
                                                                                            handleGroupMemberNameChange(e.detail.value!, i)
                                                                                        }}
                                                                                    />
                                                                                </IonItem>
                                                                            </IonCol>
                                                                            <IonCol sizeXs="12" sizeMd="4">
                                                                                <IonItem color="white">
                                                                                    <IonLabel position="stacked">Member #{i + 1} Birth Date<RequiredInputIndicator /></IonLabel>
                                                                                    <IonInput
                                                                                        className="add-member-group-dob"
                                                                                        type="date"
                                                                                        value={groupMember?.birthDate}
                                                                                        aria-required={true}
                                                                                        onIonChange={e => {
                                                                                            setError("")
                                                                                            handleGroupMemberBirthDateChange(e.detail.value!, i)
                                                                                        }}
                                                                                    />
                                                                                </IonItem>
                                                                            </IonCol>
                                                                            {selectedMembershipType && selectedMembershipType.applicationFields?.backNumber &&<IonCol sizeXs="12" sizeMd="4">
                                                                                <IonItem color="white">
                                                                                    <IonLabel position="floating">Member #{i + 1} Back Number</IonLabel>
                                                                                    <IonInput
                                                                                        className="add-member-group-back-number"
                                                                                        type="text"
                                                                                        value={groupMember?.backNumber}
                                                                                        aria-required={true}
                                                                                        onIonChange={e => {
                                                                                            setError("")
                                                                                            handleGroupMemberBackNumberChange(e.detail.value!, i)
                                                                                        }}
                                                                                    />
                                                                                </IonItem>
                                                                            </IonCol>}
                                                                        </IonRow>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    ))}
                                                </div>
                                            }

                                            {selectedMembershipType?.category === MembershipTypeCategory.HORSE && <div>
                                                <IonRow className="mt-3 mb-0">
                                                    <IonCol className="ion-text-center">
                                                        <h4>Member Info</h4>
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the barn in the select. If it doesn't exist, type in the name of the barn.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonLabel position="stacked">Barn</IonLabel>
                                                        <SelectFromAllBarns barn={barn} onSelect={(barn: Barn) => handleSelectBarn(barn)} />
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Barn Name</IonLabel>
                                                            <IonInput
                                                                type="text"
                                                                value={barnName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setBarnName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <p className="text-info description ion-text-wrap">Try finding the horse in the select. Horses load after selecting the barn. If the horse doesn't exist, type in the name of the horse.</p>
                                                <IonRow className="pb-3">
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonLabel position="stacked">Horse</IonLabel>
                                                        <SelectHorse horses={horseOptions} selectedValue={horse?.id} onSelect={(horse: Horse) => {handleSelectHorse(horse)}} />
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Horse Name<RequiredInputIndicator /></IonLabel>
                                                            <IonInput
                                                                id="add-new-member-horse-name"
                                                                type="text"
                                                                value={horseName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setHorseName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                                <IonRow className="pb-3">
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <HorseHeightInput height={horseHeight} isRequired={true} onChange={handleHeightChange} />
                                                    </IonCol>
                                                    <IonCol sizeXs="12" sizeMd="6">
                                                        <IonItem color="white">
                                                            <IonLabel position="stacked">Owner's Name<RequiredInputIndicator /></IonLabel>
                                                            <IonInput
                                                                id="add-new-member-horse-owner-name"
                                                                type="text"
                                                                value={horseOwnerName}
                                                                aria-required={true}
                                                                onIonChange={e => {
                                                                    setError("")
                                                                    setHorseOwnerName(e.detail.value!)
                                                                }}
                                                            />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                            </div>}

                                            <IonRow className="ion-align-items-center">
                                                {selectedMembershipType &&
                                                    <IonCol size="12" className="ion-text-center">
                                                        <IonButton
                                                            id="add-member-next-btn"
                                                            color="tertiary"
                                                            onClick={handleCreateMembership}
                                                        >
                                                            Next
                                                        </IonButton>
                                                    </IonCol>
                                                }
                                            </IonRow>
                                        </>
                                        :
                                        <p>Loading organization....</p>
                                    }
                            </IonCard>
                        </IonCol>
                    </IonRow>
                }
            </IonContent>
        </IonPage>
    );
};

export default EventOrganizationMembersCreatePage;