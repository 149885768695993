import { OrganizationClass } from "../../models";

/**
 * Sorts the organizationClasses alphabetically a -> z
 * 
 * @param data 
 * @returns organizationClasses sorted alphabetically
 */
export function sortOrganizationClassesByName(data: OrganizationClass[]) {
    try {
        let result: OrganizationClass[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => a.name.localeCompare(b.name));
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: organizationClass");
        console.error(error);
        return null;
    }
};

/**
 * Sorts the organizationClasses numerically smallest to greatest
 * If there are not numbers, sort alphabetically a -> z
 * @param data 
 * @returns organizationClasses sorted numerically
 */
export function sortOrganizationClassesByNumber(data: OrganizationClass[]) {
    try {
        let result: OrganizationClass[] = [];
        if (data && data.length) {
            result = data.sort((a,b) => {
                const numberResult = (a.number || 0) - (b.number || 0);
                const nameResult = a.name.localeCompare(b.name);
                return numberResult || nameResult;
            });
        }
        return result;
    } catch (error: any) {
        console.error("Error sorting data for type: organizationClass");
        console.error(error);
        return null;
    }
};