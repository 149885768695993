export const defaultTableSettings = {
    theme: "grid",
    headStyles: {fillColor: "#73a4d3"},
    rowPageBreak: "avoid", 
};
export function openTabForPrinting(doc: any){
    var base64URL = doc.output('bloburl');
    var win = window.open();
    if (win) {
        win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    }
}