import React, { useContext, useEffect, useState } from "react";
import { PersonContext } from "../../context/PersonContext";
import ErrorAlert from "../Errors/ErrorAlert";
import { Event, EventEntry, EventRules, EventRulesAgreement } from "../../models";
import Spinner from "../Spinners/Spinner";
import { IonCol, IonIcon, IonLabel, IonList, IonRow, isPlatform } from "@ionic/react";
import { getEventEntryById } from "../../utilities/eventEntry/EventEntry";
import { getEventRulesByEventId } from "../../utilities/eventRules/EventRules";
import { checkmarkCircleOutline, chevronDown, chevronUp, stopCircleSharp } from "ionicons/icons";
import RuleAgreementForm from "../EventRules/RuleAgreementForm";
import { getEventRulesAgreementsByEntryId } from "../../utilities/eventRules/EventRulesAgreement";
import { Input } from "reactstrap";
import { isWindows } from "../../utilities/platform/Platform";

interface formattedEventRule {
    isCollapsed: boolean
    isAgreed: boolean
    isRequired: boolean
    rule: EventRules
}

interface _Props {
    event: Event
    entry?: EventEntry
}

const EntryRuleForm: React.FC<_Props> = ({entry, event}) => {
    const user = useContext(PersonContext);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [eventRules, setEventRules] = useState<EventRules[] | null | undefined>();
    const [formattedEventRules, setFormattedEventRules] = useState<formattedEventRule[] | null | undefined>();
    const [previousAgreements, setPreviousAgreements] = useState<EventRulesAgreement[] | null | undefined>();
    const [currentEntry, setCurrentEntry] = useState<EventEntry | null | undefined>();

    async function getPreviousAgreements(eventEntry: EventEntry, eventRules?: EventRules[]) {
        const queryResult = await getEventRulesAgreementsByEntryId(eventEntry.id);
        if (queryResult.isSuccess) {
            const previousAgreements = queryResult.result;
            setPreviousAgreements(previousAgreements);
            if (eventRules) formatEventRules(eventRules, previousAgreements);
        }
    }

    const formatEventRules = (eventRules: EventRules[], selectedPreviousAgreements?: EventRulesAgreement[]) => {
        let formattedRules: formattedEventRule[] = [];
        for (var i = 0; i < eventRules.length; i++) {
            const currentRule = eventRules[i];
            let foundPreviousAgreement = false;
            if (selectedPreviousAgreements) {
                selectedPreviousAgreements.forEach((previous: EventRulesAgreement) => {
                    if (previous.eventRule?.id === currentRule.id) foundPreviousAgreement = true;
                });
            }
            const formattedRule: formattedEventRule = {
                isAgreed: foundPreviousAgreement,
                isRequired: currentRule.requiresAgreementPerEntry ? true : false,
                isCollapsed: false,
                rule: currentRule
            }
            formattedRules.push(formattedRule);
        } 
        setFormattedEventRules(formattedRules);
    }

    useEffect(() => {
        async function getEventRules() {
            setIsLoading(true);
            const queryResult = await getEventRulesByEventId(event.id);
            if (queryResult.isSuccess) {
                setEventRules(queryResult.result);
                if (entry && !previousAgreements) getPreviousAgreements(entry, queryResult.result);
                else formatEventRules(queryResult.result);
            }
            setIsLoading(false);
        }
        getEventRules();
    }, []);

    useEffect(() => {
        if (entry) {
            setCurrentEntry(entry);
            getPreviousAgreements(entry);
        }
    }, [entry]);

    const handleSelectedEntry = async (eventEntry: EventEntry) => {
        if (event && eventEntry) {
            const queryResult = await getEventEntryById(eventEntry.id);
            if (queryResult.result) {
                eventEntry = queryResult.result;
            }
            setCurrentEntry(eventEntry);
            getPreviousAgreements(eventEntry);
            if (eventRules) formatEventRules(eventRules);
        } else {
            setCurrentEntry(undefined);
        }
    }

    const handleCollapseRule = (index: number) => {
        if (index > -1 && formattedEventRules) {
            const clickedRule = formattedEventRules[index];
            const isCollapsed = !clickedRule.isCollapsed;
            const newFormattedEventRule: formattedEventRule = {
                isCollapsed: isCollapsed,
                isRequired: clickedRule.isRequired,
                isAgreed: clickedRule.isAgreed,
                rule: clickedRule.rule
            };
            const newFormattedEventRules = [
                ...formattedEventRules.slice(0, index),
                newFormattedEventRule,
                ...formattedEventRules.slice(index+1)
            ];
            setFormattedEventRules(newFormattedEventRules);
        }
    }

    const handleRuleInput = (index: number, isChecked: boolean) => {
        if (index > -1 && formattedEventRules) {
            const clickedRule = formattedEventRules[index];
            const newFormattedEventRule: formattedEventRule = {
                isCollapsed: !isChecked ? true: clickedRule.isCollapsed,
                isRequired: clickedRule.isRequired,
                isAgreed: !isChecked,
                rule: clickedRule.rule
            };
            const newFormattedEventRules = [
                ...formattedEventRules.slice(0, index),
                newFormattedEventRule,
                ...formattedEventRules.slice(index+1)
            ];
            setFormattedEventRules(newFormattedEventRules);
        }
    }

    return (
        <>
            {error && <ErrorAlert width="12" error={error}/>}
            {isLoading ?
                <Spinner/>
                :
                <>
                    {currentEntry && (
                        <>
                            <IonRow className="ion-justify-content-center">
                                <IonCol className="text-center" sizeMd="10">
                                    <p>Event Rules</p>
                                </IonCol>
                            </IonRow>
                            <IonRow className="ion-justify-content-center">
                                <IonCol sizeMd="10">
                                    {formattedEventRules && formattedEventRules.length > 0 ?
                                        <IonList className="bg-white">
                                            {formattedEventRules.map((formattedEventRule, index) => (
                                                <div key={index}>
                                                    <IonLabel className="ion-text-wrap">
                                                        <IonRow className="ion-justify-content-center" onClick={() => handleCollapseRule(index)}>
                                                            <IonCol size="11" className="ion-text-center">
                                                                <h2 className="text-primary">
                                                                    {((formattedEventRule.isRequired && formattedEventRule.isAgreed) || (!formattedEventRule.isRequired)) ? <IonIcon color="success" icon={checkmarkCircleOutline}/> : <IonIcon color="danger" icon={stopCircleSharp}/>}
                                                                    {formattedEventRule.rule.title}
                                                                </h2>
                                                            </IonCol>
                                                            <IonCol size="1">
                                                                <div>
                                                                    {formattedEventRule.isCollapsed ? 
                                                                        <IonIcon icon={chevronDown} /> 
                                                                        : 
                                                                        <IonIcon icon={chevronUp} /> 
                                                                    }
                                                                    </div>
                                                            </IonCol>
                                                        </IonRow>
                                                        {!formattedEventRule.isCollapsed && (
                                                            <IonRow className="ion-justify-content-center">
                                                                <Input
                                                                    id="waiver-list-body-info"
                                                                    rows={(isWindows() || isPlatform("desktop")) ? "5" : "15"}
                                                                    type="textarea"
                                                                    value={formattedEventRule.rule.text || ""}
                                                                    className="mt-3"
                                                                    disabled={true}
                                                                />
                                                            </IonRow>
                                                        )}
                                                        {formattedEventRule.rule.requiresAgreementPerEntry ? 
                                                            <>
                                                                <RuleAgreementForm event={event} eventRule={formattedEventRule.rule} eventEntry={currentEntry} onSubmit={(isChecked: boolean) => handleRuleInput(index, isChecked)} />
                                                            </>
                                                            :
                                                            <p>(No digital signature is required for this rule.)</p>
                                                        }
                                                    </IonLabel>
                                                </div>
                                            ))}
                                        </IonList>
                                        :
                                        <IonRow className="ion-justify-content-center">
                                            <IonCol className="text-center" sizeMd="10">
                                                <p>This event does not have any rules specified.</p>
                                            </IonCol>
                                        </IonRow>
                                    }
                                </IonCol>
                            </IonRow>
                        </>
                    )}
                </> 
            }
        </>
    );
};

export default EntryRuleForm;